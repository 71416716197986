import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, PIN_CODE_PATTERN, TYPE_SUCCESS, INTERVAL } from "assets/constants/Constants";
import { ROUTE_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';

export class CreateRoutePage extends Component {
    constructor(props) {
        super(props)
    
        const editData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            routeId: editData === null ? null : editData._id,
            siteId: editData === null ? sessionStorage.getItem("siteId")  : editData.site_id,
            routeManagerId: editData === null ? "" : editData.route_manager_id,
            routeName: editData === null ? null : editData.route_name,
            routeStreet: editData === null ? "" : editData.street,
            routeCity: editData === null ? "" : editData.city,
            routeZone: editData === null ? "" : editData.zone,
            routeSubzone: editData === null ? "" : editData.subzone,
            routePinCode: editData === null ? "" : editData.pin_code,
            siteList: [],
            roleList:[],
            error: {},
        }
    }

    async componentDidMount() {
        const roleData = await this.getRoleList();
        this.setState({
            roleList: roleData.data.result,
            isLoading: false,
        });
    }

    getRoleList = async () => {
        const siteID = sessionStorage.getItem("siteId");
        return await axios.get(`${API_BASE_URL}/role/roledatabase/manager`, {
            params: { siteId: siteID }
        });
    };

    renderRouteManagerIdOptions = () => {
        if(this.state.roleList.length > 0){
            return this.state.roleList.map((routeItem, index) => {
                let isSelected = false;
                if(routeItem._id === this.state.routeManagerId){
                    isSelected = true;
                }
                return(
                    <option value={ routeItem._id } selected = { isSelected } key= { routeItem._id } className = "option-text-color"> { routeItem.first_name + " " + routeItem.last_name } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    routeNameChangeHandler = (event) => {
        this.setState({
            routeName: event.target.value,
        });
    }

    routeManagerIdChangeHandler = (event) => {
        this.setState({
            routeManagerId: event.target.value,
        });
    }

    routeStreetChangeHandler = (event) => {
        this.setState({
            routeStreet: event.target.value,
        });
    }

    routeCityChangeHandler = (event) => {
        this.setState({
            routeCity: event.target.value,
        });
    }

    routeZoneChangeHandler = (event) => {
        this.setState({
            routeZone: event.target.value,
        });
    }

    routeSubzoneChangeHandler = (event) => {
        this.setState({
            routeSubzone: event.target.value,
        });
    }

    routePinCodeChangeHandler = (event) => {
        this.setState({
            routePinCode: event.target.value,
        });
    }

    allMeterCountChangeHandler = (event) => {
        this.setState({
            allMeterCount: event.target.value,
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if(this.state.siteId  && this.state.siteId === "0"){
            isValid = false;
            error.site = "Please select site";
        }

        if(!this.state.routeManagerId) {
            isValid = false;
            error.routeManagerId = "Please enter route manager";
        }

        if(!this.state.routeName) {
            isValid = false;
            error.routeName = "Please select route name";
        }

        if(!this.state.routeStreet) {
            isValid = false;
            error.routeStreet = "Please enter route street";
        }

        if(!this.state.routeCity) {
            isValid = false;
            error.routeCity = "Please enter route city";
        }

        if(this.state.routePinCode !== "undefined"){
            if(!this.state.routePinCode) {
                isValid = false;
                error.routePinCode = "Please enter route pin code";
            }
            else if (!PIN_CODE_PATTERN.test(this.state.routePinCode)) {
                isValid = false;
                error.routePinCode = "Please enter valid Pin code.";
            }
        }

        this.setState({
            error
        });

        return isValid;
    }

    createRouteHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({isLoading: true,});
            const requestData = {
                routeManagerId: this.state.routeManagerId,
                siteId: this.state.siteId,
                routeName: this.state.routeName,
                routeStreet: this.state.routeStreet,
                routeCity: this.state.routeCity,
                routePinCode: this.state.routePinCode,
                routeZone: this.state.routeZone,
                routeSubzone: this.state.routeSubzone,
                routeStatus: this.state.routeStatus
            }
            let routeResponse = null;
            if(this.state.routeId === null){
                routeResponse = await axios.post(`${API_BASE_URL}/routes`, requestData);
            }
            else{
                routeResponse = await axios.put(`${API_BASE_URL}/routes/${this.state.routeId}`, requestData);
            }
            if(routeResponse.status === 200){
                this.setState({
                    routeManagerId: "",
                    routeName: "",
                    routeStreet: "",
                    routeCity: "",
                    routePinCode: "",
                    routeSubzone: "",
                    routeZone:""
                }, () => {
                    if(this.state.routeId === null){
                    ShowToast('Route created successfully', TYPE_SUCCESS, INTERVAL, this.props, "/master-route-page"); 
                    }
                    else{
                        ShowToast('Route updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/master-route-page"); 
                    }
                    this.props.history.push("/master-route-page");
                });
            }
        }
    }

    cancelHandler = (event) => {
        this.props.history.push("/master-route-page");
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/master-route-page">Route Master</a></li>
                            {
                                this.state.routeId === null ? (
                                    <li>Add New Route</li>
                                ) : (
                                    <li>Edit Route</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-route-main-view">
                        <div className="mx-auto text-left border create-route-div">
                            <form className="pt-1">
                                <div className="route-icon-wrapper">
                                    <h4>{ this.state.routeId === null ? "Add New Route" : "Edit Route"}</h4>
                                    <i className={ `${ROUTE_ICON} list-table-icon` }></i>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Route Name <span className="text-danger">*</span></label>
                                            <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.routeName } onChange={ this.routeNameChangeHandler } placeholder="Enter Route Name" />
                                            <div className="text-danger error-msg">
                                                {this.state.error.routeName}
                                            </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Route Manager <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={ this.routeManagerIdChangeHandler } placeholder="Select route manager" >
                                            <option selected value="0" disabled >Select route manager</option>
                                            {
                                                this.renderRouteManagerIdOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.routeManagerId}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Route Street <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.routeStreet } onChange={ this.routeStreetChangeHandler } placeholder="Enter Route Street" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.routeStreet}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Route Zone </label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.routeZone } onChange={ this.routeZoneChangeHandler } placeholder="Enter Route Zone" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.routeZone}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                    <label className="form-label">Route City <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.routeCity } onChange={ this.routeCityChangeHandler } placeholder="Enter Route City" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.routeCity}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                    <label className="form-label">Route Subzone </label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.routeSubzone } onChange={ this.routeSubzoneChangeHandler } placeholder="Enter Route Subzone" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.routeSubzone}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Route Pin Code <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.routePinCode } onChange={ this.routePinCodeChangeHandler } placeholder="Enter Route Pin Code" maxlength="6" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.routePinCode}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.createRouteHandler}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div>
            )
        }
    }
}

export default CreateRoutePage
