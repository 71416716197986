import { Route, withRouter, Switch } from "react-router-dom";
import SideNavigationBar from "components/SidebarNav/Sidebar";
import TopNavigationBar from "components/navbars/NavigationBar";
import Footer from "components/footer";
import { ProtectedRoute } from "components/ProtectedRoute";
// pages
import HomePage from "App";
import LoginPage from "views/login/LoginPage";
import UserPage from "views/users/UserPage";
import MeterPage from "views/meter/MeterPage";
import RoutePage from "views/routes/RoutePage";
import SitesPage from "views/sites/SitesPage";
import CreateMeterPage from "views/meter/CreateMeterPage";
import CreateRoutePage from "views/routes/CreateRoutePage";
import CreateUserPage from "views/users/CreateUserPage";
import MeterReadingPage from "views/Readings/MeterReadingPage";
import CreateSitePage from "views/sites/CreateSitePage";
import RouteMeterMappingPage from "views/routes/RouteMeterMappingPage";
import RolesPage from "views/role/RolePage";
import CreateCustomerPage from "views/customer/CreateCustomerPage";
import CustomerPage from "views/customer/CustomerPage";
import RouteAssignementPage from "views/routes/RouteAssignementPage";
import ReportMasterPage from "views/report/ReportMasterPage";
import MasterRoutePage from "views/routes/Models/MasterRoutePage";
import RouteTransactionPage from "views/routes/Models/RouteTransactionPage";
import RouteHistory from "views/routes/Models/RouteHistory";
import ForgotPassword from "views/login/ForgotPassword";
import CustomerViewPage from "views/customer/CustomerViewPage";
import MeterViewPage from "views/meter/MeterViewPage";
import ReportTransactionPage from "views/report/ReportTransactionPage";
import BillingCyclePage from "views/billing/BillingCyclePage";
import BillingTransactionPage from "views/billing/BillingTransactionPage";
import SearchTransactionIdPage from "views/search/SearchTransactionIdPage";
import BillingInvoice from "views/billing/BillingInvoice";
import BillingSetupPage from "views/billing/BillingSetupPage";
import AMI_AMR_MeterReadingPage from "views/Readings/AMI_AMR_MeterReadingPage";
import MeterHistoryLog from "views/meter/MeterHistoryLog";
import ClearMeterHistory from "views/meter/ClearMeterHistory";
import MeterConfigurationPage from "views/meter/MeterConfigurationPage"
import NearByMeter from "views/meter/NearByMeter";

export const RouterComponent = withRouter(({ location, history }) => {
    return (
        <div className="app-container">
            <div className="header-container top-navigation-bar-container">
                {
                    (location.pathname === "/login-page" || location.pathname.includes("/login/") || location.pathname === "/" || location.pathname === '/forget-password') ? "" : <TopNavigationBar history={history} />
                }
            </div>
            <div className="side-navigation-bar-container">
                {
                    (location.pathname === "/login-page" || location.pathname.includes("/login/") || location.pathname === "/" || location.pathname === '/forget-password') ? "" : <SideNavigationBar history={history} />
                }
            </div>
            <div className="main-container">
                <Switch>
                    <Route exact path="/" render={(props) => <LoginPage {...props} />} />
                    <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
                    <Route path="/login/user/:role" render={(props) => <LoginPage {...props} />} />
                    <Route path="/login/:siteId" render={(props) => <LoginPage {...props} />} />
                    <ProtectedRoute path="/route-page" component={RoutePage} />
                    <ProtectedRoute path="/home-page" component={HomePage} />
                    <ProtectedRoute path="/user-page" component={UserPage} />
                    <ProtectedRoute path="/meter-page" component={MeterPage} />
                    <ProtectedRoute path="/sites-page" component={SitesPage} />
                    <ProtectedRoute path="/create-meter-page" component={CreateMeterPage} />
                    <ProtectedRoute path="/create-route-page" component={CreateRoutePage} />
                    <ProtectedRoute path="/create-user-page" component={CreateUserPage} />
                    <ProtectedRoute path="/meter-reading" component={MeterReadingPage} />
                    <ProtectedRoute path="/create-site-page" component={CreateSitePage} />
                    <ProtectedRoute path="/route-meter-mapping" component={RouteMeterMappingPage} />
                    <ProtectedRoute path="/roles-page" component={RolesPage} />
                    <ProtectedRoute path="/create-customer-page" component={CreateCustomerPage} />
                    <ProtectedRoute path="/customer-page" component={CustomerPage} />
                    <ProtectedRoute path="/route-assignement-page" component={RouteAssignementPage} />
                    <ProtectedRoute path="/report-master-page" component={ReportMasterPage} />
                    <ProtectedRoute path="/master-route-page" component={MasterRoutePage} />
                    <ProtectedRoute path="/route-transaction-page" component={RouteTransactionPage} />
                    <ProtectedRoute path="/route-history" component={RouteHistory} />
                    <Route path="/forget-password" render={(props) => <ForgotPassword {...props} />} />
                    <ProtectedRoute path="/customer-view-page" component={CustomerViewPage} />
                    <ProtectedRoute path="/meter-view-page" component={MeterViewPage} />
                    <ProtectedRoute path="/report-transaction-page" component={ReportTransactionPage} />
                    <ProtectedRoute path="/billing-cycle-page" component={BillingCyclePage} />
                    <ProtectedRoute path="/billing-transaction-page" component={BillingTransactionPage} />
                    <ProtectedRoute path="/search-transaction-id-page" component={SearchTransactionIdPage} />
                    <ProtectedRoute path="/billing-invoice" component={BillingInvoice} />
                    <ProtectedRoute path="/billing-setup-page" component={BillingSetupPage} />
                    <ProtectedRoute path="/ami-amr-meter-reading-page" component={AMI_AMR_MeterReadingPage} />
                    <ProtectedRoute path="/meter-history-log" component={MeterHistoryLog} />
                    <ProtectedRoute path="/clear-meter-history" component={ClearMeterHistory} />
                    <ProtectedRoute path="/meter-configuration-page" component={MeterConfigurationPage} />
                    <ProtectedRoute path="/map-view" component={NearByMeter} />
                </Switch>
            </div>
            <div className="footer-container">
                {
                    (location.pathname === "/login-page" || location.pathname.includes("/login/") || location.pathname === "/" || location.pathname === "/forget-password") ? "" : <Footer />
                }
            </div>
        </div>
    );
});
