import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL, SAMPLE_FILE_URL } from 'assets/constants/Constants';
import { EDIT_ICON, TRASH_ICON, EYE_ICON, CHECK_ICON, CLOSE_ICON, FILE_ICON } from 'assets/constants/Icons';
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import { hasPermission } from 'components/Permission';
import MaterialTable from "material-table";
import CSVFileReader from 'components/CSVFileReader';
import FileDownloader from 'components/FileDownloader';
import { showLoading, hideLoading } from 'lib/utils';

export class CustomerPage extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            isLoading: true, 
            buttonText: "Add Customer",
            customerList: [],
            importList: []
        }
    } 

    async componentDidMount() {
        const customerData = await this.getAllCustomer();
        this.setState({ 
            customerList: customerData.data.result,
            totalCount: customerData.data.totalCount,
            isLoading: false
        });
    }

    getAllCustomer = async () => {
        return await axios(`${API_BASE_URL}/customer/site/${sessionStorage.getItem("siteId")}?limit=${this.state.pageSize}&page=${this.state.currentPage}`);
    }

    addCustomerHandler = (item) => {
        if (item) {
            const rowItem = {
                _id: item.customerId,
                customer_name: item.customer_name,
                consumer_no: item.consumerNo,
                serial_number: item.assignedMeter,
                customer_address1: item.customer_address1,
                customer_address2: item.customer_address2,
                street: item.street,
                city: item.city,
                pin_code:item.pincode,
                mobile_no:item.mobile,
                meter_id:item.meterId,
                site_id:item.siteId
            }
            this.props.history.push({
                pathname: "/create-customer-page",
                state: rowItem
            })
        }
        else{
            this.props.history.push({
                pathname: "/create-customer-page",
                state: null
            })
        }
    }

    addViewHandler = (item) => {
        if (item) {
            const rowItem = {
                _id: item.customerId,
                customer_name: item.customer_name,
                consumer_no: item.consumerNo,
                serial_number: item.assignedMeter,
                customer_address1: item.customer_address1,
                customer_address2: item.customer_address2,
                street: item.street,
                city: item.city,
                pin_code:item.pincode,
                mobile_no:item.mobile,
                meter_id:item.meterId,
                site_id:item.siteId
            }
            this.props.history.push({
                pathname: "/customer-view-page",
                state: rowItem
            })
        }
        else{
            this.props.history.push({
                pathname: "/customer-view-page",
                state: null
            })
        }
    }
    
    handleDelete = async (item) => {
        if (window.confirm("Are you sure you want to delete this customer?")) {
            this.setState({isLoading: true});
            let deleteResponse = await axios.delete(`${ API_BASE_URL }/customer/${item}`);
            if(deleteResponse.status === 200){
                let fetchResponse = await this.getAllCustomer();
                this.setState({
                    customerList: fetchResponse.data.result,
                    isLoading: false
                });
                ShowToast('Customer deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            }
            else{
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
            }
        }
    }

    handleFile = async (fileData) => {
        if (fileData[0].errors.length === 0) {
            const importPromise = fileData.map( async (importItem, index) => {
                if(index !== 0) {
                    const innerPromiseResult = await new Promise(async (resolve) => {
                        const meterData = await axios(`${API_BASE_URL}/meter/serialNo/${importItem.data[2]}`);
                        const customerData = await axios(`${API_BASE_URL}/customer/meter_serial/${importItem.data[2]}`);
                        if(fileData[index].data[0] && fileData[index].data[2] && fileData[index].data[3] && fileData[index].data[4] && fileData[index].data[5] && fileData[index].data[6] && fileData[index].data[7] && fileData[index].data[8]){
                            resolve({
                                customer_name: fileData[index].data[0],
                                consumer_no: fileData[index].data[1],
                                assigned_meter: fileData[index].data[2],
                                address1: fileData[index].data[3],
                                address2: fileData[index].data[4],
                                street: fileData[index].data[5],
                                city: fileData[index].data[6],
                                pincode: fileData[index].data[7],
                                mobile_number: fileData[index].data[8],
                                site_id: sessionStorage.getItem("siteId"),
                                meter_id:meterData.data.result.length > 0 ? meterData.data.result[0]._id : null,
                                is_meter_present: meterData.data.result.length > 0 ? true : false,
                                is_meter_assign: customerData.data.result !== null  ? true : false
                            });
                        }
                        else{
                            resolve(null);
                        }
                        
                    });
                    return innerPromiseResult;
                }
            });
            let importResult = await Promise.all(importPromise);
            importResult =importResult.filter((mapItem) => mapItem !== null);
            importResult = importResult.filter((item) => item);
            if(importResult.length > 0){
                this.setState({
                    importList: [...importResult]
                }) 
            }
        }
        else {
            ShowToast('Something went wrong. Please try after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
        }
    };

    isFormValid = () => {
        let isValid = true;

        this.state.importList.filter((item, index) => {
            if(item.is_meter_present === false || item.is_meter_assign === true){
                isValid = false;
            }
            return isValid;
        });
       
        if(isValid === false){
            ShowToast('Meter not present or Meter already assigned. Please provide different meter Serial number and resubmit.', TYPE_FAIL, INTERVAL, this.props, ""); 
            this.setState({
                importList: this.state.importList,
                isLoading: false
            });
        }
        return isValid;
    }

    importCustomerHandler = async (event) => {
        let requestParameter= null;
        event.preventDefault();
        this.setState ({isLoading: true,});
        if(this.isFormValid()){
            const sitePromise = this.state.importList.map(async(item, index) => {
                return new Promise( async (resolve) => {
                    requestParameter = {
                        customerName: item.customer_name,
                        consumerNumber: item.consumer_no,
                        serialNumber: item.assigned_meter,
                        customerAddress1: item.address1,
                        customerAddress2: item.address2,
                        customerStreet: item.street,
                        customerCity: item.city,
                        customerPincode: item.pincode,
                        customerMobileNo: item.mobile_number,
                        siteId: item.site_id,
                        meterId:item.meter_id
                    }
                    const customerResponse = await axios.post(`${API_BASE_URL}/customer`, requestParameter);
                    resolve(customerResponse);
                })
            });
            
            const customerResponse = await Promise.all(sitePromise);
            if(customerResponse.length > 0 && customerResponse.length === this.state.importList.length){
                ShowToast('Customer created successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                const customerData = await this.getAllCustomer();
                this.setState({
                    customerList: customerData.data.result,
                    importList: [],
                    isLoading: false
                });
            }
        }
    }

    cancelHandler = (event) => {
        window.location.reload();
    }

    renderAssignedMeter = (item) => {
        return (((item.is_meter_present === true) && (item.is_meter_assign === false) ) ? <span className="iconGreenColor">{item.assigned_meter + "   "}<i className={`${CHECK_ICON} iconGreenColor`} aria-hidden="true"></i></span> : <span className="iconRedColor">{item.assigned_meter + "   "}<i className={`${CLOSE_ICON} iconRedColor`} aria-hidden="true"></i></span>);
    }

    getBillInvoice = (rowData) => {
        try {
            showLoading();
            axios.get(`${API_BASE_URL}/generate-bill/${rowData.assignedMeter}`)
            .then((responseData) => {
                console.log(responseData.data);
                if(responseData.data.success){
                    if(Object.keys(responseData.data.result.result).length > 0){
                        hideLoading();
                        this.props.history.push({
                            pathname: "/billing-invoice",
                            state: {...responseData.data.result.result, pageName: "Customer", pageLink: "/customer-page", pageData: rowData}
                        });
                    }
                    else{
                        ShowToast(responseData.data.message, TYPE_SUCCESS, INTERVAL, this.props, ""); 
                    }
                }
                else{
                    ShowToast(responseData.data.message, TYPE_FAIL, INTERVAL, this.props, ""); 
                }
            })
            .catch((error) => {
                console.log("Error while fetching invoice", error);   
                ShowToast("Error while fetching invoice", TYPE_FAIL, INTERVAL, this.props, "");  
            });
        } catch (error) {
            console.log("Error while fetching invoice", error);
            ShowToast("Error while fetching invoice", TYPE_FAIL, INTERVAL, this.props, "");            
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            if(this.state.importList.length === 0){
                return (
                    <div className="main-content">
                        <div className="list-wrapper"> 
                            <div className="back">
                                <ul className="breadcrumb1">
                                    <li>Customer Master</li>
                                </ul>
                            </div>
                            <div>
                                <div className="customer-button-div">
                                    {
                                        hasPermission("customer", "create") && (
                                            <>
                                            <button className="brand-button" onClick={ () => this.addCustomerHandler(null) }>{ this.state.buttonText }</button>
                                            <CSVFileReader handleFile={this.handleFile} />
                                            <label className="csv-link-label" onClick={() =>{FileDownloader(SAMPLE_FILE_URL+ "customer.csv" , "customer.csv")}}>
                                                Sample Import CSV
                                            </label>
                                            </>
                                        )
                                    }
                                </div>
                                <div  className="mt-4 border list-table-div" style={{marginBottom:50}}>
                                {
                                    hasPermission("customer", "read") ? (
                                        this.state.customerList.length > 0 && (
                                            <MaterialTable
                                            columns={[
                                                { title: "Customer Name", field: "customerName",
                                                cellStyle: {cellWidth: '14% !important', fontWeight:'bolder', color:'#000000', fontSize:14}
                                                },
                                                { title: "Consumer No", field: "consumerNo", 
                                                cellStyle: { cellWidth: '12%', color:'#808080', fontSize:14 },
                                                sorting:false 
                                                },
                                                { title: "Assigned Meter", field: "assignedMeter", 
                                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 },
                                                sorting: false
                                                },
                                                { title: "Address1", field: "address1", 
                                                cellStyle: {cellWidth: '9%', color:'#808080', fontSize:14}
                                                },
                                                { title: "Address2", field: "address2", 
                                                cellStyle: { cellWidth: '9%', color:'#808080', fontSize:14} 
                                                },
                                                { title: "Street", field: "street", 
                                                cellStyle: {cellWidth: '7%', color:'#808080', fontSize:14 } 
                                                },
                                                { title: "City", field: "city", 
                                                cellStyle: {cellWidth: '7%', color:'#808080', fontSize:14 } 
                                                },
                                                { title: "Pincode", field: "pincode", 
                                                cellStyle: {cellWidth: '9%', color:'#808080', fontSize:14 } 
                                                },
                                                { title: "Mobile", field: "mobile", 
                                                cellStyle: {cellWidth: '9%', color:'#808080', fontSize:14 } 
                                                },
                                            ]}
                                            data={
                                                this.state.customerList.map((item, index) => {
                                                    return (
                                                        {
                                                            customerName: item.customer_name,
                                                            consumerNo: item.consumer_no != null ? (item.consumer_no !== '' ? item.consumer_no : "none") : "none",
                                                            assignedMeter: item.serial_number !== null ? item.serial_number : "none" ,
                                                            address1: item.customer_address1,
                                                            address2: item.customer_address2,
                                                            street: item.street,
                                                            city: item.city,
                                                            pincode: item.pin_code,
                                                            mobile: item.mobile_no,
                                                            customerId:item._id,
                                                            meterId:item.meter_id,
                                                            siteId: item.site_id,
                                                            customer_name: item.customer_name,
                                                            customer_address1:item.customer_address1,
                                                            customer_address2: item.customer_address2
                                                        }
                                                    )
                                                })
                                            }
        
                                            actions={[
                                                {
                                                    icon: () => <i className={hasPermission("customer", "update") ? `${FILE_ICON}` : "not-active"} title="Generate bill"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("customer", "update") ? this.getBillInvoice(rowData) : ""
                                                },
                                                {
                                                    icon: () => <i className={hasPermission("customer", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit customer"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("customer", "update") ? this.addCustomerHandler(rowData) : ""
                                                },
                                                {
                                                    icon: () => <i className={hasPermission("customer", "delete") ? `${EYE_ICON}` : "not-active"} title="View customer"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("customer", "delete") ? this.addViewHandler(rowData) : ""
                                                },
                                                {
                                                    icon: () => <i className={hasPermission("customer", "delete") ? `${TRASH_ICON}` : "not-active"} title="Delete customer"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("customer", "delete") ? this.handleDelete(rowData.customerId) : ""
                                                }
                                            ]}
                                            onRowClick={(event, rowData) => this.addCustomerHandler(rowData)}
                                            options={{
                                                actionsColumnIndex: -1,
                                                pageSize:25,
                                                pageSizeOptions:[25,50,75,100],
                                                headerStyle: {
                                                    color: '#999998',
                                                    fontWeight: 'bolder',
                                                    fontSize: 15
                                                },
                                                filtering: true,
                                                sorting: true,
                                                columnsButton: true,
                                                exportButton: true,
                                                exportAllData: true, 
                                                exportFileName: "Customer Data"
                                            }}
                                        />
                                        )
                                    ):(
                                        <div className="text-center pt-2">
                                            <label>You dont have permission to see list</label>
                                        </div>
                                    )
                                    
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else{
                return(
                    <>
                    <div className="customer-button-div">
                        {
                            hasPermission("customer", "create") && (
                                <>
                                <button className="brand-button" onClick={ () => this.addCustomerHandler(null) }>{ this.state.buttonText }</button>
                                <CSVFileReader handleFile={this.handleFile} />
                                </>
                            )
                        }
                    </div>
                    <div  className="mt-4 border list-table-div">
                    <MaterialTable
                        title = {<div className="csv-title">IMPORTED CUSTOMER DATA</div>}
                        columns={[
                            { title: "Customer Name", field: "customerName",
                            cellStyle: {cellWidth: '14% !important', fontWeight:'bolder', color:'#000000', fontSize:14}
                            },
                            { title: "Consumer No", field: "consumerNo", 
                            cellStyle: { cellWidth: '12%', color:'#808080', fontSize:14 },
                            sorting:false 
                            },
                            { title: "Assigned Meter", field: "assignedMeter", 
                            cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 },
                            sorting: false
                            },
                            { title: "Address1", field: "address1", 
                            cellStyle: {cellWidth: '9%', color:'#808080', fontSize:14}
                            },
                            { title: "Address2", field: "address2", 
                            cellStyle: { cellWidth: '9%', color:'#808080', fontSize:14} 
                            },
                            { title: "Street", field: "street", 
                            cellStyle: {cellWidth: '7%', color:'#808080', fontSize:14 } 
                            },
                            { title: "City", field: "city", 
                            cellStyle: {cellWidth: '7%', color:'#808080', fontSize:14 } 
                            },
                            { title: "Pincode", field: "pincode", 
                            cellStyle: {cellWidth: '9%', color:'#808080', fontSize:14 } 
                            },
                            { title: "Mobile", field: "mobile", 
                            cellStyle: {cellWidth: '9%', color:'#808080', fontSize:14 } 
                            },
                        ]}
                        data={
                            this.state.importList.map((item, index) => {
                                return (
                                    {
                                        customerName: item.customer_name,
                                        consumerNo: item.consumer_no !== '' ? item.consumer_no : '',
                                        assignedMeter: this.renderAssignedMeter(item),
                                        address1: item.address1,
                                        address2: item.address2,
                                        street: item.street,
                                        city: item.city,
                                        pincode: item.pincode,
                                        mobile: item.mobile_number,
                                    }
                                )
                            })
                        }
                        options={{
                            headerStyle: {
                                color: '#999998',
                                fontWeight: 'bolder',
                                fontSize: 15
                            },
                        }}
                    />
                    </div>
                    <div className="site-button-div" style={{marginTop: 30, marginBottom:50, marginLeft:17}}>
                        <button className="brand-button" onClick={ this.importCustomerHandler }>Submit</button>
                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                    </div>
                    </>
                )
            }
        }
    }
}

export default CustomerPage
