import React, { Component } from 'react';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);
console.log("Plot::",Plot)

export class ReadingGraphPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avarageReadingsData: [],
            monthNames: [],
            daysDiffrence: [],
        };
    }

    componentDidMount() {
        const meterAvarageReadingData = this.props.avarageReadingsData;
        const metersBillingCycleMonth = this.props.monthNames;
        const dayDiffrenece = this.props.daysDiffrence;

        const data = [{
            x: metersBillingCycleMonth,
            y: meterAvarageReadingData,
            type: 'bar',
            marker: {
                color: 'skyblue',
            },
            width: [0.3, 0.3, 0.3, 0.3, 0.3],
            hoverinfo: 'text',
            text: dayDiffrenece.map((value) => `Day Difference: ${value}`),
            textposition: 'none'
        }];

        const annotations = data[0].y.map((value, index) => {
            console.log("value::", value)
            let textReadings;
            if (value === null) {
                textReadings = '0';
            } else if (value !== '' || null) {
                textReadings = value.toFixed(2);
            } else {
                textReadings = '0';
            }

            return {
                x: data[0].x[index],
                y: value,
                text: textReadings,
                xanchor: 'center',
                yanchor: 'bottom',
                showarrow: false
            };
        });

        const layout = {
            yaxis: {
                automargin: true,
                zeroline: true,
                fixedrange: true,
                range: [
                    Math.min(...meterAvarageReadingData),
                    Math.max(...meterAvarageReadingData)
                ],
                title: 'Readings',
                tickfont: {
                    size: 14,
                    color: 'black',
                    style: 'bold',
                },
            },
            bargap: 0.2,
            title: 'Meter Reading Graph',
            xaxis: {
                title: 'Billing Cycle Months',
                tickfont: {
                    size: 14,
                    color: 'black',
                    style: 'bolder',
                },
            },
            annotations: annotations,
            plot_bgcolor: '',
            paper_bgcolor: '',
            barcornerradius: 10,
        };

        Plotly.newPlot('Plot', data, layout);
    }

    render() {
        console.log("!this.props.pageName::", this.props.pageName);
        return (
            <div>
                <div id="Plot" style={{ width: "80%", height: "80vh", marginLeft: "60px", justifyContent: "center" }} />
            </div>
        );
    }
}

export default ReadingGraphPage;
