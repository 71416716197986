import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from 'assets/constants/Constants';
import { TRASH_ICON, EDIT_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from 'react-select';
import { defaultFormatDate } from "lib/utils"
import { hasPermission }  from "components/Permission";
import MaterialTable from "material-table";

export class BillingCyclePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            selectedRoute: null,
            routeOptions: [],
            routeList: [],
            billingList:[],
            transactionId:null,
            startDate: moment().startOf('day').toDate(),
            endDate: moment().endOf('day').toDate(),
            reminderEmailSend:false,
            billingId:null,
            error: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeData = this.handleChangeData.bind(this);
    }

    async componentDidMount() {
        const routeData = await this.getRouteList();
        const billingData = await this.getBillingDetails();
        this.setState({
            routeList:routeData.data.result,
            billingList:billingData.data.result,
            isLoading: false,
        }, () => {
            this.renderRouteOptions(this.state.routeList);
        });

    }

    getRouteList = async () => {
        return await axios(`${API_BASE_URL}/routes/site/${sessionStorage.getItem("siteId")}?limit=${this.state.pageSize}&page=${this.state.currentPage}`);
    }

    getBillingDetails = async () => {
        return await axios(`${API_BASE_URL}/billing/sitewise/${sessionStorage.getItem("siteId")}`);
    }

    getRouteList = async () => {
        return await axios(`${API_BASE_URL}/billing/route/not_in_billing_cycle/${sessionStorage.getItem("siteId")}`);
    }

    async refreshRouteList(){
        const routelist = await this.getRouteList();
        this.setState({
            routeList: routelist.data.result,
        }, () => {
            this.renderRouteOptions(this.state.routeList);
        });
        
    }

    renderRouteOptions = (routeList) => {
        let routeOptions = [];
        if(routeList.length > 0) {
            routeList.forEach((routeItem) => {
                routeOptions.push({ value: routeItem._id, label: routeItem.route_name })
            });
        }
        this.setState({
            routeOptions
        });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        })
    }

    handleChangeData(date) {
        this.setState({
            endDate: date
        })
    }

    routeChangeHandler = (event) => {
        this.setState({
            selectedRoute: event,
            routeId: event.value,
            routeName:event.label
        });
    }

    emailSentBeforeHandler = (event) => {
        this.setState({
           emailSentBefore : event.target.value
        });
    }

    saveBillingSycleHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()){
            this.setState ({isLoading: true });
            const userDetails = JSON.parse(sessionStorage.getItem("userData"));
            const requestParameter = {
                routeId : this.state.routeId,
                siteId:sessionStorage.getItem("siteId"),
                routeName : this.state.routeName,
                startDate : this.state.startDate,
                endDate : this.state.endDate,
                emailSentBefore : this.state.emailSentBefore,
                reminderEmailSend :this.state.reminderEmailSend,
                userFirstName:userDetails.first_name,
                userLastName:userDetails.last_name,
                userEmailId:userDetails.email_id,
                userName:userDetails.username,
                userId:userDetails._id,
                reason:"Route added in billing cycle",
                source: "Web"
            }
            let billingResponse = null;
            if (this.state.billingId === null){
                billingResponse = await axios.post(`${API_BASE_URL}/billing`, requestParameter);
            }
            else{
                billingResponse = await axios.put(`${API_BASE_URL}/billing/${this.state.billingId}`, requestParameter);
                let transactionData = await axios(`${API_BASE_URL}/billing_transaction/last_transaction/${this.state.routeId}/${sessionStorage.getItem("siteId")}`);
                this.setState({
                    transactionId:transactionData.data.result[0]._id
                });
                let transactionResponse = await axios.put(`${API_BASE_URL}/billing_transaction/${this.state.transactionId}`, requestParameter);
                console.log("Transaction Response", transactionResponse);
            }
            const tempBillingId = this.state.billingId;
            if(billingResponse.status === 200){
                const billingData = await this.getBillingDetails();
                if(tempBillingId === null){
                    ShowToast('Billing cycle added successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                }
                else{
                    ShowToast('Billing cycle updated successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                }
                this.refreshRouteList();
                this.setState({
                    billingList:billingData.data.result,
                    routeId:"0",
                    selectedRoute: "",
                    emailSentBefore:'',
                    billingId:null,
                    isLoading:false
                })
            }
            else{
                ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, this.props, ""); 
            }   
        }
    }

    isEditValid = (item) => {
        let isValid = true;
        if (moment().toDate() >= moment(item.start_date).toDate()) {
            ShowToast('You could not perform this operation.', TYPE_FAIL, INTERVAL, this.props, ""); 
            isValid = false;
        }
        return isValid;
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.routeId) {
            isValid = false;
            error.routeId = "Please enter route name";
        }

        if (!this.state.emailSentBefore) {
            isValid = false;
            error.emailSentBefore = "Enter day when  email will sent before";
        }

        if (this.state.endDate < this.state.startDate) {
            isValid = false;
            error.endDate = "End date should not be less than start date ";
        }

        if ((this.state.endDate.getDate() >= 29) && (this.state.endDate.getDay() <= 31)){
            isValid = false;
            error.endDate = "Please select date between 01-28th of every month";
        }

        if ((this.state.startDate.getDate() >= 29) && (this.state.startDate.getDay() <= 31)){
            isValid = false;
            error.startDate = "Please select date between 01-28th of every month";
        }

        this.setState({
            error
        });
        return isValid;
    }

    handleDelete = async (item) => {
        if(this.isEditValid(item)){
            if (window.confirm("Are you sure you want to delete this billing cycle?")) {
                this.setState({isLoading: true});
                let deleteResponse = await axios.delete(`${ API_BASE_URL }/billing/${item._id}`);
                let transactionData = await axios(`${API_BASE_URL}/billing_transaction/last_transaction/${item.route_id}/${sessionStorage.getItem("siteId")}`);
                this.setState({
                    transactionId:transactionData.data.result[0]._id
                });
                let transactionResponse = await axios.delete(`${API_BASE_URL}/billing_transaction/${this.state.transactionId}`);
                console.log("Delete Transaction Response", transactionResponse);
                if(deleteResponse.status === 200){
                    const billingData = await this.getBillingDetails();
                    this.setState({
                        billingList:billingData.data.result,
                        isLoading: false
                    });
                    this.refreshRouteList();
                    ShowToast('Billing cycle deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                }
            }
        }
    }

    handleEdit = async (event) => {
        if(this.isEditValid(event)){
            let startDateFormat = new Date(event.start_date);
            let endDateFormat = new Date(event.end_date);
            let routeOptions = [];
            routeOptions.push({ value: event.route_id, label: event.route_name })

            this.setState({
                isLoading: false,
                routeId:event.route_id,
                routeName:event.route_name,
                selectedRoute: routeOptions,
                emailSentBefore:event.email_sent_before,
                reminderEmailSend:event.reminder_email_send,
                billingId:event._id,
                startDate:startDateFormat,
                endDate: endDateFormat
            }); 
        }
    }

    showBillingTransactionHandler = (item) => {
        if(item){
            const rowItem = {
                _id:item._id,
                route_id:item.route_id,
                route_name: item.route_name,
                email_sent_before: item.email_sent_before,
                reminder_email_send:item.reminder_email_send,
                start_date: item.start_date,
                end_date: item.end_date,
            }
            this.props.history.push({
                pathname: "/billing-transaction-page",
                state: {rowItem, pathName:"billing-cycle-page"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/billing-cycle-page",
                state: null
            });
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li>Billing Cycle</li>
                        </ul>
                    </div>
                    <div className="list-wrapper">
                        <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                    <div className="row">
                                        <div className="addUser">
                                            <label className="form-label"> Select Route  <span className="text-danger">*</span></label>
                                            <Select
                                                placeholder={'Select Route'}
                                                value={this.state.selectedRoute}
                                                onChange={this.routeChangeHandler}
                                                options={this.state.routeOptions}
                                                isSearchable={true}
                                                isMulti={false}
                                            />
                                            <div className="text-danger error-msg">
                                                {this.state.error.routeId}
                                            </div>
                                        </div>
                                        <div className="addUser">
                                        <label className="form-label">Email send before (days) <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholder-class" value = {this.state.emailSentBefore} onChange = {this.emailSentBeforeHandler} placeholder="Enter day when email will sent before" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.emailSentBefore}
                                        </div>
                                    </div>  
                                    </div>
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">from Date <span className="text-danger">*</span></label>
                                        <div className="calender-div">
                                            <span className="icon fa-calendar" />
                                            <DatePicker className="form-control brand-font p-3"
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.startDate}
                                        </div>
                                    </div>
                                    <div className="addUser">
                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                        <div className="calender-div">
                                            <span className="icon fa-calendar" />
                                            <DatePicker className="form-control brand-font p-3"
                                                selected={this.state.endDate}
                                                onChange={this.handleChangeData}
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.endDate}
                                        </div>
                                    </div>
                                </div>
                            <div className="addButton">
                                <button id="button" className="brand-button"  title= "Submit Billing Cycle" onClick={this.saveBillingSycleHandler}>Submit</button>
                            </div>
                        </div>

                        <div className="mt-4 border list-table-div" style={{marginBottom:30}}>
                            {
                                this.state.billingList.length > 0 ? (
                                    <MaterialTable
                                        columns={[
                                            { title: "Route Name", field: "route_name", 
                                            cellStyle: {cellWidth: '20%', fontWeight:'bolder', color:'#000000', fontSize:14 }
                                            },
                                            { title: "Start Date", field: "start_date1", 
                                            cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14 },
                                            },
                                            { title: "End Date", field: "end_date1", 
                                            cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 },
                                            },
                                            { title: "Email sent before (days)", field: "email_sent_before", 
                                            cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 }
                                            },
                                            { title: "Reminder", field: "reminder_email_send1", 
                                            cellStyle: {cellWidth: '20%', color:'#808080', fontSize:14 }
                                            }
                                        ]}
                                        data={
                                            this.state.billingList.map((item, index) => {
                                                let reminderStatusClass = "text-success";
                                                if(item.reminder_email_send === true){
                                                    reminderStatusClass = "text-success";
                                                }
                                                else if(item.reminder_email_send === false){
                                                    reminderStatusClass = "text-warning";
                                                }
                                                return (
                                                    {
                                                        _id:item._id,
                                                        route_id:item.route_id,
                                                        route_name: item.route_name,
                                                        start_date1: defaultFormatDate(item.start_date),
                                                        end_date1: defaultFormatDate(item.end_date),
                                                        email_sent_before: item.email_sent_before,
                                                        reminder_email_send1:  <span className={`${reminderStatusClass}`}>{item.reminder_email_send !== false ? "Sent" : "Not Sent"}</span>,
                                                        reminder_email_send:item.reminder_email_send,
                                                        start_date: item.start_date,
                                                        end_date: item.end_date,
                                                    }
                                                )
                                            })
                                        }
                                        onRowClick={(event, rowData) => this.showBillingTransactionHandler(rowData)}

                                        actions={[
                                            {
                                                icon: () => <i className={hasPermission("billing", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit Billing Cycle"></i>,
                                                onClick: (event, item) =>
                                                    hasPermission("billing", "update") ? this.handleEdit(item) : ""
                                            },
                                            {
                                                icon: () => <i className={hasPermission("billing", "delete") ? `${TRASH_ICON}` : "not-active"} title="Delete Billing Cycle"></i>,
                                                onClick: (event, item) =>
                                                    hasPermission("billing", "delete") ? this.handleDelete(item) : ""
                                            }
                                        ]}
                                        options={{
                                            actionsColumnIndex: -1,
                                            pageSize:25,
                                            pageSizeOptions:[25,50,75,100],
                                            headerStyle: {
                                                color: '#999998',
                                                fontWeight: 'bolder',
                                                fontSize: 15
                                            },
                                        }}
                                    />
                                ):(
                                    <div className="text-center pt-2"> No Records Found!</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default BillingCyclePage