import React, { Component } from 'react'
import Spinner from 'components/Spinner';
import { CONFIG_ICON } from 'assets/constants/Icons';


export class MeterConfigurationPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            meterData,
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    cancelHandler = (event) => {
        this.props.history.push("/meter-page")
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/meter-page">Meter</a></li>
                            <li>Meter Configuration Page</li>
                        </ul>
                    </div>
                    <div className="add-meter-main-view">
                        <div className="mx-auto text-left border create-meter-div">
                            <form className="pt-1">
                                <div className="meter-icon-wrapper">
                                    <h4>Meter Configuration Settings</h4>
                                    <i className={`${CONFIG_ICON} list-table-icon`}></i>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6" >
                                        <label className="form-label-dark">Meter Serial Number </label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker ">
                                            {this.state.meterData.serial_number ? this.state.meterData.serial_number : "NA"}
                                        </span>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Type </label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.meter_type ? this.state.meterData.meter_type : "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter AMR Channel </label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.amr_channel ? this.state.meterData.amr_channel : "NA"}
                                        </span>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Status</label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.status ? this.state.meterData.status.charAt(0).toUpperCase() + this.state.meterData.status.slice(1) : "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Software Version </label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.swVersion ? this.state.meterData.swVersion : "NA"}
                                        </span>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Hardware Version</label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.hwVersion ? this.state.meterData.hwVersion : "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Total Unit </label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.totalUnit ? this.state.meterData.totalUnit : "NA"}
                                        </span>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Erase Entire Data Log </label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.eraseEntireDataLog ? this.state.meterData.eraseEntireDataLog.charAt(0).toUpperCase() + this.state.meterData.eraseEntireDataLog.slice(1) : "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Log Enabled</label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.logEnabled ? this.state.meterData.logEnabled.charAt(0).toUpperCase() + this.state.meterData.logEnabled.slice(1) : "NA"}
                                        </span>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Flow Unit</label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.flowUnit ? this.state.meterData.flowUnit : "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Unit</label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.unit ? this.state.meterData.unit.charAt(0).toUpperCase() + this.state.meterData.unit.slice(1) : "NA"}
                                        </span>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label-dark">Meter Version</label>
                                        <span className="form-control brand-font p-3 placeholderColor add-meter-input text-darker">
                                            {this.state.meterData.meterVersion ? this.state.meterData.meterVersion : "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className="site-button-div" style={{ marginTop: 30, marginBottom: 50, marginLeft: 17 }}>
                                    <button className="brand-button" onClick={this.cancelHandler}>Done</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MeterConfigurationPage
