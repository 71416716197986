import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL } from "assets/constants/Constants";
import Spinner from 'components/Spinner';
import { defaultFormatDate } from "lib/utils"

export class BillingTransactionPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        console.log("meter Data", meterData);
        this.state = {
            isLoading: true,
            routeMeterInfo: this.props.history.location.state,
            billingList: [],
            error: {},
        }
    }

    async componentDidMount() {
        const billingTransactionData = await this.getBillingTransaction();
        this.setState({
            billingList: billingTransactionData.data.result,
            isLoading: false
        });
    }

    getBillingTransaction = async () => {
        return await axios(`${API_BASE_URL}/billing_transaction/route/${this.state.routeMeterInfo.rowItem.route_id}/${sessionStorage.getItem("siteId")}`);
    }
    
    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            {
                                <>
                                    <li><a href={this.state.routeMeterInfo.pathName}>Billing Cycle</a></li>
                                    <li>Billing Transaction</li>
                                </>
                            }
                            
                        </ul>
                    </div>
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                        {
                            this.state.billingList.length > 0 ? (
                                <table className="table list-table">
                                    <thead>
                                        <tr>
                                            <th className="pl1">Route Name</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Email sent before (days)</th>
                                            <th>Reminder</th>
                                        </tr>
                                    </thead>
                                    <tbody className="reading-list-table">
                                    {
                                        this.state.billingList.map((item) => {
                                            let reminderStatusClass = "text-success";
                                            if(item.reminder_email_send === true){
                                                reminderStatusClass = "text-success";
                                            }
                                            else if(item.reminder_email_send === false){
                                                reminderStatusClass = "text-warning";
                                            }
                                            return (
                                                <tr key={ item._id }>
                                                    <td className="routename-td pl1" > { item.route_name } </td>
                                                    <td className="startdate-td" > { defaultFormatDate(item.start_date) } </td>
                                                    <td className="startdate-td" > { defaultFormatDate(item.end_date) } </td>
                                                    <td className="emailsent-td" > { item.email_sent_before } </td>
                                                    <td className="reminder-td" > <span className={`${reminderStatusClass}`}>{item.reminder_email_send !== false ? "Sent" : "Not Sent"}</span> </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            ):(
                                <div className="text-center pt-2"> No Records Found!</div>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}

export default BillingTransactionPage