import React from "react";

function Footer() {
  return (
    <div className = "main-footer">
      <p>[ © 2021 SBEM DOMWM Web Server ]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ v1.0 ]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ ® All rights reserved ]</p>
    </div>
  );
}

export default Footer;          