import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { REFRESH_ICON, CAMERA_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import MaterialTable from "material-table";
import Tooltip from '@material-ui/core/Tooltip'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Utils from "lib/utils";
import ShowToast from 'components/ShowToast';
import ReadingListTable from 'views/report/ReportComponent/ReadingListTable';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver'; 
import { defaultFormatDateTime } from "lib/utils"

export class MeterReadingPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            meterData,
            metadata: {},
            meterSiteData: {},
            customerData: {},
            readingList: [],
            onnileIconClass: "enable-icon",
            toggleClass: true,
            routeName: "",
            startDate: moment().startOf('day').toDate(),
            endDate: moment().endOf('day').toDate(),
            maxEndDate: new Date(),
            isSyncVisible: true
        }

        this.toggleClassInterval = null;
        this.readingInterval = null;
    }

    async componentDidMount() {
        const readingData = await this.getReadingData();
        this.toggleClassInterval = setInterval(() => {
            this.setState({
                toggleClass: !this.state.toggleClass,
                onnileIconClass: this.state.toggleClass ? "enable-icon" : "disable-icon"
            })
        }, 1000);
        this.readingInterval = setInterval(async () => {
            const readingData = await this.getReadingData();
            this.setState({
                readingList: readingData.data.result.readingData,
                routeName: readingData.data.result.route_name
            })
        }, 5000);
        
        this.setState({
            metadata: readingData.data.result,
            readingList: readingData.data.result.readingData,
            isLoading: false
        });
    }
    
    componentWillUnmount() {
        clearInterval(this.toggleClassInterval);
        clearInterval(this.readingInterval);
        this.toggleClassInterval = null;
        this.readingInterval = null;
    }

    getReadingData = async () => {
        if(this.state.meterData.pageName === "Meter"){
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}/${this.state.meterData.rowItem.route_id}`);
        }
        else if(this.state.meterData.pageName === "AMI Readings"){
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.rowItem.serial_number}`);
        }
        else if(this.state.meterData.pageName1 === "Route Meter Mapping"){
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.routeItem.serial_number}/${this.state.meterData.routeItem.route_id}`);
        }
        else{
            return await axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.serial_number}`);
        }
    }

    renderStatus = (item) => {
        if(this.state.meterData.pageName === "Meter"){
            if(this.state.meterData.rowItem.serial_number === item){
                return this.state.meterData.rowItem.status;
            }
        }
        else if(this.state.meterData.pageName1 === "Route Meter Mapping"){
            if(this.state.meterData.routeItem.serial_number === item){
                return this.state.meterData.routeItem.status;
            }
        }
        else if(this.state.meterData.serial_number === item){
            return this.state.meterData.status;
        }
    }

    routeMeterMapping = (item) => {
        const rowItem = {
            _id: item.routeItem.route_id,
            site_id: item.routeItem.site_id,
            route_name: this.state.routeName,
            street: item.routeItem.street,
            city: item.routeItem.city,
            pin_code: item.routeItem.pin_code,
        }
        if(this.props.history.location.state.pageName === "Route Master"){
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: { rowItem, isVisibleAddMeter:true, pageName:"Route Master", pathName:"master-route-page", pageName1:"Route Meter Mapping"}
            });
        }
        else if(this.props.history.location.state.pageName === "Route Transaction"){
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: { rowItem, isVisibleAddMeter:true, pageName:"Route Transaction", pathName:"route-transaction-page", pageName1:"Route Meter Mapping" }
            });
        }
        else if(this.props.history.location.state.pageName === "Route Collection History"){
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: { rowItem, isVisibleAddMeter:true, pageName:"Route History", pathName:"route-history", pageName1:"Route Meter Mapping" }
            });
        }
    }

    getAMIReportReadings = async () => {
        try {
            this.setState({
                readingList: [],
                isSyncVisible: false
            });
            let interval = 5000;
            
            if(this.toggleClassInterval && this.toggleClassInterval){
                Utils.showLoading("Stop syncing before getting report data...");
                clearInterval(this.toggleClassInterval);
                clearInterval(this.readingInterval);
                this.toggleClassInterval = null;
                this.readingInterval = null;
            }
            else{
                interval = 0;
            }
           
            setTimeout(async() => {
                Utils.showLoading("Fetching report data...");
                const readingData = await axios(`${API_BASE_URL}/readings/ami/${this.state.meterData.rowItem.serial_number}/${moment(this.state.startDate).valueOf()}/${moment(this.state.endDate).valueOf()}`);
                Utils.hideLoading();
                if(readingData.data.result.length > 0){
                    ShowToast('Report created successfully', TYPE_SUCCESS, INTERVAL, null, ""); 
                    var excelDownloadBtn = document.getElementById("download-excel-button");
                    if(excelDownloadBtn){
                        excelDownloadBtn.classList.add("mystyle");
                    }
                    this.setState({
                        readingList: readingData.data.result
                    });
                }
                else{
                    ShowToast('There is no data to fetch!', TYPE_SUCCESS, INTERVAL, null, ""); 
                }
            }, interval);
        } catch (error) {
            ShowToast('Something went wrong.', TYPE_FAIL, INTERVAL, null, ""); 
            Utils.hideLoading();
        }
    }

    exportToXLSX = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            const headersToUse = [
                "Serial Number",
                "Web Receive Time",
                "Route",
                "Gateway",
                "Reader",
                "Unit",
                "Meter Status",
                "Alarm Type",
                "Meter Time",
                "Readings",
                "Photo",
                "RSSI",
                "SSR"
            ];

            const headerDataMapping = {
                "Serial Number": "meter_serial_no",
                "Web Receive Time": "created_date_time",
                "Route": "route_name",
                "Gateway": "gway_device_id",
                "Reader": "assigned_user_id",
                "Unit": "unit",
                "Meter Status": "meter_status",
                "Alarm Type": "alarm_type",
                "Meter Time": "receive_date_time",
                "Readings": "meter_reading",
                "Photo": "reading_image",
                "RSSI": "field_strength_rssi",
                "SSR": "snr"
            };

            const dateFormatArray = ["created_date_time"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }

            const headerRow = worksheet.addRow(headersToUse);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });

            this.state.readingList.forEach((row) => {
                const rowData = headersToUse.map(header => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formattedDate = defaultFormatDateTime(row[dataProperty]);
                        return formattedDate !== "Invalid date" ? formattedDate : "NA";
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });

            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `AMI-meter-report-${moment().format('DD-MM-YYYY hh:mm:ss a')}`);
        } catch (error) {
            console.error(error);
        }
    };

    handleStartDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleEndDateChange = (date) => {
        this.setState({
            endDate: date
        })
    }

    viewImage = (image) => {
        if(image !== "" && image !== undefined){
            this.setState({viewImage:true, imgSrc:image})
        }
        else{
            ShowToast("Image not uploaded yet.", TYPE_FAIL,INTERVAL, null, "");
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                        {
                                this.state.meterData.pageName === "Meter" ? (
                                    <>
                                        <li><a href={this.props.history.location.state.pathName}>{this.props.history.location.state.pageName}</a></li>
                                        <li>Meter Reading</li>
                                    </>
                                ) : (
                                    this.state.meterData.pageName === "AMI Readings" ? (
                                        <>
                                            <li><a href={this.props.history.location.state.pathName} >{this.props.history.location.state.pageName}</a></li>
                                            <li>Meter Reading</li>
                                        </>
                                    ) : (
                                        <>
                                            <li><a href={this.props.history.location.state.pathName} >{this.props.history.location.state.pageName}</a></li>
                                            <li><a href={this.props.history.location.state.pathName1} onClick={() => this.routeMeterMapping(this.props.history.location.state)}>{this.props.history.location.state.pageName1}</a></li>
                                            <li>Meter Reading</li>
                                        </>
                                    )
                                )
                            }
                        </ul>
                    </div>
                    <div className="reading-info-outer border">
                        <div className="reading-wrapper">
                            <div className="site-wrapper">
                                <div className="row">
                                    <div className="site-info">
                                        <div className="site-content">
                                            <label>Site Name: </label>
                                            <label className="ml-3">{this.state.metadata.site_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Site Address: </label>
                                            <label className="ml-3">{ `${this.state.metadata.site_street}, ${this.state.metadata.site_city}, ${this.state.metadata.site_pincode}` }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="customer-wrapper">
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label>Customer Name: </label>
                                            {
                                                this.state.meterData.pageName1 === "Route Meter Mapping" ? (
                                                    <label className="ml-3">{ this.state.meterData.routeItem.customer_name !=null ?  this.state.meterData.routeItem.customer_name : "NA"}</label>
                                                ):(
                                                    this.state.meterData.pageName === "Meter" ? (
                                                    <label className="ml-3">{ this.state.meterData.rowItem.customer_name !=null ?  this.state.meterData.rowItem.customer_name : "NA"}</label>
                                                    ):(
                                                        this.state.meterData.pageName === "AMI Readings" ? (
                                                            <label className="ml-3">{this.state.metadata.customer_name != null ? this.state.metadata.customer_name : "NA"}</label>
                                                        ):(
                                                            <label className="ml-3">{"NA"}</label>
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Address: </label>
                                            {
                                                this.state.meterData.pageName1 === "Route Meter Mapping" ? (
                                                    <label className="ml-3">{ this.state.meterData.routeItem.customer_address1 !== null ? (`${this.state.meterData.routeItem.customer_address1}, ${this.state.meterData.routeItem.customer_address2},${this.state.meterData.routeItem.street}, ${this.state.meterData.routeItem.city}, ${this.state.meterData.routeItem.pin_code}`) : "NA" }</label>

                                                ):(
                                                    this.state.meterData.pageName === "Meter" ? (
                                                        <label className="ml-3">{ this.state.meterData.rowItem.customer_address1!== null ? (`${this.state.meterData.rowItem.customer_address1}, ${this.state.meterData.rowItem.customer_address2}, ${this.state.meterData.rowItem.street}, ${this.state.meterData.rowItem.city}, ${this.state.meterData.rowItem.pin_code}`) : "NA" }</label>
                                                    ):(
                                                        this.state.meterData.pageName === "AMI Readings" ? (
                                                            <label className="ml-3">{this.state.metadata.customer_address1 != null ? this.state.metadata.customer_address1 + "," + this.state.metadata.customer_address2 + "," + this.state.metadata.customer_street + "," + this.state.metadata.customer_city : "NA"}</label>
                                                        ):(
                                                            <label className="ml-3">{"NA"}</label>
                                                        )
                                                    )
                                                )
                                            }       
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div></div>
                        </div>
                        <div className="mr-5 sync-wrapper sync-responsive">
                            {
                                this.state.isSyncVisible ? (
                                    <>
                                        <div className="ml-3">
                                            <i className={`${REFRESH_ICON} ${this.state.onnileIconClass} text-center mr-4 rotate`} />
                                        </div>
                                        <label className={`${this.state.onnileIconClass}`}>Syncing...</label>
                                    </>
                                ):(
                                    <label className={`disable-icon`}>Pause...</label>
                                )
                            }
                            
                        </div>
                    </div>
                    {
                        this.state.meterData.pageName === "AMI Readings" && (
                            <div>
                                <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                    <div className="row">
                                        <div className="addUser">
                                            <label className="form-label">From Date <span className="text-danger">*</span></label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker className="form-control brand-font p-3"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="addUser">
                                            <label className="form-label">To Date <span className="text-danger">*</span></label>
                                            <div className="calender-div">
                                                <span className="icon fa-calendar" />
                                                <DatePicker className="form-control brand-font p-3"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    timeIntervals={1}
                                                    timeCaption="time"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={this.state.maxEndDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="addButton">
                                        <button id="button" className="brand-button report-button" onClick={this.getAMIReportReadings}>Get Report</button>
                                        <button
                                            id="download-excel-button"
                                            className="download-table-xls-button brandxls-button download-button"
                                            onClick={this.exportToXLSX}>Download as XLS
                                    </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="mt-4 border list-table-div" style={{marginBottom:100}}>
                        {
                            this.state.readingList.length > 0 ? (
                                this.state.meterData.pageName === "AMI Readings" ? (
                                    <ReadingListTable readingList={this.state.readingList} pageName={"AMI Readings"}/>
                                ) : (
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: "Serial Number", field: "meter_serial_no",
                                                cellStyle: { cellWidth: '18%', fontWeight: 'bolder', color: '#000000', fontSize: 14 }
                                            },
                                            {
                                                title: "Route", field: "route_name1",
                                                cellStyle: { cellWidth: '20%', color: '#808080', fontSize: 14, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 },
                                                sorting: false, filtering: true
                                            },
                                            {
                                                title: "Aqualink Device Id", field: "sird_device_id",
                                                cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 },
                                                sorting: false
                                            },
                                            {
                                                title: "Reader", field: "assigned_user_id",
                                                cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Unit", field: "unit",
                                                cellStyle: { cellWidth: '12%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Meter Status", field: "status",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Reading Status", field: "meter_status",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title: "Alarm Type", field: "alarm_type1",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 }, filtering: true
                                            },
                                            {
                                                title: "Date", field: "receive_date_time",
                                                cellStyle: {
                                                    cellWidth: '14%', color: '#808080', fontSize: 14,
                                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100
                                                }
                                            },
                                            {
                                                title: "Reading", field: "meter_reading",
                                                cellStyle: { cellWidth: '14%', color: '#808080', fontSize: 14 }
                                            },
                                            {
                                                title:"Photo", field:"photo",
                                                cellStyle:{cellWidth:'5%', color:'#808080', fontSize:14},filtering: false
                                            }
                                        ]}
                                        data={
                                            this.state.readingList.map((item, index) => {
                                                let readingStatusClass = "unread";
                                                let meterStatusClass = "text-success";

                                                if (item.meter_status === "Manual") {
                                                    readingStatusClass = "manual";
                                                }
                                                else if (item.meter_status === "Auto+Alarm") {
                                                    readingStatusClass = "alarm";
                                                }
                                                else if (item.meter_status === "Auto Read") {
                                                    readingStatusClass = "read";
                                                }

                                                return (
                                                    {
                                                        meter_serial_no: item.meter_serial_no,
                                                        route_name1: <Tooltip placement="bottom-start" title={<span style={{ fontSize: "12px", color: "white" }}>{this.state.routeName} </span>}><span>{this.state.routeName}</span></Tooltip>,
                                                        route_name: this.state.routeName,
                                                        sird_device_id: item.sird_device_id,
                                                        assigned_user_id: item.assigned_user_id,
                                                        unit: <span style={{ textTransform: 'none' }}>{item.unit !== null ? (item.unit !== "NA" ? item.unit : "m3") : "m3"}</span>,
                                                        status: <span className={`${meterStatusClass}`}>{this.renderStatus(item.meter_serial_no)}</span>,
                                                        meter_status: <span className={`${readingStatusClass}`}>{item.meter_status}</span>,
                                                        alarm_type1: <Tooltip placement="bottom-start" title={<span style={{ fontSize: "12px", color: "white" }}>{item.alarm_type ? item.alarm_type : "None"} </span>}><span>{item.alarm_type ? item.alarm_type : "None"}</span></Tooltip>,
                                                        alarm_type: item.alarm_type ? item.alarm_type : "None",
                                                        receive_date_time: <Tooltip placement="bottom-start" title={<span style={{ fontSize: "12px", color: "white" }}>{item.receive_date_time} </span>}><span>{item.receive_date_time}</span></Tooltip>,
                                                        meter_reading: item.meter_reading,
                                                        _id: item._id,
                                                        photo:<i className={ (item.reading_image !== "" && item.reading_image !== undefined )? `${CAMERA_ICON} camera-icon` : `${CAMERA_ICON} gray-icon`} title="View Photo" onClick={ () => this.viewImage(item.reading_image)}></i>
                                                    }
                                                )
                                            })
                                        }
                                        options={{
                                            actionsColumnIndex: -1,
                                            pageSize:25,
                                            pageSizeOptions:[25,50,75,100],
                                            headerStyle: {
                                                color: '#999998',
                                                fontWeight: 'bolder',
                                                fontSize: 15
                                            },
                                            filtering: true,
                                            sorting: true,
                                            exportButton: true,
                                            exportAllData: true, 
                                            exportFileName: "Meter Reading Data"
                                        }}
                                    />
                                )
                            ):(
                                <div className="text-center pt-2"> No Records Found!</div>
                            )
                        }
                        {
                            this.state.viewImage && (
                                <>
                                    <div className="custom_modal">
                                        <div className="modal_body">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h4 className="modal_title pagetitle">View Meter Image</h4>
                                                </div>
                                                <div className="col-md-2">
                                                    <button type="button" className="close text-right" style={{fontSize:20}} onClick={(e) => this.setState({ viewImage: false })}>&times;</button>
                                                </div>
                                                {
                                                    (this.state.imgSrc.includes("png") || this.state.imgSrc.includes("jpg") || this.state.imgSrc.includes("jpeg"))  ? (
                                                        <embed src={this.state.imgSrc} title="photo" width="100%" height= "auto" />
                                                    ):(
                                                        <embed style={{height:'400px'}} src={this.state.imgSrc} title="photo" width="100%" height= "auto" />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}

export default MeterReadingPage