import React, { Component } from 'react'

export class CustomerListTable extends Component {

    render() {
        return (
            <React.Fragment>
                <table className="table list-table" id="table-to-xls" >
                    <thead>
                        <tr>
                            <th className="pl1">Customer Name</th>
                            <th>Consumer No</th>
                            <th>Assigned Meter</th>
                            <th>Address 1</th>
                            <th>Address 2</th>
                            <th>Street</th>
                            <th>City</th>
                            <th>Pincode</th>
                            <th>Moblie</th>
                        </tr>
                    </thead>
                    <tbody className="customer-list-table">
                    {
                        this.props.customerList.map((item) => {
                            return (
                                <tr key={ item._id }>
                                    <td className="customer-name-td pl1"> { item.customer_name } </td>
                                    <td className="customer-consumer-td"> { item.consumer_no != null ? item.consumer_no : "none"} </td>
                                    <td className="customer-serial-td"> { item.serial_number } </td>
                                    <td className="customer-address1-td"> { item.customer_address1 } </td>
                                    <td className="customer-address2-td"> { item.customer_address2 } </td>
                                    <td className="customer-street-td"> { item.street} </td>
                                    <td className="customer-city-td"> { item.city} </td>
                                    <td className="customer-pincode-td"> { item.pin_code } </td>
                                    <td className="customer-mobile-td"> { item.mobile_no} </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default CustomerListTable
