import axios from 'axios';
import { API_BASE_URL, READER } from 'assets/constants/Constants';

export const getRouteList = async (userId, siteId, pageSize, currentPage, userRole) => {
    if(userRole === READER ){
        return await axios(`${API_BASE_URL}/route_assignement/routeassign/site/${siteId}/user/${userId}?limit=${pageSize}&page=${currentPage}`);
    }
    else{
        return await axios(`${API_BASE_URL}/routes/site/${siteId}?limit=${pageSize}&page=${currentPage}`);
    }
}