import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, PHONE_NUMBER_PATTERN, PIN_CODE_PATTERN, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { CUSTOMER_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import { hasPermission } from 'components/Permission';
import ShowToast from 'components/ShowToast';

export class CreateCustomerPage extends Component {
    constructor(props) {
        super(props) 
    
        const editData = this.props.history.location.state ? this.props.history.location.state : null ;
        this.state = { 
            isLoading: true,
            isConsumerNoEnterFirstTime:false,
            customerId: editData === null ? null : editData._id,
            meterId: editData === null ? "" : editData.meter_id,
            siteId: editData === null ? sessionStorage.getItem("siteId")  : editData.site_id,
            serialNumber: editData === null ? "" : editData.serial_number,
            customerName: editData === null ? "" : editData.customer_name,
            customerAddress1: editData === null ? "" : editData.customer_address1,
            customerAddress2: editData === null ? "" : editData.customer_address2,
            customerStreet: editData === null ? "" : editData.street,
            customerCity: editData === null ? "" : editData.city,
            customerPincode: editData === null ? "" : editData.pin_code,
            customerMobileNo: editData === null ? "" : editData.mobile_no,
            consumerNumber: editData === null ? "" : editData.consumer_no,
            isConsumerNoDisable: (editData === null || editData.consumer_no === '' || editData.consumer_no === 'none' ) ? false : true,
            custMeter: {},
            meterList: [],
            siteList: [],
            error: {},
            editData : editData
        }
    }
    
    async componentDidMount() {
        try {
            const meterData = await axios(`${API_BASE_URL}/customer/meterserial/notin/${sessionStorage.getItem("siteId")}`);
            let custMeter = {
                data:{
                    result: []
                }
            };
            if(this.state.meterId){
                custMeter = await axios(`${API_BASE_URL}/meter/${this.state.meterId}`);
            } 
            this.setState({
                meterList: meterData.data.result,
                custMeter: custMeter.data.result,
                isLoading: false,
            });
        } catch (error) {
            console.log("Error", error);
        }
       
    }

    renderMeterListOption = () => {
        if(this.state.meterList.length >= 0){
            let meterList = this.state.meterList.map((meterItem, index) => {
                let isSelected = false;
                if(meterItem._id === this.state.meterId){
                    isSelected = true;
                }
                return(
                    <option value={ meterItem._id } selected = { isSelected } key= { meterItem._id} className = "option-text-color"> { meterItem.serial_number} </option>
                )
            });
            if(this.state.customerId){
                if(this.state.custMeter){
                    meterList.push(<option value={this.state.custMeter._id} selected = {true}>{this.state.custMeter.serial_number}</option>);
                }
            }
            return meterList;
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    meterIdHandler = (event) => { 
        let index = event.nativeEvent.target.selectedIndex;
        this.setState({
            meterId: event.target.value,
            serialNumber: event.nativeEvent.target[index].text
        });
    }

    customerNameHandler = (event) => {
        this.setState({
            customerName: event.target.value,
        });
    }

    customerAddress1Handler = (event) => {
        this.setState({
            customerAddress1: event.target.value,
        });
    }

    customerAddress2Handler = (event) => {
        this.setState({
            customerAddress2: event.target.value,
        });
    }

    customerStreetHandler = (event) => {
        this.setState({
            customerStreet: event.target.value,
        });
    }

    customerCityHandler = (event) => {
        this.setState({
            customerCity: event.target.value,
        });
    }

    customerPincodeHandler = (event) => {
        this.setState({
            customerPincode: event.target.value,
        });
    }

    customerMobileHandler = (event) => {
        this.setState({
            customerMobileNo: event.target.value,
        });
    }

    consumerNumberHandler = (event) => {
        this.setState({
            consumerNumber: event.target.value,
            isConsumerNoEnterFirstTime:true,
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        
        if(!this.state.meterId){
            isValid = false;
            error.meterId = "Please select meter";
        }

        if(!this.state.customerName){
            isValid = false;
            error.customerName = "Please enter customer name";
        }

        if(!this.state.consumerNumber){
            isValid = false;
            error.consumerNumber = "Please enter consumer number";
        }

        if(!this.state.customerAddress1){
            isValid = false;
            error.customerAddress1 = "Please enter address 1";
        }

        if(!this.state.customerAddress2){
            isValid = false;
            error.customerAddress2 = "Please enter address 2";
        }

        if(!this.state.customerStreet){
            isValid = false;
            error.customerStreet= "Please enter street";
        }

        if(!this.state.customerCity){
            isValid = false;
            error.customerCity= "Please enter city";
        }

        if(this.state.customerPincode !== "undefined"){
            if(!this.state.customerPincode){
                isValid = false;
                error.customerPincode= "Please enter Pincode";
            }
            else if (!PIN_CODE_PATTERN.test(this.state.customerPincode)) {
                isValid = false;
                error.customerPincode = "Please enter valid Pincode";
            }    
        }
        
        if(this.state.customerMobileNo !== undefined){
            if(!this.state.customerMobileNo){
                isValid = false;
                error.customerMobileNo= "Please enter mobile no";
            }
            else if (!PHONE_NUMBER_PATTERN.test(this.state.customerMobileNo)) {
                isValid = false;
                error.customerMobileNo = "Please enter valid mobile no..";
            }
        }

        this.setState({
            error
        });

        return isValid;
    }

    createCustomerHandler = async (event) => {
        let newMeterAssigned = false;
        event.preventDefault();
        if(this.isFormValid()){
            if(this.state.editData!== null && this.state.editData.meter_id !== this.state.meterId){
                newMeterAssigned = true;
            }
            this.setState({isLoading: true,isConsumerNoDisable: false});
            let customerResponse = null;
            
                const userDetails = JSON.parse(sessionStorage.getItem("userData"));
                const requestData = {
                    meterId: this.state.meterId,
                    siteId: this.state.siteId,
                    serialNumber: this.state.serialNumber,
                    customerName: this.state.customerName,
                    customerAddress1: this.state.customerAddress1,
                    customerAddress2: this.state.customerAddress2,
                    customerStreet: this.state.customerStreet,
                    customerCity: this.state.customerCity,
                    customerPincode: this.state.customerPincode,
                    customerMobileNo: this.state.customerMobileNo,
                    consumerNumber: this.state.consumerNumber,
                    isConsumerNoEnterFirstTime:this.state.isConsumerNoEnterFirstTime,
                    userFirstName:userDetails.first_name,
                    userLastName:userDetails.last_name,
                    userEmailId:userDetails.email_id,
                    userName:userDetails.username,
                    userId:userDetails._id,
                    reason:this.state.customerId === null ? "New customer added and meter assigned to customer" : (newMeterAssigned ? "New Meter assigned to customer": "Customer data updated"),
                    source: "Web"
                }
                try {
                    if(this.state.customerId === null){
                        customerResponse = await axios.post(`${API_BASE_URL}/customer`, requestData);
                    }
                    else{
                        customerResponse = await axios.put(`${API_BASE_URL}/customer/${this.state.customerId}`, requestData);
                    }
                    if(customerResponse.status === 200 && customerResponse.data.success){
                        this.setState({
                            meterId: "",
                            customerName: "",
                            consumerNumber: "",
                            customerAddress1: "",
                            customerAddress2: "",
                            customerStreet: "",
                            customerCity: "",
                            customerPincode: "",
                            customerMobileNo: "",
                        }, () => {
                            if(this.state.customerId === null){
                                ShowToast('Customer added successfully', TYPE_SUCCESS, INTERVAL, this.props, "/customer-page"); 
                            }
                            else{ 
                                ShowToast('Customer updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/customer-page");
                            }
                        this.props.history.push("/customer-page");
                    });
                    this.setState({
                        isLoading: false
                    });
                }
                else if(customerResponse.status === 200 && !customerResponse.data.success){
                    this.setState({
                        isLoading: false
                    }, () => {
                        ShowToast(customerResponse.data.message, TYPE_FAIL, INTERVAL, this.props, ""); 
                    })
                }
            } catch (error) {
                console.log("error", error.message)
            }
        }
    }

    cancelHandler = (event) => {
        this.props.history.push("/customer-page");
    }

    unAssinedMeterHandler = async (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure you want to change the meter serial number?")) {
            const userDetails = JSON.parse(sessionStorage.getItem("userData"));
            const requestData = {
                meterId: this.state.meterId,
                siteId: this.state.siteId,
                serialNumber: this.state.serialNumber,
                userFirstName:userDetails.first_name,
                userLastName:userDetails.last_name,
                userEmailId:userDetails.email_id,
                userName:userDetails.username,
                userId:userDetails._id,
                reason:"Meter unassigned from customer",
                source: "Web"
            }
            const customerResponse = await axios.post(`${API_BASE_URL}/customer_meter_map/customer/delete/${this.state.customerId}`, requestData);
            if(customerResponse.status === 200 && customerResponse.data.success){
                
                ShowToast('Meter unassigned successfully', TYPE_SUCCESS, INTERVAL, this.props, "/customer-page"); 
            }
            else{
                ShowToast(customerResponse.data.message, TYPE_FAIL, INTERVAL, this.props, ""); 
            }
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/customer-page">Customer Master</a></li>
                            {
                                this.state.customerId === null ? (
                                    <li>Add New Customer</li>
                                ) : (
                                    <li>Edit Customer</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-meter-main-view">
                        {
                            hasPermission("customer", "create") ? (
                                <div className="mx-auto text-left border create-meter-div">
                                    <form className="pt-1">
                                        <div className="meter-icon-wrapper">
                                            <h4>{ this.state.customerId === null ? "Add New Customer" : "Edit Customer"}</h4>
                                            <i className={ `${CUSTOMER_ICON} list-table-icon` }></i>
                                        </div>
                                        <div className="input-label-group w-100">
                                            <label className="form-label">Customer Name <span className="text-danger">*</span></label>
                                            <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.customerName } onChange={ this.customerNameHandler } placeholder="Customer Name" />
                                            <div className="text-danger error-msg">
                                                {this.state.error.customerName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Consumer Number <span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.consumerNumber } onChange={ this.consumerNumberHandler } placeholder="Enter Consumer Number" disabled = { this.state.isConsumerNoDisable } />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.consumerNumber}
                                                </div>
                                            </div>
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Assigned Meter <span className="text-danger">*</span></label>
                                                <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={ this.meterIdHandler } placeholder="Select Meter " >
                                                    <option selected value="0" disabled >Select Meter </option>
                                                    {
                                                        this.renderMeterListOption()
                                                    }
                                                </select>
                                                {
                                                    this.state.customerId && (
                                                        <div>
                                                            <label className="form-label underline-italic" onClick={this.unAssinedMeterHandler}>Unassigned Meter</label>
                                                        </div>
                                                    )
                                                }
                                                <div className="text-danger error-msg">
                                                    {this.state.error.meterId}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Address 1<span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.customerAddress1} onChange={ this.customerAddress1Handler } placeholder="Enter Customer Address 1" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.customerAddress1}
                                                </div>
                                            </div>
                                            <div className="input-label-group col-md-6">
                                            <label className="form-label">Address 2<span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.customerAddress2 } onChange={ this.customerAddress2Handler} placeholder="Enter Customer Address 2" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.customerAddress2}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Street<span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.customerStreet } onChange={ this.customerStreetHandler } placeholder="Enter Customer Street" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.customerStreet}
                                                </div>
                                            </div>
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label"> City<span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.customerCity} onChange={ this.customerCityHandler } placeholder="Enter Customer City" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.customerCity}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Customer Pincode <span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value = {this.state.customerPincode} onChange = {this.customerPincodeHandler} placeholder="Enter Customer Pincode" maxLength = "6" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.customerPincode}
                                                </div>
                                            </div>
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Customer Mobile Number<span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.customerMobileNo } onChange={ this.customerMobileHandler } placeholder="Enter Mobile Number" maxLength = "10" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.customerMobileNo}
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="row mt-4 mb-5">
                                            <div className="col-md-6">
                                                <button className="brand-button" onClick={this.createCustomerHandler}>Submit</button>
                                                <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            ):(
                                <div  className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="text-center pt-2">
                                    <label>You dont have permission to see list</label>
                                </div>
                                </div>
                            )
                        }
                    </div> 
                </div>
            )
        }
    }
} 

export default CreateCustomerPage
