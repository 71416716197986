import moment from "moment";
import Toast from 'light-toast';

export const onSortColumn = (dataArray, column, orderFlag) => {
    let sortedData = [];
    if (orderFlag === "asc") {
        sortedData = dataArray.sort((a, b) => {
            if (a[column] < b[column]) return -1;
            if (a[column] > b[column]) return 1;
            if (a[column] === b[column]) return 1;
            return 1;
        });
    } else {
        sortedData = dataArray.sort((a, b) => {
            if (a[column] > b[column]) return -1;
            if (a[column] < b[column]) return 1;
            if (a[column] === b[column]) return 1;
            return 1;
        });
    }
    return sortedData;
}

export const defaultFormatDate = (item) => {
    let formatedDate = null;
    formatedDate = moment(item).format("DD-MM-YY"); 
    return formatedDate;
}
export const defaultFormatDateTime = (item) => {
    let formatedDate = null;
    formatedDate = moment(item).format("DD/MM/YY hh:mm A"); 
    return formatedDate;
}

export const showLoading = (message = "Please wait...") => {
    Toast.loading(message, () => {});
}

export const hideLoading = () => {
    Toast.hide();
}