import React, { Component } from 'react'
import RoutePage from 'views/routes/RoutePage';
import Spinner from 'components/Spinner';
import { PAGE_SIZE } from 'assets/constants/Constants';
import AuthContext from "context/AuthProvider";
import {getRouteList} from "views/routes/Models/GetRoutes";
import { hasPermission }  from "components/Permission";

export class MasterRoutePage extends Component {
    constructor(props) {
        super(props)

        const userRoleDetails = sessionStorage.getItem("userRole");
        this.state = {
            isLoading: true,
            buttonText: "Add Route",
            routeList: [],
            selectedItem: null,
            currentPage: 1,
            totalCount: 1,
            pageSize: PAGE_SIZE,
            userRoleDetails: userRoleDetails ? JSON.parse(userRoleDetails) : null,
            userSessionData: null
        }
    }
    async componentDidMount() {
        const userSessionData = await this.context.getUserDetails();
        const routeData = await getRouteList (userSessionData._id,sessionStorage.getItem("siteId"), this.state.pageSize, this.state.currentPage, this.state.userRoleDetails.user_role );
       
        this.setState({
            userSessionData,
            routeList: routeData.data.result,
            totalCount: routeData.data.totalCount,
            isLoading: false,
        });
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner/>
            )
        }
        else{
            return (
                <div className="main-content">
                    {
                        hasPermission("route", "update") ? (
                            <RoutePage routeList = {this.state.routeList} totalPageCount = {this.state.totalCount} model = "masterRoute" {...this.props}/>
                        ):(
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div text-center pt-2">
                                <label>You dont have permission to see list</label>
                            </div>
                        )
                    }
                </div>
            )
        }
    }
}
MasterRoutePage.contextType = AuthContext;
export default MasterRoutePage
