import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL } from "assets/constants/Constants";
import { REFRESH_ICON } from "assets/constants/Icons";
import Spinner from "components/Spinner";
import MaterialTable from "material-table";

export class AMI_AMR_MeterReadingPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: true,
            meterReadings: [],
            liveSyncIconClass: "enable-icon",
            toggleLiveSyncIconClass: true
        }

        this.liveSyncIconClassToggleInterval = null;
        this.meterReadingsGetInterval = null;
    }

    arrangeReadingList = (array) => {
        const meterListArray = [...this.state.meterReadings];
        if(Array.isArray(array)){
            array.forEach((item) => {
                const index = meterListArray.findIndex(findeItem => findeItem.meter_serial_no === item.meter_serial_no);
                if(index === -1){
                    meterListArray.push(item)
                }
                else{
                    meterListArray[index].meter_reading = item.meter_reading;
                    meterListArray[index].meter_reading_status = item.meter_reading_status;
                    meterListArray[index].receive_date_time = item.receive_date_time;
                    meterListArray[index].alarm_type = item.alarm_type;
                    meterListArray[index].created_date_time = item.created_date_time;
                    meterListArray[index].updated_date_time = item.updated_date_time;
                }
            });
            return meterListArray;
        }
        else{
            return array;
        }
    }

    async componentDidMount() {
        const meterReadings = await this.getMeterReadings();
        const updatedList = this.arrangeReadingList(meterReadings.data.result);
        this.liveSyncIconClassToggleInterval = setInterval( () => {
            this.setState({
                toggleLiveSyncIconClass: !this.state.toggleLiveSyncIconClass,
                liveSyncIconClass: this.state.toggleLiveSyncIconClass ? "enable-icon" : "disable-icon"
            });
        }, 1000);

        this.meterReadingsGetInterval = setInterval( async () => {
            const meterReadings = await this.getMeterReadings();
            const updatedList = this.arrangeReadingList(meterReadings.data.result);
            this.setState({
                meterReadings: updatedList
            });
        }, 5000);

        this.setState({
            meterReadings: updatedList,
            isLoading: false
        });
    }

    componentWillUnmount() {
        clearInterval(this.liveSyncIconClassToggleInterval);
        clearInterval(this.meterReadingsGetInterval);
    }

    getMeterReadings = async () => {
        return await axios.get(`${API_BASE_URL}/readings/AMI-AMR/${sessionStorage.getItem('siteId')}`);
    }

    showMeterReadingHandler = (item) => {
        if(item){
            const rowItem = {
                serial_number:item.meter_serial_no,
                ...item
            }
            this.props.history.push({
                pathname: "/meter-reading",
                state: {rowItem, pageName:"AMI Readings", pathName: "ami-amr-meter-reading-page"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/meter-reading",
                state: null
            });
        }
    }

    render () {
        if (this.state.isLoading) {
            return (
                <Spinner />
            );
        }
        else {
            return (
                <div className="main-content">
                    <div className="live-sync-icon-and-text-container">
                        <i className={ `${REFRESH_ICON} ${this.state.liveSyncIconClass} rotate`}></i>
                        <label className={`${this.state.liveSyncIconClass}`}>Syncing...</label>
                    </div>
                    
                    <div className="border list-table-div" style={{marginBottom:50}}>
                        {(this.state.meterReadings.length > 0) ? (
                            <MaterialTable
                                columns={[
                                    { title: "Serial Number", field: "meter_serial_no", cellStyle:{color:"#000000", fontSize: 14} },
                                    { title: "Date", field: "receive_date_time", cellStyle:{color:"#808080", fontSize: 14} },
                                    { title: "Reading", field: "meter_reading", cellStyle:{color:"#808080", fontSize: 14} },
                                    { title: "Meter Status", field: "meter_status", cellStyle:{color:"#808080", fontSize: 14} },
                                    { title: "Receiver", field: "receiver", cellStyle: (e, rowData) => {
                                        if (rowData.receiver === "AMI") {
                                            return { color: "blue", fontSize: 14, fontWeight: "bold"}
                                        }
                                        else {
                                            return { color: "green", fontSize: 14, fontWeight: "bold"}
                                        }
                                    } },
                                    { title: "Gateway Device ID", field: "gway_device_id", cellStyle:{color:"#808080", fontSize: 14} },
                                    { title: "Field Strength", field: "field_strengthG", cellStyle:{color:"#808080", fontSize: 14} },
                                    { title: "Alarm Type", field: "alarm_type", cellStyle:{color:"#808080", fontSize: 14} }
                                ]}
                                data={
                                    this.state.meterReadings.map( (element, index) => {
                                        return {
                                            meter_serial_no: element.meter_serial_no,
                                            receive_date_time: element.receive_date_time,
                                            meter_reading: element.meter_reading,
                                            meter_status: element.meter_status,
                                            receiver : element.original_receiver !== "AMI" ? "AMR" : element.original_receiver,
                                            gway_device_id: element.gway_device_id,
                                            field_strengthG: element.field_strengthG,
                                            alarm_type: element.alarm_type,
                                        };
                                    })
                                }
                                onRowClick = {(event, rowData) => {
                                    this.showMeterReadingHandler(rowData)
                                }}
                                options={{
                                    headerStyle: {
                                        color: "#999998",
                                        fontWeight: "bold",
                                        fontSize: 15
                                    },
                                    columnsButton: true,
                                    pageSize:25,
                                    pageSizeOptions:[25,50,75,100],
                                    exportButton: true,
                                    exportAllData: true, 
                                    exportFileName: "Reading Data"
                                }}
                            />
                        ) : (
                            <div className="text-center pt-2">
                                <label>Records not found!</label>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }
}

export default AMI_AMR_MeterReadingPage;