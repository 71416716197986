import React, { Component } from 'react'
import { CHECK_ICON, CLOSE_ICON } from "assets/constants/Icons";

export class MeterListTable extends Component {

    renderAssignedStatus = (item) => {
        let assignStatus = <i className={`${CHECK_ICON} iconGreenColor`} aria-hidden="true"></i>;
        if (this.props.meterAssignList.length > 0) {
            this.props.meterAssignList.forEach((meterItem) => {
                if (meterItem.serial_number === item) {
                    assignStatus = <i className={`${CLOSE_ICON} iconRedColor`} aria-hidden="true"></i>
                }
            });
            return assignStatus
        }
        else {
            return assignStatus
        }
    }

    render() {
        return (
            <React.Fragment>
                <table className="table list-table" id="table-to-xls">
                    <thead>
                        <tr>
                            <th className="pl1">Serial Number</th>
                            <th>Meter Type</th>
                            <th>Unit</th>
                            <th>Meter AMR Channel</th>
                            <th>Meter Status</th>
                            <th>Consumer Number</th>
                            <th>Assigned</th>
                        </tr>
                    </thead>
                    <tbody className="reading-list-table">
                        {
                            this.props.meterList.map((readingItem) => {
                                let meterStatusClass = "text-success";
                                if (readingItem.status === "active") {
                                    meterStatusClass = "text-success";
                                }
                                else if (readingItem.status === "faulty") {
                                    meterStatusClass = "text-warning";
                                }
                                else if (readingItem.status === "Damage") {
                                    meterStatusClass = "text-danger";
                                }
                                else {
                                    meterStatusClass = "text-warning";
                                }
                                return (
                                    <tr key={readingItem._id}>
                                        <td className="serial-no-td pl1" > {readingItem.serial_number} </td>
                                        <td className="meter-type-td" > {readingItem.meter_type} </td>
                                        <td className="meter-unit-td" > {readingItem.unit !== null ? (readingItem.unit !== "NA" ? readingItem.unit : "m3") : "m3"} </td>
                                        <td className="meter-amr-td" > {readingItem.amr_channel} </td>
                                        <td className="meter-status-td"> <mark className={`${meterStatusClass}`}>{readingItem.status}</mark> </td>
                                        <td className="consumer-no-td" > {readingItem.consumer_number != null ? readingItem.consumer_number : "none"} </td>
                                        <td className="meter-assign-td" > {this.renderAssignedStatus(readingItem.serial_number)}  </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default MeterListTable