import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, PIN_CODE_PATTERN, TYPE_SUCCESS, INTERVAL, WEB_APP_URL } from "assets/constants/Constants";
import { SITE_ICON, COPY_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class CreateSitePage extends Component {
    constructor(props) { 
       super(props) 

        const editData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            siteId: editData === null ? null : editData._id,
            siteName : editData === null ? "" : editData.site_name,
            siteStreet : editData === null ? "" : editData.street,
            siteCity : editData === null ? "" : editData.city,
            sitePincode : editData === null ? "" : editData.pincode,
            backendURL:editData === null ? "" : editData.backend_url,
            copiedIconClass: "",
            error : {},
        }
    }

    async componentDidMount() {
        const siteData = await axios(`${API_BASE_URL}/site`);
        this.setState({
            siteList: siteData.data.result,
            isLoading: false,
        });
    }

    siteNameHandler = (event) => {
        this.setState({
           siteName : event.target.value
        });
    }
   
   siteStreetHandler = (event) => {
        this.setState({
            siteStreet : event.target.value 
        });
   }

    siteCityHandler = (event) => {
        this.setState({
            siteCity : event.target.value
        });
    }
    
    sitePincodeHandler = (event) => {
        this.setState({
            sitePincode : event.target.value
        });
    }
   
    backendURLHandler = (event) => {
        this.setState({
            backendURL: event.target.value
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if (!this.state.siteName) {
            isValid = false;
            error.siteName = "Please enter site name";
        }
        if (!this.state.siteStreet) {
            isValid = false;
            error.siteStreet = "Please enter site street";
        }
        if (!this.state.siteCity) {
            isValid = false;
            error.siteCity = "Please enter site city";
        }
        if (!this.state.sitePincode) {
            isValid = false;
            error.sitePincode = "Please enter site pincode";
        }
        if(this.state.sitePincode !== "undefined"){
            if (!PIN_CODE_PATTERN.test(this.state.sitePincode)) {
                isValid = false;
                error.sitePincode = "Please enter valid Pincode";
            }
        }
        this.setState({
            error
        });
        return isValid;
    }

    addSiteHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()){
            this.setState ({isLoading: true,});
            const requestParameter = {
                siteName : this.state.siteName,
                siteStreet : this.state.siteStreet,
                siteCity : this.state.siteCity,
                sitePincode : this.state.sitePincode,
                backendURL:this.state.backendURL
            }
            let siteResponse = null;
            if (this.state.siteId === null){
                siteResponse = await axios.post(`${API_BASE_URL}/site`, requestParameter);
            }
            else{
                siteResponse = await axios.put(`${API_BASE_URL}/site/${this.state.siteId}`, requestParameter);
            }
            const tempSiteId = this.state.siteId;
            if(siteResponse.status === 200){
                this.setState({
                    siteId: null ,
                    siteName: "",
                    siteList: "",
                    siteCity: "",
                    sitePincode: "",
                    backendURL:""
                }, () => {
                    if(tempSiteId === null){
                        ShowToast('Site created successfully', TYPE_SUCCESS, INTERVAL, this.props, "/sites-page"); 
                    } 
                    else{
                        ShowToast('Site updated successfully', TYPE_SUCCESS, INTERVAL, this.props, "/sites-page"); 
                    }
                    this.props.history.push("/sites-page");
                });
            }    
        }
    }

    cancelHandler = (event) => {
        this.props.history.push("/sites-page");
    }

    copyHandler = (copiedText, isSuccessful) => {
        if (isSuccessful) {
            console.log(`Copied: ${copiedText}`);
            this.setState({ copiedIconClass: 'copied-text' });

            setTimeout(() => {
                this.setState({ copiedIconClass: " " });
            }, 3500);
            ShowToast('Text copied!', TYPE_SUCCESS, INTERVAL, this.props, "/create-site-page");
        } else {
            console.error('Copy failed');
        }
    };

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/sites-page">Site Master</a></li>
                            {
                                this.state.siteId === null ? (
                                    <li>Add New Site</li>
                                ) : (
                                    <li>Edit Site</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-site-main-view"> 
                        <div className="mx-auto text-left border create-site-div">
                            <form className="pt-1">
                                <div className="site-icon-wrapper">
                                    <h4>{this.state.siteId ? "Edit Site" : "Add New Site"}</h4>
                                    <i className={ `${SITE_ICON} list-table-icon` }></i>
                                </div>
                                <div className="input-label-group w-100">
                                    <label className="form-label">Site Name <span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" value = {this.state.siteName} onChange = {this.siteNameHandler} placeholder="Enter Site Name" />
                                    <div className="text-danger error-msg">
                                        {this.state.error.siteName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">Site Street <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value = {this.state.siteStreet} onChange = {this.siteStreetHandler} placeholder="Enter Site Street" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.siteStreet}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">Site City<span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value = {this.state.siteCity} onChange = {this.siteCityHandler} placeholder="Enter Site City" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.siteCity}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">Site Pincode <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value = {this.state.sitePincode} onChange = {this.sitePincodeHandler} placeholder="Enter Site Pincode" maxLength = "6"/>
                                        <div className="text-danger error-msg">
                                            {this.state.error.sitePincode}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Backend URL</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input font-style" value = {this.state.backendURL} onChange = {this.backendURLHandler} placeholder="Enter backend URL" />
                                    </div>
                                    {
                                        this.state.siteId && (
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Site Login URL </label>
                                                <div className='site-url-div'>
                                                    <CopyToClipboard text={`${WEB_APP_URL}/login/${this.state.siteId}`} onCopy={this.copyHandler}>
                                                        <label className="form-label brand-font p-3 placeholderColor add-meter-input text-transform-none pl-0">
                                                            {`${WEB_APP_URL}/login/${this.state.siteId}`}
                                                            <br />
                                                            <i className={`${COPY_ICON} list-table-icon copy-icon ${this.state.copiedIconClass}`}></i>
                                                        </label>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick = {this.addSiteHandler} >Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div>
            )
        }
    }    
}

export default CreateSitePage