import React, { Component } from 'react'
import { CSVReader } from 'react-papaparse'

const buttonRef = React.createRef()

export default class CSVFileReader extends Component {
    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = (data) => {
        this.props.handleFile(data);
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }

    handleOnRemoveFile = (data) => {
        console.log(data);
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    render() {
        return (
            <>
                <CSVReader
                    ref={buttonRef}
                    onFileLoad={this.handleOnFileLoad}
                    onError={this.handleOnError}
                    noClick
                    noDrag
                    onRemoveFile={this.handleOnRemoveFile}
                >
                    {({ file }) => (
                        <aside
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px"
                            }}
                        >
                            <button
                                type='button'
                                onClick={this.handleOpenDialog}
                                style={{
                                    width: "150px",
                                    padding: "10px",
                                    border: "none",
                                    borderRadius: "5px", 
                                    backgroundColor:  "#2674c8",
                                    fontSize: "16px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    marginTop:"10px",
                                    marginLeft:"10px"
                                }}
                               
                            >
                                IMPORT CSV
                            </button>
                        </aside>
                    )}
                </CSVReader>
            </>
        )
    }
}