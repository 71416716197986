import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL } from "assets/constants/Constants";
import Spinner from 'components/Spinner';
import moment from "moment";
import { hasPermission }  from "components/Permission";
import MaterialTable from "material-table";

export class MeterHistoryLog extends Component {
    constructor() {
        super();

        const userDetail = JSON.parse(sessionStorage.getItem("userData"));
        this.state = {
            isLoading: true,
            historyList: [],
            userDetail:userDetail
        }
    }

    async componentDidMount() {
        const historyResult = await this.getHistory();
        this.setState({
            isLoading: false,
            historyList: historyResult.data.result.errors.length > 0 ? [] : historyResult.data.result.result
        })
    }

    getHistory = async () => {
        if(this.state.userDetail.user_role === "super admin"){
            return await axios(`${API_BASE_URL}/meter/history/log`);
        }
        else{
            return await axios(`${API_BASE_URL}/meter/history/log/${this.state.userDetail._id}`);
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className='main-content'>
                    <div className="list-wrapper">
                        <div className="back">
                            <ul className="breadcrumb1">
                                <li>Meter History Log</li>
                            </ul>
                        </div>
                        <div className="mt-4 border list-table-div" style={{marginBottom:50}}>
                            {
                                hasPermission("meter", "read") ? (
                                this.state.historyList.length > 0 ? (
                                    <MaterialTable
                                        columns={[
                                            { title: "Sr No", field: "index", 
                                            width: null, cellStyle: { width: "1%", fontWeight:'bolder', color:'#000000', fontSize:14 },
                                            },
                                            { title: "Serial No", field: "serialNumber", 
                                            width: null, cellStyle: { width: '10%', color:'#808080',fontSize:14 },
                                            sorting:false 
                                            },
                                            { title: "Username", field: "username", 
                                            width: null, cellStyle: { width: '5%', color:'#808080',fontSize:14 },
                                            sorting:false 
                                            },
                                            { title: "Name", field: "name", 
                                            cellStyle: {cellWidth: '8%', color:'#808080', fontSize:14 },
                                            sorting: false
                                            },
                                            { title: "Email", field: "email", 
                                            cellStyle: {cellWidth: '10%', color:'#808080', fontSize:14 },
                                            sorting: false
                                            },
                                            { title: "Meter Status", field: "meterStatus", 
                                            width: null, cellStyle: { width: '10%', color:'#808080', fontSize:14 },
                                            sorting: false
                                            },
                                            { title: "Meter Data Status", field: "meterDataStatus", 
                                            width: null, cellStyle: {width: '12%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Date & time", field: "date", 
                                            width: null, cellStyle: {width: '12%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Reason", field: "reason", 
                                            width: null, cellStyle: {width: '20%', color:'#808080', fontSize:14 },sorting: false 
                                            }
                                        ]}
                                        data={
                                            this.state.historyList.map((item, index) => {
                                                let meterStatusClass = "";
                                                if(item.meter_status){
                                                    if(item.meter_status.toLowerCase() === "active"){
                                                        meterStatusClass = "text-success";
                                                    }
                                                    else if(item.meter_status.toLowerCase() ===  "faulty"){
                                                        meterStatusClass = "text-danger-bg";
                                                    }
                                                    else if(item.meter_status.toLowerCase() ===  "inactive"){
                                                        meterStatusClass = "text-warning";
                                                    }
                                                }
                                                return (
                                                    {
                                                        index : index+1,
                                                        serialNumber :item.meter_serial_number,
                                                        username : item.user_name !== '' ? item.user_name : "-",
                                                        name : item.status_changed_by !== '' ? item.status_changed_by : "-",
                                                        email : item.user_email_id !== '' ? item.user_email_id : "-",
                                                        meterStatus: <span className={`${meterStatusClass}`}>{item.meter_status}</span>,
                                                        meterDataStatus : item.meter_data_status !== '' ?  item.meter_data_status : "-",
                                                        date : moment(item.created_date_time).format('DD-MM-YYYY hh:mm:ss a'),
                                                        reason : item.reason
                                                    }
                                                )
                                            })
                                        }
                                        options={{
                                            headerStyle: {
                                                color: '#999998',
                                                fontWeight: 'bolder',
                                                fontSize: 15
                                            },
                                            pageSize:25,
                                            pageSizeOptions:[25,50,75,100],
                                            filtering: true,
                                            sorting: true,
                                            columnsButton: true,
                                            exportButton: true,
                                            exportAllData: true, 
                                            exportFileName: "Logs"
                                        }}
                                    />
                                ):(
                                    <div className="text-center pt-2">
                                        <label>Records not found!</label>
                                    </div>
                                )
                                ):(
                                    <div className="text-center pt-2">
                                        <label>You dont have permission to see log</label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MeterHistoryLog