import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import { UNLINK_ICON, CALENDAR_ICON, LINK_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowToast from 'components/ShowToast';
import { defaultFormatDate } from "lib/utils"
import moment from "moment";

export class RouteAssignementPage extends Component {
    constructor(props) {
        super(props)
        const routeData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            siteId: routeData === null ? null : routeData.site_id,
            routeId: routeData === null ? null : routeData._id,
            routeStatus: routeData === null ? null : routeData.route_status,
            routeData,
            siteList: [],
            meterSiteData: {},
            userList: [],
            userDetail: [],
            roleList:[],
            error: {},
            routeAssignmentId: null,
            startDate: new Date(routeData.start_date)
        }
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const userData = await this.getAllUsers();
        const siteData = await this.getSiteList();
        const roleData = await this.getRoleList();
        const userDetails = await this.getUserDetail();
        const meterSite = siteData.data.result.filter((filterItem) => filterItem._id === this.state.routeData.site_id)[0];
        console.log("userDetails", userDetails);
        this.setState({ 
            userList: userData.data.result,
            siteList: siteData.data.result,
            roleList: roleData.data.result,
            userDetail: userDetails.data.result,
            routeAssignmentId: userDetails.data.result.length > 0 ? userDetails.data.result[0].route_assignement_id : null,
            meterSiteData: meterSite ? meterSite : {},
            isLoading: false
        });
    }
    
    getRoleList = async () => {
        return await axios(`${API_BASE_URL}/role/roledata/reader/site/${this.state.siteId}`);
    }

    getAllUsers = async () => {
        return await axios(`${API_BASE_URL}/user`);
    }

    getSiteList = async () => {
        return await axios(`${API_BASE_URL}/site`);
    }

    getUserDetail = async () => {
        return await axios(`${API_BASE_URL}/billing/routeassign/${this.state.routeId}`);
    }
    
    createReaderHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({isLoading: true,});
            const requestData = {
                routeId: this.state.routeId,
                siteId : this.state.siteId,
                userId: this.state.userId,
                routeComment: this.state.routeComment,
                routeStatus:this.state.routeStatus,
                routeSchedule: this.state.startDate,
                activeUser: true,
            }
            let routeResponse = null;
            routeResponse = await axios.post(`${API_BASE_URL}/route_assignement`, requestData);
            if(routeResponse.status === 200){
                const userDetails = await this.getUserDetail();
                this.setState({
                    userDetail: userDetails.data.result,
                    isLoading: false,
                    activeUser: true,
                });
                ShowToast('Reader assigned to the Route!', TYPE_SUCCESS, INTERVAL, this.props, "/route-transaction-page"); 
                this.setState({
                    routeComment:""
                });
            }
        }
    }

    userChangeHandler = (event) => {
        this.setState({
            userId: event.target.value,
        });
    }

    scheduleChangeHandler = (event) => {
        this.setState({
            routeSchedule: event.target.value,
        });
    }

    commentChangeHandler = (event) => {
        this.setState({
            routeComment: event.target.value,
        });
    }

    renderReaderOptions = () => {
        if(this.state.roleList.length > 0){
            const readerOptions =  this.state.roleList.map((userItem, index) => {
                const flag = !!this.state.userDetail.find(item => userItem._id === item._id);
                if(flag === false){
                    let isSelected = false;
                    if(userItem._id === this.state.userId){
                        isSelected = true;

                    }
                    return(
                        <option value={ userItem._id } selected = { isSelected } key= { userItem._id } className = "option-text-color"> { `${userItem.first_name} ${userItem.last_name}`} </option>
                    )
                }
                else{
                    return null;
                }
                
            });
            return readerOptions.filter(item => item);
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    isEditValid = (item) => {
        let isValid = true;
        if (moment().toDate() > moment(item.route_schedule).toDate()) {
            ShowToast('You could not perform this operation.', TYPE_FAIL, INTERVAL, this.props, ""); 
            isValid = false;
        }
        return isValid;
    }

    handleUpdate = async (item) => {
        if(this.isEditValid(item)){
            if (window.confirm("Are you sure you want to unassign this Reader?")) {
                const requestParameter = {
                    activeUser :false
                }
                let updateResponse = await axios.put(`${ API_BASE_URL }/route_assignement/${item.route_assignement_id}`, requestParameter);
                if(updateResponse.status === 200){
                    const userDetails = await this.getUserDetail();
                    this.setState({
                    userDetail: userDetails.data.result,
                    isLoading: false,
                });
                ShowToast('Reader unassigned successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                }
            }
        }
    }

    handleActive = async (item) => {
        if(this.isEditValid(item)){
            if (window.confirm("Are you sure you want to activate this Reader?")) {
                const requestParameter = {
                    activeUser :true
                }
                let updateResponse = await axios.put(`${ API_BASE_URL }/route_assignement/${item.route_assignement_id}`,requestParameter);
                if(updateResponse.status === 200){
                    const userDetails = await this.getUserDetail();
                    this.setState({
                    userDetail: userDetails.data.result,
                    isLoading: false,
                });
                ShowToast('Reader activated successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                }
            }
        }
    }

    renderSiteNames = (item) => {
        let siteNamesArray = [];
        this.state.siteList.forEach((siteItem) => {
            item.forEach((userSiteitem) => {
                if(siteItem._id === userSiteitem){
                    siteNamesArray.push(siteItem.site_name);
                }
            });
        });
        return siteNamesArray.join(", ");
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        if(this.state.userId === undefined){
            isValid = false;
            error.userId = "Please select reader";
        }
        if(this.state.userId !== undefined && this.state.userId === "0" && this.state.userId){
            isValid = false;
            error.userId = "Please select reader";
        }

        if(this.state.routeComment === undefined){
            isValid = false;
            error.routeComment = "Please enter comment";
        }
        
        this.setState({
            error
        });
        return isValid;
    }

    handleChange(date) {
        this.setState({
          startDate: date
        })
      }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="route-transaction-page">Route Transaction</a></li>
                            <li>Route Assignment</li>
                        </ul>
                    </div>
                    <div className="reading-info-outer">
                        <div className="reading-wrapper">
                            <div className="site-wrapper">
                                <div className="row">
                                    <div className="site-info">
                                        <div className="site-content">
                                            <label>Site Name: </label>
                                            <label className="ml-3">{this.state.meterSiteData.site_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Site Address: </label>
                                            <label className="ml-3">{ `${this.state.meterSiteData.street}, ${this.state.meterSiteData.city}, ${this.state.meterSiteData.pincode}` }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="customer-wrapper">
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label>Route Name: </label>
                                            <label className="ml-3">{ this.state.routeData.route_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Address: </label>
                                            <label className="ml-3">{ `${this.state.routeData.street}, ${this.state.routeData.city}, ${this.state.routeData.pin_code}` }</label>
                                            <br/>
                                            <label>Status: </label>
                                            <label className="ml-3">{this.state.routeData.route_status === true ? "Active" : "Inactive"}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                        {
                            this.state.userDetail.length > 0 ? (
                                <table className="table list-table">
                                    <thead>
                                        <tr>
                                            <th className="pl1">Assigned Reader</th>
                                            <th>Transaction ID</th>
                                            <th>Email Id</th>
                                            <th>Phone Number</th>
                                            <th>Username</th>
                                            <th>Site</th>
                                            <th>Assigned Date</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="reading-list-table">
                                    {
                                        this.state.userDetail.map((item) => {
                                            return (
                                                <tr key={ item._id }>
                                                    <td className="userFullName-td  pl1" > { `${item.first_name} ${item.last_name}` } </td>
                                                    <td className="transaction-td normalfont" > { item.transaction_id !== null ? item.transaction_id: "NA"  } </td>
                                                    <td className="userEmail-td normalfont" > { item.email_id } </td>
                                                    <td className="userPhone-td normalfont" > { item.phone_number } </td>
                                                    <td className="userName-td normalfont" > { item.username } </td>
                                                    <td className="userSite-td normalfont" > { this.renderSiteNames(item.site_id) } </td>
                                                    <td className="assigned-td normalfont" > { defaultFormatDate(item.route_schedule )} </td>
                                                    <td className="userSite-td normalfont" > { item.active_user !== true ? "Inactive" : "Active" } </td>
                                                    <td  className={"action-td center"}> 
                                                    <div className="action-div">
                                                        <span onClick={ () => this.handleActive(item)}><i className={`${ LINK_ICON } icon`} title="Active Reader"></i></span>
                                                        <span onClick={ () => this.handleUpdate(item)}><i className={`${ UNLINK_ICON } icon text-danger deleteaction`} title="Inactive Reader"></i></span>
                                                    </div>
                                                </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            ):(
                                <div className="text-center pt-2"> No Records Found!</div>
                            )
                        }
                    </div>
                    <div className="list-wrapper">
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                        <div className="row">
                            <div className="addUser">
                                <label className="form-label"> Reader  <span className="text-danger">*</span></label>
                                <select className="form-control brand-font p-3 placeholderColor add-meter-input" multiple= { false } onChange={this.userChangeHandler} placeholder="Select Reader" >
                                    <option value="0" >Select Reader</option>
                                    {
                                        this.renderReaderOptions()
                                    }
                                </select>
                                <div className="text-danger error-msg">
                                    {this.state.error.userId}
                                </div>
                            </div>
                            <div className="addUser">
                                <label className="form-label">Schedule <span className="text-danger">*</span></label>
                                <div className = "calender-div">
                                <span className={`${ CALENDAR_ICON }`} />
                                    <DatePicker className="form-control brand-font p-3" 
                                        selected={ this.state.startDate }
                                        onChange={ this.handleChange }
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="time"
                                        dateFormat="dd/MM/yyyy hh:mm aa"
                                        minDate={new Date(this.state.routeData.start_date)}
                                        maxDate={new Date(this.state.routeData.end_date)}  
                                    />
                                </div>
                                    <div className="text-danger error-msg">
                                        {this.state.error.routeSchedule}
                                    </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="addUser">
                                <label className="form-label">Comment <span className="text-danger">*</span></label>
                                <textarea className="form-control brand-font p-3 placeholderColor add-meter-input" rows="4" cols="50" value={ this.state.routeComment } onChange={ this.commentChangeHandler } placeholder="Enter Comments"/>
                                    <div className="text-danger error-msg">
                                        {this.state.error.routeComment}
                                    </div>
                            </div>
                        </div>
                        <div className="addButton">
                            <button className="brand-button assignment-button" onClick={this.createReaderHandler}>Assign Reader</button>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
    }     
}

export default RouteAssignementPage