import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL  } from 'assets/constants/Constants';
import ShowToast from 'components/ShowToast';
import { BILLING_ICON } from "assets/constants/Icons";

function BillingSetupPage(props) {
    const [unitOptionsArray, setUnitOptionsArray] = useState([]);
    const [basePrice, setBasePrice] = useState(0);
    const [dueDate, setDueDate] = useState(0);
    const [lateFee, setLateFee] = useState(0);
    const [ disCount, setdisCount] = useState (0);
    const [ sGST, setsGST ] = useState(0);
    const [ cGST, setcGST ] = useState(0);
    const [ iGST, setiGST ] = useState(0);
    const [ setupId, setSetupId ] = useState(null);

    useEffect(() => {
        const getBillingSetup = () => {
            axios.get(`${API_BASE_URL}/billing-setup`)
            .then((response) => {
                console.log("response", response);
                if(response.data.result.result.length > 0){
                    const setupResult = response.data.result.result[0];
                    setBasePrice(setupResult.base_charge);
                    setDueDate(setupResult.due_date);
                    setLateFee(setupResult.late_fee);
                    setdisCount(setupResult.discount.value);
                    setsGST(setupResult.sgst);
                    setcGST(setupResult.cgst);
                    setiGST(setupResult.igst);
                    setUnitOptionsArray(setupResult.unit);
                    setSetupId(setupResult._id);
                }
            })
            .catch((e) => {
                console.log("Erorr while fetching setup");
            })
        }

        getBillingSetup();
    }, [])

    const addUnitOptions = (event) => {
        event.preventDefault();
        const options = [...unitOptionsArray];
        options.push({
            from: 0,
            to: 0,
            value: 0
        });
        setUnitOptionsArray(options);
    }

    useEffect(() => {
        console.log(unitOptionsArray);
    }, [unitOptionsArray]);

    const unitFromChangeHandler = (value, index) => {
        const unitArray = [...unitOptionsArray];
        unitArray[index].from = value;
        setUnitOptionsArray(unitArray);
    }

    const unitToChangeHandler = (value, index) => {
        const unitArray = [...unitOptionsArray];
        unitArray[index].to = value;
        setUnitOptionsArray(unitArray);
    }

    const unitValueChangeHandler = (value, index) => {
        const unitArray = [...unitOptionsArray];
        unitArray[index].value = value;
        setUnitOptionsArray(unitArray);
    }

    const basePriceChangeHandler = (event) => {
        if(!isNaN(event.target.value) ){
            setBasePrice(event.target.value);
        }
    }
    const dueDateChangeHandler = (event) => {
        if(!isNaN(event.target.value) ){
            setDueDate(event.target.value);
        }
    }
    const lateFeeChangeHandler = (event) => {
        if(!isNaN(event.target.value) ){
            setLateFee(event.target.value);
        }
    }
    const disCountChangeHandler = (event) => {
        if(!isNaN(event.target.value) && (event.target.value.length<3) ){
            setdisCount(event.target.value);
        }
    }
    const sGSTChangeHandler = (event) => {
        if(!isNaN(event.target.value) ){
            setsGST(event.target.value);
        }
    }
    const cGSTChangeHandler = (event) => {
        if(!isNaN(event.target.value) ){
            setcGST(event.target.value);
        }
    }
    const iGSTChangeHandler = (event) => {
        if(!isNaN(event.target.value) ){
            setiGST(event.target.value);
        }
    }

    const billingSetupHandler = async (event) => {
        event.preventDefault();
        const requestData = {
            unit: unitOptionsArray,
            discount: {value: disCount, unit: "percent" },
            sgst: sGST,
            cgst: cGST,
            igst: iGST,
            due_date: dueDate,
            base_charge: basePrice,
            late_fee: lateFee
        };

        console.log(requestData);
        let response = null;
        if (setupId) {
            response = await axios.put(`${API_BASE_URL}/billing-setup/${setupId}`, requestData);
        }
        else {
            response = await axios.post(`${API_BASE_URL}/billing-setup`, requestData);
        }

        if(response.status === 200) {
            if(setupId){
                ShowToast('Setup updated Successfully', TYPE_SUCCESS, INTERVAL, props, "");
            }
            else{
                ShowToast('Setup created Successfully', TYPE_SUCCESS, INTERVAL, props, "");
            }
        }
        else{
            if(setupId){
                ShowToast('Error occured while updating bill setup', TYPE_FAIL, INTERVAL, props, "");
            }
            else{
                ShowToast('Error occured while creating bill setup', TYPE_FAIL, INTERVAL, props, "");
            }
        }
    }

    return (
        <div className="main-content">
            <div className="back">
                <ul className="breadcrumb1">
                    <li>Billing Setup</li>
                </ul>
            </div>
            <div className="billing-setup-form-outer-div">
                <div className="mx-auto text-left border create-billing-div">
                    <form className="pt-1">
                        <div className="billing-icon-wrapper">
                            <h4>Billing Setup</h4>
                            <i className={`${BILLING_ICON} list-table-icon`}></i>
                        </div>
                        <div className="row">
                            <div className="input-label-group col-md-12">
                                <div className='unit-btn-div'>
                                    <label className="form-label">Unit <span className="text-danger">*</span></label>
                                    <button className='button button-primary button-sm' onClick={addUnitOptions}>Add Unit</button>
                                </div>
                                <hr />
                                <div>
                                    {
                                        unitOptionsArray.length > 0 ? (
                                            unitOptionsArray.map((unitItem, index) => {
                                                return (
                                                    <div className='row'>
                                                        <div className="input-label-group col-md-4">
                                                            <label className="form-label">From</label>
                                                            <input className='form-control unit-input' value={unitItem.from} onChange={(event) => unitFromChangeHandler(event.target.value, index)} />
                                                        </div>
                                                        <div className="input-label-group col-md-4">
                                                            <label className="form-label">To</label>
                                                            <input className='form-control unit-input' value={unitItem.to } onChange={(event) => unitToChangeHandler(event.target.value, index)} />
                                                        </div>
                                                        <div className="input-label-group col-md-4">
                                                            <label className="form-label">Price</label>
                                                            <input className='form-control unit-input' value={unitItem.value} onChange={(event) => unitValueChangeHandler(event.target.value, index)} placeholder="eg. 50" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className='empty-unit-div'>Click Add Unit Button To Add Units</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="input-label-group col-md-12 mt-5">
                                <label className="form-label">Base Price <span className="text-danger">*</span></label>
                                <hr />
                                <div className='row'>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Base Price</label>
                                        <input className='form-control unit-input' value={ basePrice } onChange={basePriceChangeHandler} placeholder="eg. 150" />
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Due Date</label>
                                        <input className='form-control unit-input' value = { dueDate} onChange = { dueDateChangeHandler } placeholder="eg. 10" />
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Late Fee</label>
                                        <input className='form-control unit-input' value = { lateFee } onChange = { lateFeeChangeHandler } placeholder="eg. 5" />
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Discount</label>
                                        <input className='form-control unit-input' value = { disCount } onChange = { disCountChangeHandler } placeholder="eg. 5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="input-label-group col-md-12 mt-5">
                                <label className="form-label">GST <span className="text-danger">*</span></label>
                                <hr />
                                <div className='row'>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">SGST</label>
                                        {/* <input className='form-control unit-input' options={unitOptionsArray} placeholder="eg. 18%" /> */}
                                        <input className='form-control unit-input' value = { sGST } onChange = { sGSTChangeHandler } placeholder="eg. 5" />

                                    </div>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">CGST</label>
                                        {/* <input className='form-control unit-input' placeholder="eg. 18%" /> */}
                                        <input className='form-control unit-input' value = { cGST } onChange = { cGSTChangeHandler } placeholder="eg. 5" />

                                    </div>
                                    <div className="input-label-group col-md-4">
                                        <label className="form-label">IGST</label>
                                        {/* <input className='form-control unit-input' placeholder="eg. 18%" /> */}
                                        <input className='form-control unit-input' value = { iGST } onChange = { iGSTChangeHandler } placeholder="eg. 5" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 mb-5 mt-5">
                            <div className="col-md-6">
                                <button className="brand-button" onClick={billingSetupHandler}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BillingSetupPage
