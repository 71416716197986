import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, INTERVAL } from 'assets/constants/Constants';
import { REFRESH_ICON , RIGHTMARK_ICON} from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReadingListTable from 'views/report/ReportComponent/ReadingListTable';
import ReadingDifferenceTable from "views/report/ReportComponent/ReadingDifferenceTable"
import ReadingGraphPage from "views/report/ReportComponent/ReadingGraphPage"
import moment from "moment";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver'; 
import { defaultFormatDateTime } from "lib/utils"

export class ReportTransactionPage extends Component {
    constructor(props) {
        super(props)
        let siteId = sessionStorage.getItem("siteId");
        this.state = {
            isLoading: true,
            buttonFlag: false,
            startDate:"",
            endDate: moment().endOf('day').toDate(),
            resultList: [],
            meterList: [],
            choice: null,
            routeNameChoice: null,
            meterSerialNumberChoice: null,
            routeIdChoice: null,
            routeId: null,
            allmeterSerialNumber: [],
            choiceList: [
                { value: "Reading", label: "Reading" },
                { value: "Meter Reading", label: "Meter Reading" },
                { value: "Meter Reading Graph", label: "Meter Reading Graph" }
            ],
            avarageReadingData: [],
            renderButtonEnabaled: true,
            error: {},
            currentPage: 1,
            itemsPerPage: 10000,
            onnileIconClass: "enable-icon",
            toggleLiveSyncIconClass: true,
            displayedReadingList: [],
            lastdatafeched: null,
            isDwnXlsDisabled: true,
            siteId: siteId,
            maxEndDate: new Date(),
            avarageReadingsData:[],
            getBillingTransaction:[],
            resavarageReadingDataultList: [],
            lastFiveMonthBillingCycle: [],
            daysDiffrenceFromStartToEndDate: [],
            daysDiffrence: [],
            GraphButtonFlag: false,
            excelButtonFlag: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeData = this.handleChangeData.bind(this);
    }

    async componentDidMount() {
        const getBillingCycleDetails = await this.getBillingDetails()
        this.setRenderButtonEnabled();
        this.setState({
            isLoading: false,
            toggleLiveSyncIconClass: !this.state.toggleLiveSyncIconClass,
            liveSyncIconClass: this.state.toggleLiveSyncIconClass ? "enable-icon" : "disable-icon",
            billingCycleRouteName: getBillingCycleDetails.data.result
        });

    }

    handleChange(date) {
        this.setState({
            startDate: date,
            buttonFlag:false,
            displayedReadingList: [],
            isDwnXlsDisabled: false
        })
    }

    handleChangeData(date) {
        this.setState({
            endDate: date,
            buttonFlag: false,
            isDwnXlsDisabled: false,
            displayedReadingList: [],
        })
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        let siteName = sessionStorage.getItem("siteName");

        if(!this.state.choice) {
            isValid = false;
            error.type = "Please select type";
        }
   
        if (!this.state.startDate && (this.state.choice === "Meter Reading" || this.state.choice === "Reading")) {
            isValid = false;
            error.startDateError = "Please select date";
        }
        

        if (!this.state.routeNameChoice && this.state.choice === "Meter Reading Graph") {
            isValid = false;
            error.routeNameChoiceError = "Please select route name";
        }

        if (!this.state.meterSerialNumberChoice && this.state.choice === "Meter Reading Graph") {
            isValid = false;
            error.serialNumberChoiceError = "Please select serial Number";
        }


        if (siteName === "PWD GOA dIV .XVII") {
            const selectedStartDate = moment(this.state.startDate);
            const selectedEndDate = moment(this.state.endDate);
            const diffrenceBetweenDates = moment(selectedEndDate).diff(selectedStartDate, 'days') > 62;
            if (diffrenceBetweenDates) {
                isValid = false;
                error.startDateError = "Please select date in between  2 months ( ex: 1/10/2023 to 1/12/2023 )";
                ShowToast('Please choose a date in between the 2 months exclusively for this site', TYPE_SUCCESS, INTERVAL, this.props, "");
            }
        }

        this.setState({
            error
        });
        
        return isValid;
    }
    
    createReportHandler = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isLoading: true, buttonFlag: true, excelButtonFlag:true });
            let routeResponse = null;
            const pageSize = this.state.itemsPerPage;

            const fetchData = async (pageNumber) => {
                try {
                    if (this.state.choice === "Reading") {
                        routeResponse = await axios(`${API_BASE_URL}/readings/site/${this.state.siteId}/dateLimits/${this.state.startDate}/${this.state.endDate}`, {
                            params: {
                                page: pageNumber,
                                pagesize: pageSize
                            }
                        });
                    } else if (this.state.choice === "Meter Reading") {
                        routeResponse = await axios(`${API_BASE_URL}/meter/reading/difference/${this.state.startDate}/${this.state.endDate}/site/${this.state.siteId}`);
                    }

                    if(pageNumber === 1){
                        let firstCallData = routeResponse.data.result.slice(0, 5000);
                       this.setState({
                           firstCallFechedReadingData:firstCallData
                       })
                    }

                    const resultList = routeResponse.data.result;
                    const lastData = routeResponse.data.lastReadingData;
                    const newDisplayedData = [...this.state.displayedReadingList, ...resultList];

                    this.setState({
                        resultList: resultList,
                        isLoading: false,
                        displayedReadingList: newDisplayedData,
                        lastdatafeched: lastData,
                    }, () => {
                        if (resultList.length < 10000) {
                            this.setState({
                                isDwnXlsDisabled: false
                            });
                            var element = document.getElementById("download-excel-button");
                            element.classList.toggle("mystyle");
                            ShowToast('Report created successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                            this.exportToXLSX();
                            this.setState({
                                excelButtonFlag:false,
                            })                        
                        }
                    });

                    if (resultList.length === pageSize) {
                        await fetchData(pageNumber + 1);
                    }
                } catch (error) {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                }
            };
            fetchData(1);
        }
    };

    getBillingDetails = async () => {
        return await axios(`${API_BASE_URL}/billing/sitewise/${sessionStorage.getItem("siteId")}`);
    }
   
    getMeterAllData = async () => {
        const routeName = this.state.routeNameChoice;
        const routeItem = this.state.billingCycleRouteName.find(meterItem => meterItem.route_name === routeName);
        if (routeItem) {
            this.setState({
                routeId: routeItem.route_id
            }, async () => {
                try {
                    const meterAllData = await axios(`${API_BASE_URL}/route_meter_map/route/${this.state.routeId}`);
                    const allMeterSerialNumber = meterAllData.data.result.map(item => item.serial_number)
                    this.setState({
                        allmeterSerialNumber: allMeterSerialNumber
                    })
                }
                catch (error) {
                    console.error("Error fetching meterSerialNumber data:", error);
                }
            })
        }
    }
    
    
    genarateReadingGraph = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            this.setState({ GraphButtonFlag: true, isLoading: true });
            const routeName = this.state.routeNameChoice;
            const routeItem = this.state.billingCycleRouteName.find(meterItem => meterItem.route_name === routeName);
            if (routeItem) {
                this.setState({
                    routeId: routeItem.route_id,
                }, async () => {
                    try {
                        const getBillingTransactionData = await axios(`${API_BASE_URL}/billing_transaction/route/${this.state.routeId}/${this.state.meterSerialNumberChoice}/${sessionStorage.getItem("siteId")}`);
                        this.setState({
                            totalReadingData: getBillingTransactionData.data.totalReadingsArray,
                            avarageReadingData: getBillingTransactionData.data.calculatedAvarageReadings,
                            daysDiffrenceFromStartToEndDate: getBillingTransactionData.data.daysDifferenceArray,
                            lastFiveMonthBillingCycle: getBillingTransactionData.data.lastFiveMonthNames,
                            isLoading: false,
                        }, () => {
                            ShowToast(' Reading Report Graph Created Successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                        })
                    } catch (error) {
                        console.error("Error fetching graph data:", error);
                    }
                });
            }
            else {
                console.log("error")
            }
        }
    }

    exportToXLSX = async () => {
        this.setState({ isLoadingdata:true ,excelButtonFlag:true })
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report Data');

            let headersToUse = [];
            const AMIReadings = this.props.pageName === "AMI Readings" ? "Gateway" : "Aqualink"
            if (this.state.choice === "Reading") {
                headersToUse = [
                    "R-Serial Number",
                    "Web Receive Time",
                    "Route",
                    `${AMIReadings}`,
                    "Reader",
                    "R-Unit",
                    "R-Meter Status",
                    "Alarm Type",
                    "Meter Time",
                    "Readings",
                    "Photo",
                    "RSSI",
                    "SSR"
                ];
            } else if (this.state.choice === "Meter Reading") {
                headersToUse = [
                    "M-Serial Number",
                    "Meter Type",
                    "M-Unit",
                    "Meter AMR Channel",
                    "M-Meter Status",
                    "Previous Reading Date",
                    "Previous Reading",
                    "Current Reading Date",
                    "Current Reading",
                    "Difference"
                ];
            }
            const headerRow = worksheet.addRow(headersToUse);
            const headerDataMapping = {
                "R-Serial Number": "meter_serial_no",
                "Web Receive Time": "created_date_time",
                "Route": "route_name",
                [AMIReadings]: "sird_device_id",
                "Reader": "assigned_user_id",
                "R-Unit": "unit",
                "R-Meter Status": "meter_status",
                "Alarm Type": "alarm_type",
                "Meter Time": "receive_date_time",
                "Readings": "meter_reading",
                "Photo": "reading_image",
                "RSSI": "field_strength_rssi",
                "SSR": "snr",
                //meter reading data
                "M-Serial Number": "serial_number",
                "Meter Type": "meter_type",
                "M-Unit": "unit",
                "Meter AMR Channel": "amr_channel",
                "M-Meter Status": "status",
                "Previous Reading Date": "previous_reading_date",
                "Previous Reading": "previous_reading",
                "Current Reading Date": "current_reading_date",
                "Current Reading": "current_reading",
                "Difference": "difference"
            };
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: "red" };
            });
            const dateFormatArray = ["current_reading_date", "previous_reading_date", "created_date_time"];
            function unitConversion(value) {
                if (value === "0" || value === "m3") {
                    return "m3";
                }
                else if (value === "1" || value === "L") {
                    return "L"
                }
                else if (value === "2" || value === "GAL") {
                    return "GAL"
                }
                else if (value === "3" || value === "ft3") {
                    return "ft3"
                }
                else {
                    return "NA";
                }
            }
            this.state.displayedReadingList.forEach((row) => {
                const rowData = headersToUse.map((header) => {
                    const dataProperty = headerDataMapping[header];
                    if (dateFormatArray.includes(dataProperty)) {
                        const formatedDate = defaultFormatDateTime(row[dataProperty]);
                        if (formatedDate === "Invalid date") {
                            return "NA";
                        } else {
                            return formatedDate;
                        }
                    } else if (dataProperty === "unit") {
                        return unitConversion(row[dataProperty]);
                    } else {
                        return row[dataProperty] || "NA";
                    }
                });
                worksheet.addRow(rowData);
            });
            const excelBlob = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'report.xlsx');
            this.setState({ isLoadingdata:false, excelButtonFlag:false}) 
            var element = document.getElementById("download-excel-button");
            element.classList.toggle("mystyle");
        } catch (error) {
            console.error(error);
        }
    }

    renderChoiceOptions = () => {
        if (this.state.choiceList.length > 0) {
            return this.state.choiceList.map((meterItem, index) => {
                let isSelected = false;
                if (meterItem.value === this.state.choice) {
                    isSelected = true;
                }
                return (
                    <option value={meterItem.value} selected={isSelected} key={meterItem.value} className="option-text-color"> {meterItem.label} </option>
                )
            });
        }
        else {
            return "<option> No Data Found</option>"
        }
    }

    renderRouteNameChoiceOptions = () => {
        if (this.state.billingCycleRouteName.length > 0) {
            return this.state.billingCycleRouteName.map((meterItem, index) => {
                let isSelected = false;
                if (meterItem.route_name === this.state.routeNameChoice) {
                    isSelected = true;
                }
                return (
                    <option value={meterItem.route_name} selected={isSelected} key={meterItem.route_name} className="option-text-color"> {meterItem.route_name} </option>
                )
            });
        }
        else {
            return "<option> No Data Found</option>"
        }
    }

    setRenderButtonEnabled = () => {
        if (this.state.allmeterSerialNumber.length > 0) {
            this.setState({
                renderButtonEnabaled: false
            });
        }
    }

    renderMeterSerialNumberChoiceOptions = () => {
        if (this.state.allmeterSerialNumber.length > 0) {
            return this.state.allmeterSerialNumber.map((meterSerialNo, index) => {
                let isSelected = false;
                if (meterSerialNo === this.state.meterSerialNumberChoice) {
                    isSelected = true;
                }
                return (
                    <option value={meterSerialNo} selected={isSelected} key={meterSerialNo} className="option-text-color"> {meterSerialNo} </option>
                )
            });
        }
        else {
            return "<option> No Data Found</option>"
        }
    }

    choiceChangeHandler = (event) => {
        this.setState({
            choice: event.target.value,
            buttonFlag: false,
            assignRoute: null,
            meterStatus: null,
            GraphButtonFlag: false,
            routeNameChoice: null,
            startDate: null,
            meterSerialNumberChoice: null,
            lastdatafeched: null,
        });
    }
    
    choiceRouteNameChangeHandler = (event) => {
        this.setState({
            routeNameChoice: event.target.value,
            GraphButtonFlag: false,
        }, () => {
            this.getMeterAllData()
        });
    }

    choiceMeterSerialNumberChangeHandler = (event) => {
        this.setState({
            meterSerialNumberChoice: event.target.value,
            GraphButtonFlag: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li>Report Transaction</li>
                        </ul>
                    </div>
                    <div className="list-wrapper">
                        <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                            <div className="row">
                                <div className="addUser">
                                    <label className="form-label">Select Type <span className="text-danger">*</span></label>
                                    <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={this.choiceChangeHandler} placeholder="Select" >
                                        <option selected value="0" disabled >Select</option>
                                        {
                                            this.renderChoiceOptions()
                                        }
                                    </select>
                                    <div className="text-danger error-msg">
                                        {this.state.error.type}
                                    </div>
                                </div>
                            </div>
                            {(this.state.choice === "Reading" || this.state.choice === "Meter Reading") ? (
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">From Date <span className="text-danger">*</span></label>
                                        <div className="calender-div">
                                            <span className="icon fa-calendar" />
                                            <DatePicker className="form-control brand-font p-3"
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Please select date"
                                                maxDate={this.state.maxEndDate}
                                            />
                                        </div>
                                        <div className="text-danger error-msg">
                                            {this.state.error.startDateError}
                                        </div>
                                    </div>
                                    <div className="addUser">
                                        <label className="form-label">To Date <span className="text-danger">*</span></label>
                                        <div className="calender-div">
                                            <span className="icon fa-calendar" />
                                            <DatePicker className="form-control brand-font p-3"
                                                selected={this.state.endDate}
                                                onChange={this.handleChangeData}
                                                timeIntervals={1}
                                                timeCaption="time"
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxEndDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                    this.state.choice === "Meter Reading Graph" && (
                                        <div className="row">
                                            <div className="addUser">
                                            <label className="form-label">Select Route Name <span className="text-danger">*</span></label>
                                                <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={this.choiceRouteNameChangeHandler} placeholder="Select" >
                                                    <option selected value="0" disabled >Select</option>
                                                    {this.renderRouteNameChoiceOptions()}
                                                </select>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.routeNameChoiceError}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}

                            {
                                this.state.routeNameChoice !== null && (
                                    <div className="row">
                                        <div className="addUser">
                                            <label className="form-label">Select Serial Number <span className="text-danger">*</span></label>
                                            <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={this.choiceMeterSerialNumberChangeHandler} placeholder="Select" >
                                                <option selected value="0" disabled >Select</option>
                                                {this.renderMeterSerialNumberChoiceOptions()}
                                            </select>
                                            <div className="text-danger error-msg">
                                                {this.state.error.serialNumberChoiceError}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                this.state.choice === "Reading" || this.state.choice === "Meter Reading" ? (
                                    <div className="addButton">
                                        <button id="button" className="brand-button report-button" onClick={this.createReportHandler}>Get Report</button>
                                        {
                                            this.state.isLoadingdata === true ? (
                                                <span>
                                                    <button id="download-excel-button" className="download-table-xls-button brandxls-button download-button brandxls-buttons loader"></button>
                                                </span>
                                            ) : (
                                                <>
                                                    <button
                                                        disabled={this.state.isDwnXlsDisabled}
                                                        id="download-excel-button"
                                                        className="download-table-xls-button brandxls-button download-button"
                                                        onClick={this.exportToXLSX}>Download xls to get all rows</button>
                                                    <span className="enabled-text">
                                                        {this.state.isDwnXlsDisabled === true ? <i> (Enabled after table is loaded)</i> : " "}
                                                    </span>
                                                </>
                                            )
                                        }
                                    </div>
                                ) : null
                            }

                            {
                                this.state.choice === "Meter Reading Graph" ? (
                                    <div className="addButton">
                                        <button id="button" className="brand-button report-graph-button" onClick={this.genarateReadingGraph}>Get Report Graph</button>
                                    </div>
                                ) : null
                            }
                        </div>
                       
                        {
                            this.state.buttonFlag === true && (
                                this.state.displayedReadingList.length > 0 ? (
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                        <div className="live-sync-icon-and-text-container">
                                            {
                                                this.state.lastdatafeched < 10000 ? (
                                                    <div>
                                                    <i className={`${RIGHTMARK_ICON} ${this.state.liveSyncIconClass}`}>{"  "}</i>
                                                      <label className={`disable-icon`}>Data Feched Successfully Now You can Download XLS Sheet ...</label>
                                                      </div>
                                                ) : (
                                                    <>
                                                        <i className={`${REFRESH_ICON} ${this.state.liveSyncIconClass} rotate`}></i>
                                                        <label className={`${this.state.liveSyncIconClass}`}>Fetched {this.state.displayedReadingList.length} data...</label>
                                                    </>
                                                )
                                            }
                                        </div>
                                        {
                                            this.state.choice === "Reading" && (
                                                 <ReadingListTable readingList={this.state.firstCallFechedReadingData} />
                                            )
                                        }
                                        {
                                            this.state.choice === "Meter Reading" && (
                                                <ReadingDifferenceTable meterList={this.state.resultList} />
                                            )
                                        }
                                        <br/>
                                    </div>
                                ) : (
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                        <div className="text-center pt-2"> No Records Found!</div>
                                    </div>
                                ))
                        }
                        {
                            this.state.GraphButtonFlag === true && (
                                <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                    {this.state.choice === "Meter Reading Graph" && (
                                        this.state.avarageReadingData.length > 0 && (
                                            <ReadingGraphPage avarageReadingsData={this.state.avarageReadingData} monthNames={this.state.lastFiveMonthBillingCycle} daysDiffrence={this.state.daysDiffrenceFromStartToEndDate} />
                                        )
                                    )}
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}

export default ReportTransactionPage