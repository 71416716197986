import React, { Component } from 'react'

export class UserListTable extends Component {

    renderSiteNames = (item) => {
        let siteNamesArray = [];
        this.props.siteList.forEach((siteItem) => {
            item.forEach((userSiteitem) => {
                if(siteItem._id === userSiteitem){
                    siteNamesArray.push(siteItem.site_name);
                }
            });
        });
        return siteNamesArray.join(", ");
    }

    renderRole = (item) => {
        let roleNameArray = [];
        this.props.roleList.forEach((roleItem) => {
            if(roleItem._id === item){
                roleNameArray.push(roleItem.user_role);
            }
        });
        return roleNameArray.join(", ");
    }

    render() {
        return (
            <React.Fragment>
                <table className="table list-table" id="table-to-xls" >
                    <thead>
                        <tr>
                            <th className="pl2">User Name</th>
                            <th>Email Id</th>
                            <th>Phone Number</th>
                            <th>Username</th>
                            <th>Site</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody className="user-list-table">
                    {
                        this.props.userList.map((item) => {
                            return (
                                <tr key={ item._id }>
                                    <td className="name-td pl2"> { `${item.first_name} ${item.last_name}` } </td>
                                    <td className="email-td"> { item.email_id } </td>
                                    <td className="phone-td" > { item.phone_number } </td>
                                    <td className="username-td"> { item.username } </td>
                                    <td className="site-td"> { this.renderSiteNames(item.site_id) } </td>
                                    <td className="role-td"> { this.renderRole(item.role) } </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default UserListTable
