import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_SUCCESS, TYPE_INFO, INTERVAL, TYPE_FAIL } from "assets/constants/Constants";
import { METER_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';

export class CreateMeterPage extends Component {
    constructor(props) {
        super(props)
    
        const editData = this.props.history.location.state;
        const userDetail = JSON.parse(sessionStorage.getItem("userData"));
        this.state = {
            isLoading: true,
            meterId: editData === null ? null : editData._id,
            siteId: editData === null ? sessionStorage.getItem("siteId") : editData.site_id,
            meterSerialNo: editData === null ? "" : editData.serial_number,
            meterType: editData === null ? null : editData.meter_type,
            meterAMRChannel: editData === null ? "" : editData.amr_channel,
            meterStatus: editData === null ? "inactive" : editData.status,
            meterCustomer: editData === null ? "" : editData.meter_assign_to_cust,
            statusReason:editData === null ? "" : editData.reason,
            userDetail: userDetail,
            reasonFlag:false,
            siteList: [],
            meterList: [],
            activeTabIndex: 0,
            meterTypeList: [
                { value: "Residential Water Meter", label: "Residential Water Meter" },
                { value: "Commercial Water Meter", label: "Commercial Water Meter" }

            ],
            meterAMRChannelList: [
                { value: "IN865_CHANNEL_0_FREQ", label: "IN865_CHANNEL_0_FREQ" },
                { value: "IN865_CHANNEL_1_FREQ ", label: "IN865_CHANNEL_1_FREQ" },
                { value: "IN865_CHANNEL_2_FREQ ", label: "IN865_CHANNEL_2_FREQ " },
                { value: "IN865_RX_WND_2_FREQ ", label: "IN865_RX_WND_2_FREQ " },
            ],
            meterStatusList: [
                { value: "inactive", label: "Inactive" },
                { value: "active", label: "Active" },
                { value: "faulty", label: "Faulty" },
            ],
            error: {},
            unitList: [
                { value: "m3", label: "m3" },
                { value: "l", label: "l" },
                { value: "ga", label: "ga" },
                { value: "ft", label: "ft" }
            ],
            meterRecieverType: [
                { value: "ami", label: "AMI" },
                { value: "amr", label: "AMR" },
            ],
            meterReciever: editData === null ? "" : editData.meter_receiver_type,
            totaliserUnit: editData === null ? "" : editData.totaliser_unit,
            flowUnit: editData === null ? "" : editData.flow_unit,
            presetTotaliser: editData === null ? "" : editData.preset_totaliser,
            readRTC: editData === null ? "" : editData.read_rtc,
            setRTC: editData === null ? false : editData.set_rtc,
            sensorFactor: editData === null ? "" : editData.sensor_factor,
            flowRate: editData === null ? "" : editData.flow_rate,
            totaliser: editData === null ? "" : editData.totaliser,
            alarmStatus: editData === null ? "" : editData.alarm_status,
            hardwareVersion: editData === null ? "" : editData.hw_version,
            softwareVersion: editData === null ? "" : editData.sw_version,
            meterVersion: editData === null ? "" : editData.meter_version,
            logEnabled: editData === null ? false : editData.log_enabled,
            eraseEntireDataLog: editData === null ? false : editData.erase_entire_data_log,
            latitude: editData === null ? "" : editData.gps_latitude,
            longitude: editData === null ? "" : editData.gps_longitude,
            gpsAccuracy: editData === null ? "" : editData.gps_accuracy,
            routeList: [],
            assignedRoute: editData === null ? "" : editData.assign_route,
            routeOptions: []
        }
    }
    
    async componentDidMount() {
        this.setState({
            isLoading: false,
        });
    }

    renderMeterTypeOptions = () => {
        if(this.state.meterTypeList.length > 0){
            return this.state.meterTypeList.map((meterItem, index) => { 
                let isSelected = false;
                if(meterItem.value === this.state.meterType){
                    isSelected = true;
                }
                return(
                    <option value={ meterItem.value } selected = { isSelected } key= { meterItem.value } className = "option-text-color"> { meterItem.label } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    renderMeterStatusOptions = () => {
        if(this.state.meterTypeList.length > 0){
            return this.state.meterStatusList.map((meterItem, index) => {
                let isSelected = false;
                if(meterItem.value === this.state.meterStatus){
                    isSelected = true;
                }
                return(
                    <option value={ meterItem.value } selected = { isSelected } key= { meterItem.value } className = "option-text-color"> { meterItem.label } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    renderMeterReciverTypeOptions = () => {
        if(this.state.meterTypeList.length > 0){
            return this.state.meterRecieverType.map((meterItem, index) => {
                let isSelected = false;
                if(meterItem.value === this.state.meterReciever){
                    isSelected = true;
                }
                return(
                    <option value={ meterItem.value } selected = { isSelected } key= { meterItem.value } className = "option-text-color"> { meterItem.label } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    renderMeterAmrChannelOptions = () => {
        if(this.state.meterAMRChannelList.length > 0){
            return this.state.meterAMRChannelList.map((meterItem, index) => { 
                let isSelected = false;
                if(meterItem.value === this.state.meterAMRChannel){
                    isSelected = true;
                }
                return(
                    <option value={ meterItem.value } selected = { isSelected } key= { meterItem.value } className = "option-text-color"> { meterItem.label } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    serialNumChangeHandler = (event) => {
        this.setState({
            meterSerialNo: event.target.value
        });
    }

    meterTypeChangeHandler = (event) => {
        this.setState({
            meterType: event.target.value,
        });
    }

    meterAMRChannelChangeHandler = (event) => {
        this.setState({
            meterAMRChannel: event.target.value,
        });
    }

    meterStatusChangeHandler = (event) => {
        this.setState({
            meterStatus: event.target.value,
            reasonFlag: event.target.value !== "active" ? true : false
        });
    }

    meterReciverTypeChangeHandler = (event) => {
        this.setState({
            meterReciever: event.target.value,
        });
    }

    statusReasonChangeHandler = (event) => {
        this.setState({
            statusReason: event.target.value
        });
    }

    latitudeChangeHandler = (event) => {
        this.setState({
            latitude : event.target.value
        });
    }

    longitudeChangeHandler = (event) => {
        this.setState({
            longitude: event.target.value
        });
    }

    checkIfMeterAlreadyPresent = async (event) => {
        event.preventDefault();
        let meterDetails = {
            meterId: null,
            siteId: sessionStorage.getItem("siteId"),
            meterType: "",
            meterAMRChannel: "",
            meterStatus: "inactive",
            meterCustomer: ""
        };

        const meterData = await axios(`${API_BASE_URL}/meter/serialNo/${event.target.value}`);
        if (meterData.status === 200) {
            if (meterData.data.result && meterData.data.result.length > 0) {
                if(meterData.data.result[0].receiver_type !== 'AMI'){
                    meterDetails = {
                        meterId: meterData.data.result[0] === null ? null : meterData.data.result[0]._id,
                        siteId: meterData.data.result[0] === null ? sessionStorage.getItem("siteId") : meterData.data.result[0].site_id,
                        meterSerialNo: meterData.data.result[0] === null ? "" : meterData.data.result[0].serial_number,
                        meterType: meterData.data.result[0] === null ? null : meterData.data.result[0].meter_type,
                        meterAMRChannel: meterData.data.result[0] === null ? "" : meterData.data.result[0].amr_channel,
                        meterStatus: meterData.data.result[0] === null ? null : meterData.data.result[0].status,
                        meterCustomer: meterData.data.result[0] === null ? "" : meterData.data.result[0].meter_assign_to_cust,
                        totaliserUnit: meterData.data.result[0] === null ? "" : meterData.data.result[0].totaliser_unit,
                        flowUnit: meterData.data.result[0] === null ? "" : meterData.data.result[0].flow_unit,
                        presetTotaliser: meterData.data.result[0] === null ? "" : meterData.data.result[0].preset_totaliser,
                        readRTC: meterData.data.result[0] === null ? "" : meterData.data.result[0].read_rtc,
                        setRTC: meterData.data.result[0] === null ? false : meterData.data.result[0].set_rtc,
                        sensorFactor: meterData.data.result[0] === null ? "" : meterData.data.result[0].sensor_factor,
                        flowRate: meterData.data.result[0] === null ? "" : meterData.data.result[0].flow_rate,
                        totaliser: meterData.data.result[0] === null ? "" : meterData.data.result[0].totaliser,
                        alarmStatus: meterData.data.result[0] === null ? "" : meterData.data.result[0].alarm_status,
                        hardwareVersion: meterData.data.result[0] === null ? "" : meterData.data.result[0].hw_version,
                        softwareVersion: meterData.data.result[0] === null ? "" : meterData.data.result[0].sw_version,
                        meterVersion: meterData.data.result[0] === null ? "" : meterData.data.result[0].meter_version,
                        logEnabled: meterData.data.result[0] === null ? false : meterData.data.result[0].log_enabled,
                        eraseEntireDataLog: meterData.data.result[0] === null ? false : meterData.data.result[0].erase_entire_data_log,
                        latitude: meterData.data.result[0] === null ? "" : meterData.data.result[0].gps_latitude,
                        longitude: meterData.data.result[0] === null ? "" : meterData.data.result[0].gps_longitude,
                        gpsAccuracy: meterData.data.result[0] === null ? "" : meterData.data.result[0].gps_accuracy,
                        assignedRoute: meterData.data.result[0] === null ? "" : meterData.data.result[0].assign_route,
                    };
                    this.setState({
                        ...meterDetails
                    }, () => {
                        this.renderMeterTypeOptions();
                        this.renderMeterAmrChannelOptions();
                        this.renderMeterStatusOptions();
                    });
                }
                else{
                    ShowToast('Meter Serial No : ' + meterData.data.result[0].serial_number + ' is already auto added as AMI meter.', TYPE_FAIL, INTERVAL, this.props, "");
                    this.setState({meterSerialNo:''})
                    return;
                }
            }
        }
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if(!this.state.meterSerialNo) {
            isValid = false;
            error.meterSerialNo = "Please enter meter serial number";
        }

        if(!this.state.meterType) {
            isValid = false;
            error.meterType = "Please select meter type";
        }

        if(!this.state.meterAMRChannel) {
            isValid = false;
            error.meterAMRChannel = "Please select meter AMR channel";
        }

        if(!this.state.statusReason && this.state.reasonFlag){
            isValid = false;
            error.statusReason = "Please enter reason";
        }
        if(!this.state.meterReciever){
            isValid = false;
            error.meterRecieverError = "Please select meter receiver type";
        }

        this.setState({
            error
        });

        return isValid;
    }

    createMeterHandler = async (event) => {
        event.preventDefault();
        console.log("this.isFormValid(): ", this.isFormValid());
        if (this.isFormValid()) {
            const requestData = {
                meterType: this.state.meterType,
                siteId: this.state.siteId,
                meterAmrChannel: this.state.meterAMRChannel,
                meterSerialNumber: this.state.meterSerialNo,
                meterStatus: this.state.meterStatus,
                meterCustomer: this.state.meterCustomer,
                totalUnit: this.state.totaliserUnit,
                flowUnit: this.state.flowUnit,
                presetTotaliser: this.state.presetTotaliser,
                readRtc: this.state.readRTC,
                setRtc: this.state.setRTC,
                sensorFactor: this.state.sensorFactor,
                flowRate: this.state.flowRate,
                totaliser: this.state.totaliser,
                alarmStatus: this.state.alarmStatus,
                hwVersion: this.state.hardwareVersion,
                swVersion: this.state.softwareVersion,
                meterVersion: this.state.meterVersion,
                logEnabled: this.state.logEnabled,
                eraseEntireDataLog: this.state.eraseEntireDataLog,
                gpsLatitude: this.state.latitude,
                gpsLongitude: this.state.longitude,
                gpsAccuracy: this.state.gpsAccuracy,
                assignedRoute: this.state.assignedRoute,
                userFirstName:this.state.userDetail.first_name,
                userLastName:this.state.userDetail.last_name,
                userEmailId:this.state.userDetail.email_id,
                userName:this.state.userDetail.username,
                userId:this.state.userDetail._id,
                reason:this.state.statusReason === '' ? (this.state.meterId === null ? "New meter created" : "Meter data updated") : this.state.statusReason,
                meterRecieverType:this.state.meterReciever
            }

            console.log(requestData);
            let meterResponse = null;
            if (this.state.meterId === null) {
                meterResponse = await axios.post(`${API_BASE_URL}/meter`, requestData);
            }
            else {
                meterResponse = await axios.put(`${API_BASE_URL}/meter/${this.state.meterId}`, requestData);
            }
            if (meterResponse.status === 200) {
                this.setState({
                    meterType: [],
                    meterAMRChannel: "",
                    meterStatus: null,
                }, () => {
                    if (this.state.meterId === null) {
                        ShowToast('Please wait while meter created', TYPE_INFO, INTERVAL, this.props, "");
                        ShowToast('Meter created Successfully', TYPE_SUCCESS, INTERVAL, this.props, "/meter-page");
                    }
                    else {
                        ShowToast('Please wait while meter updated', TYPE_INFO, INTERVAL, this.props, "");
                        ShowToast('Meter updated Successfully', TYPE_SUCCESS, INTERVAL, this.props, "/meter-page");
                    }
                    this.props.history.push("/meter-page");
                });
            }
        }
    }

    cancelHandler = (event) => {
        this.props.history.push("/meter-page");
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/meter-page">Meter Master</a></li>
                            {
                                this.state.meterId === null ? (
                                    <li>Add New Meter</li>
                                ) : (
                                    <li>Edit Meter</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-meter-main-view">
                        <div className="mx-auto text-left border create-meter-div">
                            <form className="pt-1">
                                <div className="meter-icon-wrapper">
                                    <h4>{ this.state.meterId === null ? "Add New Meter" : "Edit Meter"}</h4>
                                    <i className={ `${METER_ICON} list-table-icon` }></i>
                                </div>

                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Serial Number <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.meterSerialNo } onChange={ this.serialNumChangeHandler } onBlur={this.checkIfMeterAlreadyPresent}  placeholder="Enter Serial Number" />
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterSerialNo}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Type <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={ this.meterTypeChangeHandler } placeholder="Select Meter Type" >
                                            <option selected value="0" disabled >Select Meter Type</option>
                                            {
                                                this.renderMeterTypeOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterType}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter AMR Channel <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={ this.meterAMRChannelChangeHandler } placeholder="Select Meter AMR Channel" >
                                            <option selected value="0" disabled >Select Meter AMR Channel</option>
                                            {
                                                this.renderMeterAmrChannelOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterAMRChannel}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Status</label>
                                        <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={ this.meterStatusChangeHandler } placeholder="Select Meter Status" >
                                            <option selected value="0" disabled >Select Meter Status</option>
                                            {
                                                this.renderMeterStatusOptions()
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Meter Receiver Type <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={this.meterReciverTypeChangeHandler} placeholder="Select Meter Receiver Type" >
                                            <option selected value="0" disabled >Select Meter Receiver Type</option>
                                            {
                                                this.renderMeterReciverTypeOptions()
                                            }
                                        </select>
                                    <div className="text-danger error-msg">
                                        {this.state.error.meterRecieverError}
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">GPS Latitude</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.latitude } onChange={ this.latitudeChangeHandler } placeholder="Enter GPS Latitude" />
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">GPS Longitude</label>
                                        <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.longitude } onChange={ this.longitudeChangeHandler } placeholder="Enter GPS Longitude" />
                                    </div>
                                </div>
                                {
                                    this.state.reasonFlag && (
                                        <div className="row">
                                            <div className="input-label-group col-md-6">
                                                <label className="form-label">Reason <span className="text-danger">*</span></label>
                                                <input className="form-control brand-font p-3 placeholderColor add-meter-input" value={ this.state.statusReason } onChange={ this.statusReasonChangeHandler } placeholder="Enter reason" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.statusReason}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.createMeterHandler}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div>
            )
        }
    }
}

export default CreateMeterPage
