import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "context/AuthProvider";
import { RouterComponent } from "components/RouterComponent";
// styles
import "assets/css/bootstrap.css";
import "assets/css/main.css";
import "assets/css/fonts.css";


ReactDOM.render(
  <>
    <AuthProvider>
      <BrowserRouter>
        <RouterComponent />
      </BrowserRouter>
    </AuthProvider>
  </>,
  document.getElementById("root")
);