import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL, TYPE_FAIL, INTERVAL } from "assets/constants/Constants";
import Spinner from 'components/Spinner';
import moment from "moment";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {CAMERA_ICON} from 'assets/constants/Icons';
import ShowToast from 'components/ShowToast';

export class MeterViewPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            meterData,
            invoiceList: [],
            readingList:[],
            viewReadingImage: false,
            readingImageSource: ""
        }
    }

    async componentDidMount() {
        const siteData = await this.getSiteList();
        const meterData = await this.getMeterList();
        this.getInvoiceList();
        this.getReadingList();
        this.setState({
            siteList: siteData.data.result,
            meterList: meterData.data.result,
            isLoading: false
        });
    }

    viewImage = (readingImage) => {
        if (readingImage !== "" && readingImage !== undefined) {
            this.setState({
                viewReadingImage: true,
                readingImageSource: readingImage
            });
        }
        else {
            ShowToast("Image not uploaded yet.", TYPE_FAIL, INTERVAL, null, "");
        }
    }
    
    getMeterList = async () => {
        return await axios(`${API_BASE_URL}/meter/${this.state.meterData._id}`);
    }

    getSiteList = async () => {
        return await axios(`${API_BASE_URL}/site/${sessionStorage.getItem("siteId")}`);
    }

    getInvoiceList = () => {
        axios(`${API_BASE_URL}/invoice/consumer/${this.state.meterData.consumer_number}`)
        .then((response) => {
            if(response.data.result.result.length > 0){
                this.setState({
                    invoiceList: response.data.result.result
                });
            }
        })
        .catch((error) => {
            this.setState({
                invoiceList: []
            });
        });
    }

    getReadingList = () => {
        axios(`${API_BASE_URL}/readings/meter/serialnum/${this.state.meterData.serial_number}`)
        .then((response) => {
            if(response.data.result){
                this.setState({
                    readingList: response.data.result.readingData,
                    routeName: response.data.result.route_name
                });
            }
        })
        .catch((error) => {
            this.setState({
                readingList: []
            });
        });
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="meter-page">Meter</a></li>
                            <li>Meter View</li>
                        </ul>
                    </div>
                    <div className="reading-info-outer">
                        <div className="reading-wrapper">
                            <div className="site-wrapper">
                                <div className="row">
                                    <div className="site-info">
                                        <div className="site-content">
                                            <label>Site Name: </label>
                                            <label className="ml-3">{this.state.siteList.site_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Site Address: </label>
                                            <label className="ml-3">{ `${this.state.siteList.street}, ${this.state.siteList.city}, ${this.state.siteList.pincode}` }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-wrapper address-section">
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label>Route Name: </label>
                                            <label className="ml-3">{this.state.meterData.route_name !=null ? this.state.meterData.route_name :"none" }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label>Customer Name: </label>
                                            <label className="ml-3">{this.state.meterData.customer_name != null ? this.state.meterData.customer_name : "NA" }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Customer Address: </label>
                                            <label className="ml-3">{ this.state.meterData.customer_address1!== null ? (`${this.state.meterData.customer_address1}, ${this.state.meterData.customer_address2}, ${this.state.meterData.street}, ${this.state.meterData.city}, ${this.state.meterData.pin_code}`) : "NA" }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.readingList.length > 0 && (
                    <div className="addButton">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button brandxls-button download-button mystyle"
                            table="table-to-xls"
                            filename= {`Reading serial No - ${this.state.meterData.serial_number}`}
                            sheet="tablexls"
                            buttonText="Download as XLS" />
                    </div>
                    )}
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                        {
                            <table className="table list-table">
                                <thead>
                                    <tr>
                                        <th className="pl1">Serial Number</th>
                                        <th>Meter Type</th>
                                        <th>Unit</th>
                                        <th>Meter AMR Channel</th>
                                        <th>Meter Status </th>
                                        <th>Consumer number</th>
                                        <th>Collection status</th>
                                        <th>Alarm status</th>
                                        <th>Receiver Type</th>
                                    </tr>
                                </thead>
                                <tbody className="reading-list-table">
                                {
                                    <tr >
                                        <td className="serial-no-td pl1"> { this.state.meterData.serial_number  } </td>
                                        <td className="meter-type-td"> { this.state.meterData.meter_type  } </td>
                                        <td className="meter-unit-td" > {this.state.meterData.unit } </td>
                                        <td className="meter-amr-td" > { this.state.meterData.amr_channel } </td>
                                        <td className= "meter-status-td" > { this.state.meterData.status  } </td>
                                        <td className="consumer-no-td" > { this.state.meterData.consumer_number != null ? this.state.meterData.consumer_number : "none" }</td>
                                        <td className="other-field-td" > {this.state.meterList.data_status ? this.state.meterList.data_status : "-" } </td>
                                        <td className="other-field-td" > {this.state.meterList.alarm_status !== '' ? this.state.meterList.alarm_status : "-" } </td>
                                        <td className="other-field-td" > {this.state.meterList.receiver_type } </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                    {
                        this.state.invoiceList.length > 0 && (
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                {
                                    <table className="table list-table">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Invoive Number</th>
                                                <th>Consumer Number</th>
                                                <th>Bill Date</th>
                                                <th>Bill Start Date</th>
                                                <th>Bill End Date</th>
                                                <th>Serial Number</th>
                                                <th>Unit Consume</th>
                                                <th>Total Bill</th>
                                            </tr>
                                        </thead>
                                        <tbody className="reading-list-table">
                                            {
                                                this.state.invoiceList.map((invoiceItem) => {
                                                    return(
                                                        <tr onClick={() => this.previewInvoice(invoiceItem)}>
                                                            <td className="customer-name-td pl1" > {invoiceItem.invoice_no} </td>
                                                            <td className="customer-consumer-td" >{invoiceItem.consumer_no}</td>
                                                            <td className="customer-address1-td" > { moment(invoiceItem.bill_date).format("Do MMM, yyyy")} </td>
                                                            <td className="customer-address2-td" > {moment(invoiceItem.start_date).format("Do MMM, yyyy")} </td>
                                                            <td className="customer-address2-td" > {moment(invoiceItem.end_date).format("Do MMM, yyyy")} </td>
                                                            <td className="customer-serial-td"> {invoiceItem.serial_number} </td>
                                                            <td className="customer-address2-td" > {invoiceItem.unit_consume} </td>
                                                            <td className="customer-street-td" > {invoiceItem.net_bill_amount.toFixed(2)} </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        )
                    }
                    {
                        this.state.readingList.length > 0 && (
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                {
                                    <table className="table list-table" id="table-to-xls">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Serial Number</th>
                                                <th>Route Name</th>
                                                <th>Device ID</th>
                                                <th>Reader</th>
                                                <th>Unit</th>
                                                <th>Meter Status</th>
                                                <th>Reading Status</th>
                                                <th>Alarm Type</th>
                                                <th>Date</th>
                                                <th>Reading</th>
                                                <th>Photo</th>
                                            </tr>
                                        </thead>
                                        <tbody className="reading-list-table">
                                            {
                                                this.state.readingList.map((readingItem) => {
                                                    return(
                                                        <tr>
                                                            <td className="customer-name-td pl1" > {readingItem.meter_serial_no} </td>
                                                            <td className="route-td" >{this.state.routeName}</td>
                                                            <td className="sird-td" >{readingItem.gway_device_id}</td>
                                                            <td className="reader-td"> {readingItem.assigned_user_id} </td>
                                                            <td className="unit-td" > {readingItem.unit} </td>
                                                            <td className="alarm-td" > {readingItem.meter_status} </td>
                                                            <td className="alarm-td" > {readingItem.meter_reading_status} </td>
                                                            <td className="alarm-td" > {readingItem.alarm_type} </td>
                                                            <td className="date-td" > {readingItem.receive_date_time} </td>
                                                            <td className="reading-td" > {readingItem.meter_reading} </td>
                                                            <td className="reading-image-td"><i className={(readingItem.reading_image !== "" && readingItem.reading_image !== undefined) ? `${CAMERA_ICON} camera-icon` : `${CAMERA_ICON} gray-icon`} title="Reading Image" onClick={() => this.viewImage(readingItem.reading_image)}/></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                                { this.state.viewReadingImage && (
                                    <div className="custom_modal">
                                        <div className="modal_body">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <h4 className="modal_title pagetitle">View Meter Reading Image</h4>
                                                </div>
                                                <div className="col-md-2">
                                                    <button type="button" className="close text-right" style={{fontSize: 20}} onClick={(e) => this.setState({viewReadingImage: false})}>&times;</button>
                                                </div>
                                                {(this.state.readingImageSource.includes("png") || this.state.readingImageSource.includes("jpg") || this.state.readingImageSource.includes("jpeg")) ? (
                                                    <embed src={this.state.readingImageSource} title="photo" width="100%" height="auto" />
                                                ) : (
                                                    <embed src={this.state.readingImageSource} title="photo" width="100%" height="auto" style={{height: '400px'}} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
            )
        }
    }
}

export default MeterViewPage
