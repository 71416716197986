import React, { Component } from "react";

class App extends Component {
  render() {
    return (
      <>
        <div className="outer-class">
          <a href="home-page">Home Page</a>
          <a href="user-page">User Page</a>
          <a href="sites-page">Sites</a>
          <a href="route-page">Route Page</a>
          <a href="meter-page">Meter Page</a>
          <a href="login-page">Logout</a>
        </div>
        <div className="demo-text">
          SBEM CRUD Operation DEMO
        </div>
      </>
    );
  }
}

export default App;
