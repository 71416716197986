import React, { Component } from 'react'
import axios from "axios";
import { API_BASE_URL } from "assets/constants/Constants";
import Spinner from 'components/Spinner';
import moment from 'moment';

export class CustomerViewPage extends Component {
    constructor(props) {
        super(props)
        const meterData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            meterData,
            meterStatus:null,
            invoiceList: []
        }
    }
    
    async componentDidMount() {
        const siteData = await this.getSiteList();
        const meterData = await this.getMeterList();
        const meterResult = await axios(`${API_BASE_URL}/meter/serialNo/${this.state.meterData.serial_number}`); 
        this.getInvoiceList();
        this.setState({
            siteList: siteData.data.result,
            meterList: meterData.data.result,
            meterStatus:meterResult.data.result[0].status,
            isLoading: false
        });
    }
    
    getInvoiceList = () => {
        axios(`${API_BASE_URL}/invoice/consumer/${this.state.meterData.consumer_no}`)
        .then((response) => {
            if(response.data.result.result.length > 0){
                this.setState({
                    invoiceList: response.data.result.result
                });
            }
        })
        .catch((error) => {
            this.setState({
                invoiceList: []
            });
        });
    }

    getMeterList = async () => {
        return await axios(`${API_BASE_URL}/meter/${this.state.meterData._id}`);
    }

    getSiteList = async () => {
        return await axios(`${API_BASE_URL}/site/${sessionStorage.getItem("siteId")}`);
    }

    previewInvoice = (invoiceItem) => {
        this.props.history.push({
            pathname: "/billing-invoice",
            state: {...invoiceItem, pageName: "Customer View", pageLink: "/customer-view-page", pageData: this.state.meterData}
        });
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="customer-page">Customer</a></li>
                            <li>Customer View</li>
                        </ul>
                    </div>
                    <div className="reading-info-outer">
                        <div className="reading-wrapper">
                            <div className="site-wrapper">
                                <div className="row">
                                    <div className="site-info">
                                        <div className="site-content">
                                            <label>Site Name: </label>
                                            <label className="ml-3">{this.state.siteList.site_name }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Site Address: </label>
                                            <label className="ml-3">{ `${this.state.siteList.street}, ${this.state.siteList.city}, ${this.state.siteList.pincode}` }</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="customer-wrapper">
                                <div className="row">
                                    <div className="cust-info">
                                        <div className="site-content">
                                            <label>Meter Serial Number: </label>
                                            <label className="ml-3">{ this.state.meterData.serial_number }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="site-info">
                                        <div className="content">
                                            <label>Meter Status: </label>
                                            <label className="ml-3">{ this.state.meterStatus}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                        {
                            <table className="table list-table">
                                <thead>
                                    <tr>
                                        <th className="pl1">Customer Name</th>
                                        <th> Consumer Number</th>
                                        <th>Assigned Meter</th>
                                        <th> Address 1</th>
                                        <th> Address 2</th>
                                        <th> Street</th>
                                        <th>City</th>
                                        <th> Pincode</th>
                                        <th> Mobile</th>
                                    </tr>
                                </thead>
                                <tbody className="reading-list-table">
                                {
                                    <tr >
                                        <td className="customer-name-td pl1" > { this.state.meterData.customer_name } </td>
                                        <td className="customer-consumer-td" >{ this.state.meterData.consumer_no != null ? this.state.meterData.consumer_no: "none"  }</td>
                                        <td className="customer-serial-td"> { this.state.meterData.serial_number } </td>
                                        <td className="customer-address1-td" > { this.state.meterData.customer_address1} </td>
                                        <td className="customer-address2-td" > { this.state.meterData.customer_address2} </td>
                                        <td className="customer-street-td" > { this.state.meterData.street} </td>
                                        <td className="customer-city-td" > { this.state.meterData.city} </td>
                                        <td className="customer-pincode-td" > { this.state.meterData.pin_code } </td>
                                        <td className="customer-mobile-td" > { this.state.meterData.mobile_no} </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                    {
                        this.state.invoiceList.length > 0 && (
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                {
                                    <table className="table list-table">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Invoive Number</th>
                                                <th>Consumer Number</th>
                                                <th>Bill Date</th>
                                                <th>Bill Start Date</th>
                                                <th>Bill End Date</th>
                                                <th>Serial Number</th>
                                                <th>Unit Consume</th>
                                                <th>Total Bill</th>
                                            </tr>
                                        </thead>
                                        <tbody className="reading-list-table">
                                            {
                                                this.state.invoiceList.map((invoiceItem) => {
                                                    return(
                                                        <tr onClick={() => this.previewInvoice(invoiceItem)}>
                                                            <td className="customer-name-td pl1" > {invoiceItem.invoice_no} </td>
                                                            <td className="customer-consumer-td" >{invoiceItem.consumer_no}</td>
                                                            <td className="customer-address1-td" > { moment(invoiceItem.bill_date).format("Do MMM, yyyy")} </td>
                                                            <td className="customer-address2-td" > {moment(invoiceItem.start_date).format("Do MMM, yyyy")} </td>
                                                            <td className="customer-address2-td" > {moment(invoiceItem.end_date).format("Do MMM, yyyy")} </td>
                                                            <td className="customer-serial-td"> {invoiceItem.serial_number} </td>
                                                            <td className="customer-address2-td" > {invoiceItem.unit_consume} </td>
                                                            <td className="customer-street-td" > {invoiceItem.net_bill_amount.toFixed(2)} </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        )
                    }
                    
                </div>
            )
        }
    }
}

export default CustomerViewPage
