import React, { useEffect, useState } from 'react'
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function PDFGenerator(props) {
    const [invoiceFileName, setInvoiceFileName] = useState("");

    useEffect(() => {
        setInvoiceFileName(`${props.children.props.data.serial_number}_${props.children.props.data.invoice_no}`)
    }, [props.children.props.data.serial_number, props.children.props.data.invoice_no]);

    const generatePDF = () => {
        exportPDF("print-content", `Invoice_${invoiceFileName}.pdf`);
    };

    const exportPDF = (elementId, fileName) => {
        let dpi = 72;
        let a4 = {
            width: 8.2677,
            height: 11.6929,
        };

        var html = document.getElementById(elementId);

        var pdf = new jsPDF("p", "mm", "a4");
        var pageWidth = pdf.internal.pageSize.getWidth();
        var pageHeight = pdf.internal.pageSize.getHeight();
        const pixelHeight = Math.ceil(dpi * a4.height);
        const totalPages = Math.ceil(html.clientHeight / pixelHeight);

        for (var i = 0; i < totalPages; i++) {
            const pageCount = i;

            html2canvas(html, {
                logging: false,
                y: pageCount * pixelHeight,
                height: pixelHeight,
                scale: 1,
                dpi: 300
            }).then((canvas) => {
                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                // const canvasHeight = canvas.height * ratio;

                const marginX = (pageWidth - canvasWidth) / 2;
                // const marginY = (pageHeight - canvasHeight) / 2;
                const marginY = 0;

                addPage(canvas, pdf, pageCount, totalPages, marginX, marginY);
                if (pageCount === totalPages - 1) pdf.save(fileName);
            });
        }
    }

    const addPage = (canvas, pdf, pageCount, totalPages, marginX, marginY) => {
        const imageObj = { type: 'jpeg', quality: 0.98 };
        const image = canvas.toDataURL("image/"+imageObj.type, imageObj.quality);
        pdf.addImage(image, "JPEG", marginX, marginY);

        if (pageCount < totalPages - 1) {
            pdf.addPage();
        }
    }

    return (
        <div>
            {/* width: "0px", height: "0px", overflow: "hidden", */}
            <div style={{  width: "0px", height: "0px", overflow: "hidden", position: "fixed", top: "0", left: "0" }}>
                <div id="print-content" style={{ width: "fit-content", height: "fit-content", marginTop: "30px", paddingTop: "50px" }} >
                    {props.children}
                </div>
            </div>
            <button className="form-control button button-primary mt-1 md-3 print-pdf-btn" style={{ padding: "8px 20ox 8px 20px" }} onClick={generatePDF}>
                Print PDF
            </button>
        </div>
    );
}

export default PDFGenerator