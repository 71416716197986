import React from "react"
import MapComponent from 'components/MapComponent';
import axios from 'axios';
import { API_BASE_URL } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';

class NearByMeter extends React.PureComponent {
    constructor(props) {
        super(props)

        const editData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            isMapLoad: false,
            isMarkerShown: false,
            meterList: [],
            selectedMarker: null,
            toggleInfoBox: false,
            routeId: editData === null ? null : editData.route_id,
            routeName: editData === null ? null : editData.route_name,
        }
    }
    
    componentWillUnmount() {
        this.setState({
            isMapLoad: false,
            isMarkerShown: false,
            meterList: [],
            selectedMarker: null,
            toggleInfoBox: false
        })
    }

    async componentDidMount() {
        const meterData = await this.getAllMeters();
        let center_location_cordinates= {
            "lng": parseFloat(meterData.data.result[0].gps_longitude),
            "lat": parseFloat(meterData.data.result[0].gps_latitude)
        };
        this.setState({
            meterList: meterData.data.result,
            isMapLoad: true,
            center_location_cordinates:center_location_cordinates,
            isLoading: false
        });
    }
    
    getAllMeters = async () => {
        return await axios(`${API_BASE_URL}/meter/mapView/${this.state.routeId}`);
    }

    handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
    }

    handleMarkerHover = (event) => {
        console.log(event);
    }

    handleClick = (marker, event) => {
        console.log(marker);
        this.setState(prevState =>{
            return{
                selectedMarker: !prevState.toggleInfoBox ? marker : null,
                toggleInfoBox: !prevState.toggleInfoBox
            }
        })
    }

    infoBoxCliked = (item) => {
        if(window.confirm("Are you sure you want see details of selected meter ?")){
            if(item){
                const rowItem = {
                    _id:item._id,
                    site_id:item.site_id,
                    serial_number:item.serial_number,
                    meter_type:item.meter_type,
                    unit:item.unit,
                    amr_channel: item.amr_channel,
                    status:item.status,
                    consumer_number:item.consumer_number,
                    customer_name: item.customer_name,
                    customer_address1:item.customer_address1,
                    customer_address2: item.customer_address2,
                    meter_assign_to_cust: item.meter_assign_to_cust,
                    route_name:this.state.routeName,
                    pin_code: item.pin_code,
                    street: item.street,
                    city: item.city,
                }
                this.props.history.push({
                    pathname: "/meter-view-page",
                    state: rowItem
                });
            }
            else{
                this.props.history.push({
                    pathname: "/meter-view-page",
                    state: null
                });
            }
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            if(this.state.isMapLoad){
                return (
                    <div className="main-content">
                        <MapComponent
                            selectedMarker={this.state.selectedMarker}
                            isMarkerShown={this.state.isMarkerShown}
                            infoBoxCliked={this.infoBoxCliked}
                            onClick={this.handleClick}
                            meterList={this.state.meterList}
                            parent={this}
                            centerLocationCoordinates={this.state.center_location_cordinates}
                        />
                    </div>
                  )
            }
            else{
                return <></>
            }
        }
    }
}

export default NearByMeter
