import React, { Component } from 'react'
import CryptoJS from "crypto-js";
import { API_BASE_URL, SECRET_KEY, emailPattern, TYPE_SUCCESS, INTERVAL  } from "assets/constants/Constants";
import ShowToast from 'components/ShowToast';
import axios from "axios";
import md5 from 'md5';

export class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            emailId: "",
            password: "",
            error: {},
        }
    }
    
    emailIdChangeHandler = (event) => {
        this.setState({
            emailId: event.target.value
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        
        if(this.state.emailId){
            if (!emailPattern.test(this.state.emailId)) {
                isValid = false;
                error.emailId = "Please enter valid email address.";
            }   
        }
        if(!this.state.emailId){
            isValid = false;
            error.emailId = "Please enter email id";
        }

        this.setState({
            error
        });
        return isValid;
    }

    recoverPasswordHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({isLoading: true,})
            var buf = new Uint8Array(6);
            window.crypto.getRandomValues(buf);
            let password = window.btoa(String.fromCharCode.apply(null, buf)); 
    
            const requestData = {
                userEmailId: this.state.emailId,
                userPassword:md5(password), 
                displayPass: CryptoJS.AES.encrypt(password, SECRET_KEY).toString(),
            }  
            ShowToast('Please wait while reset password', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            let userResponse = null;
            userResponse = await axios.post(`${API_BASE_URL}/user/forgetPassword/${this.state.emailId}/${this.state.userPassword}`, requestData);
            if(userResponse.status === 200){
            ShowToast('Password reset successfully !', TYPE_SUCCESS, INTERVAL, this.props, "/login-page"); 
            }
        }   
    }

    render() {
        return (
            <div className="password-wrapper">
                {
                    <div className="password-container">
                        <div className="input-password-div">
                            <label className="username-label"> Email Id</label>
                            <input className="username-input" type="text" value={ this.state.emailId } onChange={ this.emailIdChangeHandler} placeholder="Please Enter your Email" />
                            <div className="text-danger error-msg">
                                {this.state.error.emailId}
                            </div>
                        </div>
                        <div className="password-button-view">
                            <button className="recover-button" onClick={this.recoverPasswordHandler} >Recover My Password</button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ForgotPassword