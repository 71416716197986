import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, INTERVAL, TYPE_FAIL, SAMPLE_FILE_URL } from 'assets/constants/Constants';
import { EDIT_ICON, BOLT_ICON, USER_PLUS_ICON, SAFARI_ICON, GLOBE_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import { hasPermission }  from "components/Permission";
import ShowToast from 'components/ShowToast';
import AuthContext from "context/AuthProvider";
import { defaultFormatDate } from "lib/utils"
import MaterialTable from "material-table";
import CSVFileReader from 'components/CSVFileReader';
import FileDownloader from 'components/FileDownloader';

export class RoutePage extends Component {
    constructor(props) {
        super(props)

        const userRoleDetails = sessionStorage.getItem("userRole");
        this.state = {
            isLoading: true,
            isVisibleAddMeter: false,
            buttonText: "Add Route",
            selectedItem: null,
            totalCount: 1,
            userRoleDetails: userRoleDetails ? JSON.parse(userRoleDetails) : null,
            userSessionData: null,
            importList: [],
            roleList:[]
        }
    }

    async componentDidMount() {
        const userSessionData = await this.context.getUserDetails();
        const roleData = await this.getRoleList();
        console.log("roleData::",roleData)
        this.setState({
            userSessionData,
            routeList: this.props.routeList,
            totalCount: this.props.totalPageCount,
            roleList: roleData.data.result,
            isLoading: false,
            isVisibleAddMeter:false,
        });
    }

    getRoleList = async () => {
        return await axios(`${API_BASE_URL}/role/roledatabase/manager`);
    }

    handleInputTextChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
            input
        });
    }

    viewMeteronMapHandler = (item) => {
        if (item) {
            const rowItem = {
                route_id: item._id,
                route_name: item.route_name,
            }
            this.props.history.push({
                pathname: "/map-view",
                state: rowItem
            });
        }
    }

    addEditUserHandler = (item) => {
        if (item) {
            const rowItem = {
                _id: item._id,
                route_name: item.route_name,
                route_manager_id: item.route_manager_id,
                street: item.street,
                city: item.city,
                zone: item.zone,
                subzone: item.subzone,
                pin_code: item.pin_code,
                site_id: item.site_id,

            }
            this.props.history.push({
                pathname: "/create-route-page",
                state: rowItem
            });
        }
        else{
            this.props.history.push({
                pathname: "/create-route-page",
                state: null
            });
        }
    }

    handleActive = async (item) => {
        if(item.route_status === false)
        {
            if (window.confirm("Are you sure you want to activate this route ?")) {
                this.setState({isLoading: true}); 
                item.route_status= true;
                const requestData = {
                    routeStatus: item.route_status
                }
                ShowToast('Route activated successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                let routeResponse = null;
                routeResponse = await axios.put(`${API_BASE_URL}/routes/${item._id}`, requestData);
                console.log("Route Response is :",routeResponse);
                this.setState({
                    isLoading: false
                });
                window.location.reload();
            }
        }
        else{
            if (window.confirm("Are you sure you want to deactivate this route, if you deactivate then all meter will get unassigned from this route ?")) {
                this.setState({isLoading: true}); 
                item.route_status= false;
                let deleteResponse = await axios.delete(`${ API_BASE_URL }/route_meter_map/meters/${item._id}`);
                console.log("Meters removed from Route :",deleteResponse);
                ShowToast('Route deactivated successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                const requestData = {
                    routeStatus: item.route_status
                }
                let routeResponse = null;
                routeResponse = await axios.put(`${API_BASE_URL}/routes/${item._id}`, requestData);
                console.log("Route Response is :",routeResponse);
                this.setState({
                    isLoading: false
                });
                window.location.reload();
            }
        }
    }

    showMeterReadingHandler = (item) => {
        if (item) {
            const rowItem = {
                _id: item._id,
                site_id: item.site_id,
                route_name: item.route_name,
                street: item.street,
                city: item.city,
                pin_code: item.pin_code,
            }

            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: {rowItem, isVisibleAddMeter:true, pageName:"Route Master", pathName:"master-route-page"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: null
            });
        }
    }

    showMeterReadingHandler1 = (item) => {
        if (item) {
            const rowItem = {
                _id: item._id,
                site_id: item.site_id,
                route_name: item.route_name,
                street: item.street,
                city: item.city,
                pin_code: item.pin_code,
            }

            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: {rowItem, isVisibleAddMeter:false, pageName:"Route Transaction", pathName:"route-transaction-page"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: null
            });
        }
    }

    showMeterReadingHandler2 = (item) => {
        if (item) {
            const rowItem = {
                _id: item._id,
                site_id: item.site_id,
                route_name: item.route_name,
                street: item.street,
                city: item.city,
                pin_code: item.pin_code,
            }

            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: {rowItem, isVisibleAddMeter:false, pageName:"Route Collection History", pathName:"route-history"}
            });
        }
        else{
            this.props.history.push({
                pathname: "/route-meter-mapping",
                state: null
            });
        }
    }

    routeAssignemtPage = (item) => {
        if (item) {
            const rowItem = {
                _id: item._id,
                site_id: item.site_id,
                route_name: item.route_name,
                street: item.street,
                city: item.city,
                pin_code: item.pin_code,
                route_status: item.route_status,
                start_date: item.start_date,
                end_date:item.end_date
            }

            this.props.history.push({
                pathname: "/route-assignement-page",
                state: rowItem
            });
        }
        else{
            this.props.history.push({
                pathname: "/route-assignement-page",
                state: null
            });
        }
    }

    handleFile = (fileData) => {
        if (fileData[0].errors.length === 0) {
            const importList = [...this.state.importList];
            for (let index = 1; index <= fileData.length - 1; index++) {
                if(fileData[index].data[0] && fileData[index].data[1] && fileData[index].data[2] && fileData[index].data[3] && fileData[index].data[6]){
                    importList.push({
                        route_name: fileData[index].data[0],
                        route_manager_id: fileData[index].data[1],
                        street: fileData[index].data[2],
                        city: fileData[index].data[3],
                        zone: fileData[index].data[4],
                        subzone: fileData[index].data[5],
                        pin_code: fileData[index].data[6],
                        site_id: sessionStorage.getItem("siteId")
                    });
                }
            }
            this.setState({
                importList
            }, () =>{
                console.log("import List:",this.state.importList);
            }) 
        }
        else {
            ShowToast('Something went wrong. Please try after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
        
        }
    };

    importRouteHandler = async (event) => {
        let requestParameter= null;
        event.preventDefault();
        this.setState ({isLoading: true,});

        const sitePromise = this.state.importList.map(async(item, index) => {
            return new Promise( async (resolve) => {
                requestParameter = {
                    routeManagerId: this.renderRouteManager(item.route_manager_id),
                    siteId: item.site_id,
                    routeName: item.route_name,
                    routeStreet: item.street,
                    routeCity: item.city,
                    routePinCode: item.pin_code,
                    routeZone: item.zone,
                    routeSubzone:item.subzone,
                }
                const routeResponse = await axios.post(`${API_BASE_URL}/routes`, requestParameter);
                resolve(routeResponse);
            })
        });
        
        const routeResponse = await Promise.all(sitePromise);
        if(routeResponse.length > 0 && routeResponse.length === this.state.importList.length){
            ShowToast('Route created successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            const routeData = await axios(`${API_BASE_URL}/routes/site/${sessionStorage.getItem("siteId")}`);
            this.setState({
                routeList: routeData.data.result,
                importList: [],
                isLoading: false
            });
        }
        window.location.reload();
    }

    cancelHandler = (event) => {
        window.location.reload();
    }

    renderRouteManager = (item) => {
        let routeManager = [];
        this.state.roleList.forEach((roleItem) => {
            if (roleItem.first_name === item) {
                routeManager.push(roleItem._id);
            }
        });
        return routeManager.join(", ");
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner/>
            )
        }
        else{
            if(this.state.importList.length === 0){
                return (
                    <>
                        <div>
                            <div className="back">
                                <ul className="breadcrumb1">
                                    {
                                       this.props.model === "routeTransaction" ? (
                                            <li>Route Transaction</li>
                                        ):(
                                            this.props.model === "masterRoute" ? (
                                                <li>Route Master</li>
                                            ):(
                                                <li>Route collection History</li>
                                            )
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="route-button-div">
                            {
                                hasPermission("route", "create") && this.props.model === "masterRoute" && (
                                    <>
                                    <button className="brand-button" onClick={() => this.state.selectedItem === null ? this.addEditUserHandler(null) : this.addEditUserHandler(this.state.selectedItem)}>{ this.state.buttonText }</button>
                                    <CSVFileReader handleFile={this.handleFile} />
                                    <label className="csv-link-label" onClick={() =>{FileDownloader(SAMPLE_FILE_URL+ "route.csv" , "route.csv")}}>
                                        Sample Import CSV
                                    </label>
                                    </>
                                )
                            }
                            </div>
                            <div className="border list-table-div" style={{marginBottom:50}}>
                            {
                                hasPermission("route", "read") ? (
                                this.props.routeList.length > 0 ? (
                                    
                                    <MaterialTable
                                    columns={
                                        this.props.model !== "routeTransaction" ? ([
                                            { title: "Route Name", field: "route_name1", 
                                            cellStyle: {cellWidth: '18%', fontWeight:'bolder', color:'#000000', fontSize:14 },
                                            },
                                            { title: "Route Manager", field: "route_manager", 
                                            cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14 }
                                            },
                                            { title: "Street", field: "routeStreet", 
                                            cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14}
                                            },
                                            { title: "City", field: "city", 
                                            cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 }
                                            },
                                            { title: "Pin Code", field: "pin_code", 
                                            cellStyle: { cellWidth: '12%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "No of Meters", field: "meter_count", 
                                            cellStyle: {cellWidth: '14%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Last Updated Date", field: "last_updatedDate", 
                                            cellStyle: {cellWidth: '14%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Last Assigned Reader", field: "last_assigned_reader", 
                                            cellStyle: {cellWidth: '14%', color:'#808080', fontSize:14} 
                                            },
                                            { title: "Status", field: "status", 
                                            cellStyle: {cellWidth: '14%', color:'#808080', fontSize:14 } 
                                            }
                                        ]):([
                                            { title: "Route Name", field: "route_name1", 
                                            cellStyle: {cellWidth: '10%', fontWeight:'bolder', color:'#000000', fontSize:14 }
                                            },
                                            { title: "Route Manager", field: "route_manager", 
                                            cellStyle: { cellWidth: '10%', color:'#808080', fontSize:14 }
                                            },
                                            { title: "Street", field: "routeStreet", 
                                            cellStyle: {cellWidth: '5%', color:'#808080', fontSize:14}
                                            },
                                            { title: "City", field: "city", 
                                            cellStyle: {cellWidth: '5%', color:'#808080', fontSize:14 }
                                            },
                                            { title: "Pin Code", field: "pin_code", 
                                            cellStyle: { cellWidth: '10%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "No of Meters", field: "meter_count", 
                                            cellStyle: {cellWidth: '5%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Last Updated Date", field: "last_updatedDate", 
                                            cellStyle: {cellWidth: '10%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Last Assigned Reader", field: "last_assigned_reader", 
                                            cellStyle: {cellWidth: '10%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Status", field: "route_transaction_status", 
                                            cellStyle: {cellWidth: '10%', color:'#808080', fontSize:14 } 
                                            },
                                            { title: "Updated By", field: "route_updated_by", 
                                            cellStyle: {cellWidth: '10%', color:'#808080', fontSize:14 } 
                                            }
                                        ])
                                    }
                                    data={
                                        this.props.model !== "routeTransaction" ? (
                                            this.props.routeList.map((item, index) => {
                                                return (
                                                    {
                                                        _id: item._id,
                                                        route_name1: item.route_name,
                                                        route_manager: item.route_manager,
                                                        routeStreet: item.street,
                                                        city: item.city,
                                                        pin_code: item.pin_code,
                                                        meter_count: item.meter_count,
                                                        last_updatedDate: defaultFormatDate(item.last_updatedDate!=null ? item.last_updatedDate :item.updated_date_time),
                                                        last_assigned_reader:item.last_assigned_reader != null ? item.last_assigned_reader.join(", ") : "none",
                                                        status: item.route_status !== false ? "Active" : "Inactive",
                                                        site_id: item.site_id,
                                                        zone: item.zone,
                                                        subzone: item.subzone,
                                                        route_manager_id: item.route_manager_id,
                                                        meter_id:item.meter_id,
                                                        route_status:item.route_status,
                                                        street:item.street,
                                                        route_name: item.route_name,
                                                    }
                                                )
                                            })
                                        ):(
                                            this.props.routeList.map((item, index) => {
                                                return (
                                                    {
                                                        _id: item._id,
                                                        route_name1: item.route_name,
                                                        route_manager: item.route_manager,
                                                        routeStreet: item.street,
                                                        city: item.city,
                                                        pin_code: item.pin_code,
                                                        meter_count: item.meter_count,
                                                        last_updatedDate: defaultFormatDate(item.last_updatedDate!=null ? item.last_updatedDate :item.updated_date_time),
                                                        last_assigned_reader:item.last_assigned_reader != null ? item.last_assigned_reader.join(", ") : "none",
                                                        status: item.route_status !== false ? "Active" : "Inactive",
                                                        site_id: item.site_id,
                                                        zone: item.zone,
                                                        subzone: item.subzone,
                                                        route_manager_id: item.route_manager_id,
                                                        meter_id:item.meter_id,
                                                        route_status:item.route_status,
                                                        route_transaction_status: item.route_transaction_status != null ? item.route_transaction_status : "Not Completed",
                                                        route_updated_by:item.route_updated_by != null ? item.route_updated_by : "None",
                                                        street:item.street,
                                                        route_name: item.route_name,
                                                        start_date : item.start_date,
                                                        end_date:item.end_date
                                                    }
                                                )
                                            })
                                        )
                                    }
    
                                    actions={ 
                                        this.props.model === "masterRoute" ? ([
                                            {
                                                icon: () => <i className={hasPermission("route", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit Route"></i>,
                                                onClick: (event, rowData) =>
                                                    hasPermission("route", "update") ? this.addEditUserHandler(rowData) : ""
                                            },
                                            {
                                                icon: () => <i className={hasPermission("route", "view_meter") ? `${SAFARI_ICON}` : "not-active"} title="View Meter List"></i>,
                                                onClick: (event, rowData) =>{
                                                    rowData.route_status === true && hasPermission("route", "view_meter") ? (
                                                       this.showMeterReadingHandler(rowData)
                                                    ):(
                                                        ShowToast(' Route has deactivated, please activate first', TYPE_SUCCESS, INTERVAL, this.props, "")
                                                    )
                                                }
                                            },
                                            (rowData) => {
                                                return{
                                                    icon: () => <i className={hasPermission("route", "update") ? (rowData.route_status ? `${BOLT_ICON}` : `${BOLT_ICON} gray-icon`) : "not-active"} title="Active/Deactive" ></i>,
                                                    onClick: (event, rowData) => hasPermission("route", "update") ? this.handleActive(rowData) : "",
                                                }
                                            },
                                            {
                                                icon: () => <i className={hasPermission("route", "view_map_on_meter") ? `${GLOBE_ICON}` : "not-active"} title="View meter on map"></i>,
                                                onClick: (event, rowData) =>
                                                    hasPermission("route", "view_map_on_meter") ? this.viewMeteronMapHandler(rowData) : ""
                                            }
                                        ]):(
                                            this.props.model === "routeTransaction" ? ([
                                                {
                                                    icon: () => <i className={hasPermission("route", "assign_reader") ? `${USER_PLUS_ICON}` : "not-active"} title="Assign Reader"></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("route", "assign_reader") ? this.routeAssignemtPage(rowData) : "",
                                                },
                                                {
                                                    icon: () => <i className={hasPermission("route", "view_meter") ? `${SAFARI_ICON}` : "not-active"} title="View Meter List" ></i>,
                                                    onClick: (event, rowData) =>
                                                        hasPermission("route", "view_meter") ? this.showMeterReadingHandler1(rowData) : ""
                                                },
                                            ]):(
                                                this.props.model === "routeHistory" && ([
                                                    {
                                                        icon: () => <i className={hasPermission("route", "view_meter") ? `${SAFARI_ICON}` : "not-active"} title="View Meter List"></i>,
                                                        onClick: (event, rowData) =>
                                                            hasPermission("route", "view_meter") ? this.showMeterReadingHandler2(rowData) : ""
                                                    },
                                                ])
                                            )
                                        )
                                    }
                                    options={{
                                        actionsColumnIndex: -1,
                                        pageSize:25,
                                        pageSizeOptions:[25,50,75,100],
                                        headerStyle: {
                                            color: '#999998',
                                            fontWeight: 'bolder',
                                            fontSize: 15,
                                        },
                                        filtering: true,
                                        sorting: true,
                                        columnsButton: true,
                                        exportButton: true,
                                        exportAllData: true, 
                                        exportFileName: "Route Data"
                                    }}
                                />
                                ):(
                                    <div className="text-center pt-2">
                                        <label>Route records not found!</label>
                                    </div>
                                )
                                ):(
                                    <div className="text-center pt-2">
                                        <label>You dont have permission to see list</label>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                    <div className="route-button-div">
                        {
                            hasPermission("route", "create") && (
                                <>
                                <button className="brand-button" onClick={() => this.state.selectedItem === null ? this.addEditUserHandler(null) : this.addEditUserHandler(this.state.selectedItem)}>{ this.state.buttonText }</button>
                                <CSVFileReader handleFile={this.handleFile} />
                                </>
                            )
                        }
                        </div>
                    <div className="mt-4 border list-table-div">
                    <MaterialTable
                        title = {<div className="csv-title">IMPORTED ROUTE DATA</div>}
                        columns={
                                [{ title: "Route Name", field: "RouteName", 
                                cellStyle: {cellWidth: '18%', fontWeight:'bolder', color:'#000000', fontSize:14 },
                                },
                                { title: "Route Manager", field: "RouteManager", 
                                cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14 }
                                },
                                { title: "Street", field: "routeStreet", 
                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14}
                                },
                                { title: "City", field: "routeCity", 
                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 }
                                },
                                { title: "Zone", field: "routeZone", 
                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 }
                                },
                                { title: "Subzone", field: "routeSubzone", 
                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 }
                                },
                                { title: "Pin Code", field: "routePincode", 
                                cellStyle: { cellWidth: '12%', color:'#808080', fontSize:14 } 
                                }]
                        }
                        data={
                                this.state.importList.map((item, index) => {
                                    return (
                                        {
                                            RouteName: item.route_name,
                                            RouteManager: item.route_manager_id,
                                            routeStreet: item.street,
                                            routeCity: item.city,
                                            routeZone: item.zone,
                                            routeSubzone: item.subzone,
                                            routePincode: item.pin_code,
                                        }
                                    )
                                })
                        }

                        options={{
                            headerStyle: {
                                color: '#999998',
                                fontWeight: 'bolder',
                                fontSize: 15,

                            },
                        }}
                    />
                    </div>
                    <div className="site-button-div" style={{marginTop: 30, marginBottom:50, marginLeft:17}}>
                        <button className="brand-button" onClick={ this.importRouteHandler }>Submit</button>
                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                    </div>
                    </>
                )
            }
        }
    }
}

RoutePage.contextType = AuthContext;
export default RoutePage