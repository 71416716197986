import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import MeterListTable from 'views/report/ReportComponent/MeterListTable';
import RouteListTable from 'views/report/ReportComponent/RouteListTable';
import CustomerListTable from 'views/report/ReportComponent/CustomerListTable';
import UserListTable from 'views/report/ReportComponent/UserListTable';

export class ReportMasterPage extends Component {
    constructor(props) {
        super(props)
        let siteId = sessionStorage.getItem("siteId");
        this.state = {
            isLoading: true,
            buttonFlag: false,
            resultList: [],
            meterList: [],
            routeList: [],
            routeAssignedList: [],
            allMeterList: [],
            meterAssignList: [],
            roleList: [],
            siteList: [],
            choice: null,
            meterStatus: null,
            assignRoute: null,
            choiceList: [
                { value: "Master Meter", label: "Master Meter" },
                { value: "Master Route", label: "Master Route" },
                { value: "User", label: "User" },
                { value: "Customer", label: "Customer" },
            ],
            meterStatusList: [
                { value: "All", label: "All" },
                { value: "active", label: "Active" },
                { value: "inactive", label: "Inactive" },
                { value: "faulty", label: "Faulty" },
            ],
            routeFromList: [
                { value: "All", label: "All" },
                { value: "Assigned", label: "Assigned" },
                { value: "Not Assigned", label: "Not Assigned" },
            ],
            error: {},
            siteId: siteId
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: false,
        });

    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        
        if(!this.state.choice) {
            isValid = false;
            error.type = "Please select type";
        }
        if(this.state.choice === "Master Meter" && !this.state.meterStatus) {
            isValid = false;
            error.meterStatus = "Please select meter status";
        }
        if(this.state.choice === "Master Route" && !this.state.assignRoute) {
            isValid = false;
            error.routeStatus = "Please select route status"
        }
        this.setState({
            error
        });

        return isValid;
    }

    createReportHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()) {
            this.setState({ isLoading: true, buttonFlag: true });
            let routeResponse = null;
            if (this.state.choice === "Master Meter") {
                let assignData = await axios(`${API_BASE_URL}/route_meter_map/meter/notinroute/${sessionStorage.getItem("siteId")}`);
                if (this.state.meterStatus === "All") {
                    routeResponse = await axios(`${API_BASE_URL}/meter/site/${this.state.siteId}`);
                }
                else {
                    routeResponse = await axios(`${API_BASE_URL}/meter/site/${this.state.siteId}/meterStatus/${this.state.meterStatus}`);
                }
                this.setState({
                    meterAssignList: assignData.data.result,
                });
            }
            else if (this.state.choice === "Master Route") {
                if (this.state.assignRoute === "Not Assigned") {
                    routeResponse = await axios(`${API_BASE_URL}/route_assignement/route/notassign/site/${this.state.siteId}/false`);
                }
                else if (this.state.assignRoute === "Assigned") {
                    routeResponse = await axios(`${API_BASE_URL}/route_assignement/site/${this.state.siteId}`);
                }
                else if (this.state.assignRoute === "All") {
                    routeResponse = await axios(`${API_BASE_URL}/routes/site/${this.state.siteId}`);
                }
            }
            else if (this.state.choice === "Customer") {
                routeResponse = await axios(`${API_BASE_URL}/customer/site/${this.state.siteId}`);
            }
            else if (this.state.choice === "User") {
                routeResponse = await axios(`${API_BASE_URL}/user/site/${this.state.siteId}`);
                let siteData = await axios(`${API_BASE_URL}/site`);
                let roleData = await axios(`${API_BASE_URL}/role`);

                this.setState({
                    siteList: siteData.data.result,
                    roleList: roleData.data.result
                });
            }
            this.setState({
                resultList: routeResponse.data.result,
                isLoading: false
            }, () => {
                if (this.state.resultList.length > 0) {
                    var element = document.getElementById("test-table-xls-button");
                    element.classList.toggle("mystyle");
                    ShowToast('Report created successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                }
                else {
                    ShowToast('Report does not exist', TYPE_FAIL, INTERVAL, this.props, "");
                }
            });
        }
    }

    renderChoiceOptions = () => {
        if (this.state.choiceList.length > 0) {
            return this.state.choiceList.map((meterItem, index) => {
                let isSelected = false;
                if (meterItem.value === this.state.choice) {
                    isSelected = true;
                }
                return (
                    <option value={meterItem.value} selected={isSelected} key={meterItem.value} className="option-text-color"> {meterItem.label} </option>
                )
            });
        }
        else {
            return "<option> No Data Found</option>"
        }
    }

    renderMeterStatusOptions = () => {
        if (this.state.meterStatusList.length > 0) {
            return this.state.meterStatusList.map((meterItem, index) => {
                let isSelected = false;
                if (meterItem.value === this.state.meterStatus) {
                    isSelected = true;
                }
                return (
                    <option value={meterItem.value} selected={isSelected} key={meterItem.value} className="option-text-color"> {meterItem.label} </option>
                )
            });
        }
        else {
            return "<option> No Data Found</option>"
        }
    }

    renderRouteOptions = () => {
        if (this.state.routeFromList.length > 0) {
            return this.state.routeFromList.map((meterItem, index) => {
                let isSelected = false;
                if (meterItem.value === this.state.assignRoute) {
                    isSelected = true;
                }
                return (
                    <option value={meterItem.value} selected={isSelected} key={meterItem.value} className="option-text-color"> {meterItem.label} </option>
                )
            });
        }
        else {
            return "<option> No Data Found</option>"
        }
    }

    choiceChangeHandler = (event) => {
        this.setState({
            choice: event.target.value,
            buttonFlag: false,
            assignRoute: null,
            meterStatus: null
        });
    }

    meterStatusHandler = (event) => {
        this.setState({
            meterStatus: event.target.value,
            assignRoute: null
        });
    }

    routeHandler = (event) => {
        this.setState({
            assignRoute: event.target.value,
            meterStatus: null
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li>Report Master</li>
                        </ul>
                    </div>
                    <div className="list-wrapper">
                        <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                            <div className="row">
                                <div className="addUser">
                                    <label className="form-label">Select type <span className="text-danger">*</span></label>
                                    <select className="form-control brand-font p-3 placeholderColor add-meter-input" onChange={this.choiceChangeHandler} placeholder="Select" >
                                        <option selected value="0" disabled >Select</option>
                                        {
                                            this.renderChoiceOptions()
                                        }
                                    </select>
                                    <div className="text-danger error-msg">
                                        {this.state.error.type}
                                    </div>
                                </div>
                            </div>
                            {this.state.choice === "Master Meter" && (
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">Select Meter Status <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-user-input" onChange={this.meterStatusHandler} placeholder="Select Meter Status" >
                                            <option selected value="0" disabled >Select</option>
                                            {
                                                this.renderMeterStatusOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.meterStatus}
                                        </div>
                                    </div>
                                </div>)}
                            {this.state.choice === "Master Route" && (
                                <div className="row">
                                    <div className="addUser">
                                        <label className="form-label">Select Route Status <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-user-input" onChange={this.routeHandler} placeholder="Select" >
                                            <option selected value="0" disabled >Select</option>
                                            {
                                                this.renderRouteOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.routeStatus}
                                        </div>
                                    </div>
                                </div>)}
                            <div className="addButton">
                                <button id="button" className="brand-button report-button" onClick={this.createReportHandler}>Get Report</button>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button brandxls-button download-button"
                                    table="table-to-xls"
                                    filename={this.state.choice}
                                    sheet="tablexls"
                                    buttonText="Download as XLS" />
                            </div>
                        </div>
                        {
                            this.state.buttonFlag === true && (
                                this.state.resultList.length > 0 ? (
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                        {
                                            this.state.choice === "Master Meter" && (
                                                <MeterListTable meterList={this.state.resultList} meterAssignList={this.state.meterAssignList} />
                                            )
                                        }
                                        {
                                            this.state.choice === "Customer" && (
                                                <CustomerListTable customerList={this.state.resultList} />
                                            )
                                        }
                                        {
                                            this.state.choice === "User" && (
                                                <UserListTable userList={this.state.resultList} siteList={this.state.siteList} roleList={this.state.roleList} />
                                            )
                                        }
                                        {
                                            (this.state.assignRoute === "Not Assigned" || this.state.assignRoute === "Assigned" || this.state.assignRoute === "All") && (
                                                <RouteListTable routeList={this.state.resultList} />
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                        <div className="text-center pt-2"> No Records Found!</div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            )
        }
    }
}

export default ReportMasterPage
