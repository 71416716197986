import React, { Component } from 'react'
import { SORT_DOWN, SORT_UP } from "assets/constants/Icons";
import { onSortColumn } from "lib/utils"
import classnames from "classnames";
import { defaultFormatDate } from "lib/utils"
import MaterialTable from "material-table";

export class ReadingDifferenceTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sortingArrow: { serialNumber: classnames(SORT_DOWN, "fa-lg"), previousReading: classnames(SORT_DOWN, "fa-lg"), currentReading: classnames(SORT_DOWN, "fa-lg"), difference: classnames(SORT_DOWN, "fa-lg") },
        }
    }

    onSort = (column, className, stateName) => {
        let sortedData;
        let columnIconClass = {};
        if (className === SORT_DOWN + " fa-lg") {
            sortedData = onSortColumn(this.props.meterList, column, "asc");
            columnIconClass = this.state.sortingArrow;
            columnIconClass[stateName] = classnames(SORT_UP, "fa-lg");
        }
        else {
            sortedData = onSortColumn(this.props.meterList, column, "desc");
            columnIconClass = this.state.sortingArrow;
            columnIconClass[stateName] = classnames(SORT_DOWN, "fa-lg");
        }
        this.setState({
            campaigns: sortedData,
            sortingArrow: columnIconClass,
        });
    };

    render() {
        return (
            <MaterialTable
                columns={[
                    { title: "Serial Number", field: "serialNumber", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Meter Type", field: "meterType", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Unit", field: "unit", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Meter AMR Channel", field: "meterAMRChannel", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Meter Status", field: "meterStatus", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Previous Reading Date", field: "previousReadingDate", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Previous Reading", field: "previousReading", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Current Reading Date", field: "currentReadingDate", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Current Reading", field: "currentReading", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    { title: "Difference", field: "difference", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                ]}
                data={
                    this.props.meterList.map((readingItem) => {
                        let meterStatusClass = "text-success";
                        if (readingItem.status === "active") {
                            meterStatusClass = "text-success";
                        }
                        else if (readingItem.status === "faulty") {
                            meterStatusClass = "text-warning";
                        }
                        else if (readingItem.status === "Damage") {
                            meterStatusClass = "text-danger";
                        }
                        else {
                            meterStatusClass = "text-success";
                        }
                        return (
                            {
                                serialNumber: readingItem.serial_number,
                                meterType: readingItem.meter_type,
                                unit: readingItem.unit !== null ? (readingItem.unit === "0" || "m3" ? "m3" : (readingItem.unit === "1" || "L" ? "L" : (readingItem.unit === "2" || "GAL" ? "GAL" : (readingItem.unit === "3" || "ft3" ? "ft3" : "NA")))) : "NA",
                                meterAMRChannel: readingItem.amr_channel,
                                meterStatus: <span className={`${meterStatusClass}`}>{readingItem.status}</span>,
                                previousReadingDate: readingItem.previous_reading_date != null ? defaultFormatDate(readingItem.previous_reading_date) : 0,
                                previousReading: readingItem.previous_reading != null ? readingItem.previous_reading : 0,
                                currentReadingDate: readingItem.current_reading_date != null ? defaultFormatDate(readingItem.current_reading_date) : 0,
                                currentReading: readingItem.current_reading != null ? readingItem.current_reading : 0,
                                difference: (readingItem.previous_reading != null && readingItem.current_reading != null) ? readingItem.difference.toFixed(3) : 0,
                            }
                        );
                    })
                }
                options={{
                    rowStyle: (rowData, index) => ({
                        backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                    }),
                    sorting: true,
                    headerStyle: {
                        color: "black",
                        fontSize: 15,
                        fontWeight: 'bolder'
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    columnsButton: true,
                }}
            />
        )
    }
}

export default ReadingDifferenceTable
