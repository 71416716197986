
import axios from 'axios';
import { API_BASE_URL } from 'assets/constants/Constants';

export const getPermissionMatrix = async () => {
    const permissionMatrix = await axios.get(`${API_BASE_URL}/permissionmatrix`);
    sessionStorage.setItem("userRolePermission", JSON.stringify(permissionMatrix.data.result));
}

export const hasPermission = (resource, action) => {
    let permissionValue = false;
    const userRole = JSON.parse(sessionStorage.getItem("userRole"));
    const userRolePermission = JSON.parse(sessionStorage.getItem("userRolePermission"));
    if(userRolePermission){
        userRolePermission[0].roles.forEach((roleItem) => {
            if(roleItem.role_name.toLowerCase() === userRole.user_role.replace(" ", "").toLowerCase()){
                permissionValue = roleItem.permission.modules[resource][action]
            }
        })
    }
    return permissionValue;
}

export const hasRoutePermission = (route) => {
    let permissionValue = false;
    const userRole = JSON.parse(sessionStorage.getItem("userRole"));
    const userRolePermission = JSON.parse(sessionStorage.getItem("userRolePermission"));
    if(userRolePermission){
        userRolePermission[0].roles.forEach((roleItem) => {
            if(roleItem.role_name.toLowerCase() === userRole.user_role.replace(" ", "").toLowerCase()){
                permissionValue = roleItem.permission.routes.find(routeItem => routeItem === route);
            }
        })
    }
    return permissionValue;
}
