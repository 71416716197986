import React, { Component } from 'react'
import RoutePage from 'views/routes/RoutePage';
import Spinner from 'components/Spinner';
import { API_BASE_URL, READER } from 'assets/constants/Constants';
import AuthContext from "context/AuthProvider";
import axios from 'axios';

export class RouteTransactionPage extends Component {
    constructor(props) {
        super(props)

        const userRoleDetails = sessionStorage.getItem("userRole");
        this.state = {
            isLoading: true,
            buttonText: "Add Route",
            routeList: [],
            routeList1: [],
            selectedItem: null,
            currentPage: 1,
            totalCount: 1,
            userRoleDetails: userRoleDetails ? JSON.parse(userRoleDetails) : null,
            userSessionData: null
        }
    }
    async componentDidMount() {
        const userSessionData = await this.context.getUserDetails();
        const routeData = await this.getRouteList(userSessionData._id);
        this.setState({
            userSessionData,
            routeList1: routeData.data.result,
            totalCount: routeData.data.totalCount,
            isLoading: false,
        });

        this.state.routeList1.map((item,index) => {
            if(item.route_status === true){
                this.state.routeList.push(item);
            }
            return this.state.routeList;
        });
    }

    getRouteList = async (userId) => {
        if(this.state.userRoleDetails && this.state.userRoleDetails.user_role === READER ){
            return await axios(`${API_BASE_URL}/route_assignement/routeassign/site/${sessionStorage.getItem("siteId")}/user/${userId}?limit=${this.state.pageSize}&page=${this.state.currentPage}`);
        }
        else{
            return await axios(`${API_BASE_URL}/billing/route_data/${sessionStorage.getItem("siteId")}`);
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner/>
            )
        }
        else{
            return (
                <div className="main-content">
                    <RoutePage routeList = {this.state.routeList} totalPageCount = {this.state.totalCount} model = "routeTransaction" {...this.props}/>
                </div>
            )
        }
    }
}
RouteTransactionPage.contextType = AuthContext;
export default RouteTransactionPage