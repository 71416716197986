import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import "react-datepicker/dist/react-datepicker.css";
import RoutePage from 'views/routes/RoutePage';
import { hasPermission }  from "components/Permission";

export class RouteHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            routeList: [],
        } 
    }

    async componentDidMount() {
        const routeData = await this.getRouteData();
        this.setState({
            routeList:routeData.data.result,
            totalCount: routeData.data.totalCount,
            isLoading: false,
        });
    }

    getRouteData = async () => {
        return await axios(`${API_BASE_URL}/routes/complete/site/${sessionStorage.getItem("siteId")}`);
    }
    
    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    {
                        hasPermission("route", "update") ? (
                            <RoutePage routeList = {this.state.routeList} totalPageCount = {this.state.totalCount} model = "routeHistory" {...this.props}/>
                        ):(
                            <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div text-center pt-2">
                                <label>You dont have permission to see list</label>
                            </div>
                        )
                    }
                </div>
            )
        }
    }
}
export default RouteHistory
