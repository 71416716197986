import React, { Component } from 'react';
import {TYPE_FAIL, INTERVAL} from 'assets/constants/Constants';
import {CAMERA_ICON} from 'assets/constants/Icons';
import ShowToast from 'components/ShowToast';
import { defaultFormatDateTime } from "lib/utils"
import MaterialTable from "material-table";

export class ReadingListTable extends Component {
    constructor (props) {
        super (props)

        this.state = {
            viewReadingImage: false,
            readingImageSource: ""
        }
    }

    viewImage = (readingImage) => {
        if (readingImage !== "" && readingImage !== undefined) {
            this.setState({
                viewReadingImage: true,
                readingImageSource: readingImage
            });
        }
        else {
            ShowToast("Image not uploaded yet.", TYPE_FAIL, INTERVAL, null, "");
        }
    }

    render() {
        console.log("!this.props.pageName::",this.props.pageName)
        return (
            <div>
                <MaterialTable
                    columns={
                        [
                        { title: "Serial Number", field: "serialNumber", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Web Receive Time", field: "webReceiveTime", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Route", field: "route", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13  } },
                        { title: this.props.pageName === "AMI Readings" ? "Gateway" : "Aqualink", field: "gateway",cellStyle:{ cellWidth: '14% !important', color: '#000000', fontSize: 13 }},
                        { title: "Reader", field: "reader", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13  }},
                        { title: "Unit", field: "unit", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Meter Status", field: "meterStatus", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Alarm Type", field: "alarmType", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Meter Time", field: "meterTime", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Readings", field: "readings", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "Photo", field: "photo", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 }, },
                        { title: "RSSI", field: "rssi", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                        { title: "SSR", field: "ssr", cellStyle: { cellWidth: '14% !important', color: '#000000', fontSize: 13 } },
                    ]
                }
                    data={

                        this.props.readingList.map((readingItem) => {
                            let meterStatusClass = "unread";
                            if (readingItem.meter_status === "Manual") {
                                meterStatusClass = "manual";
                            }
                            else if (readingItem.meter_status === "Auto+Alarm") {
                                meterStatusClass = "alarm";
                            }
                            else if (readingItem.meter_status === "Auto Read") {
                                meterStatusClass = "read";
                            }
                            return (
                                {
                                    serialNumber: readingItem.meter_serial_no,
                                    webReceiveTime: defaultFormatDateTime(readingItem.created_date_time),
                                    route: !this.props.pageName && (readingItem.route_name),
                                    gateway: readingItem.gway_device_id,
                                    reader: !this.props.pageName && (readingItem.assigned_user_id),
                                    unit: readingItem.unit !== null ? (readingItem.unit === "0" || "m3" ? "m3" : (readingItem.unit === "1" || "L" ? "L" : (readingItem.unit === "2" || "GAL" ? "GAL" : (readingItem.unit === "3" || "ft3" ? "ft3"  : "NA")))) : "NA",
                                    meterStatus: <span className={` ${meterStatusClass}`}>{readingItem.meter_status}</span>,
                                    alarmType: readingItem.alarm_type ? readingItem.alarm_type : "None",
                                    meterTime: readingItem.receive_date_time,
                                    readings: readingItem.meter_reading,
                                    photo: <i className={(readingItem.reading_image !== "" && readingItem.reading_image !== undefined) ? `${CAMERA_ICON} camera-icon` : `${CAMERA_ICON} gray-icon`} title="Reading Image" onClick={() => this.viewImage(readingItem.reading_image)} />,
                                    rssi: readingItem.field_strength_rssi !== undefined ? readingItem.field_strength_rssi : "NA",
                                    ssr: readingItem.snr !== undefined ? readingItem.snr : "NA",
                                }
                            );
                        })
                    }
                    options={{
                        rowStyle: (rowData, index) => ({
                            backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                        }),
                        sorting: true,
                        headerStyle: {
                            color: "black",
                            fontSize: 15,
                            fontWeight: 'bolder'
                        },
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50, 100],
                        columnsButton: true,
                    }}
                />
                { this.state.viewReadingImage && (
                    <div className="custom_modal">
                        <div className="modal_body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className="modal_title pagetitle">View Meter Reading Image</h4>
                                </div>
                                <div className="col-md-2">
                                    <button type="button" className="close text-right" style={{ fontSize: 20 }} onClick={(e) => this.setState({ viewReadingImage: false })}>&times;</button>
                                </div>
                                {(this.state.readingImageSource.includes("png") || this.state.readingImageSource.includes("jpg") || this.state.readingImageSource.includes("jpeg")) ? (
                                    <embed src={this.state.readingImageSource} title="photo" width="100%" height="auto" />
                                ) : (
                                    <embed src={this.state.readingImageSource} title="photo" width="100%" height="auto" style={{ height: '400px' }} />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default ReadingListTable
