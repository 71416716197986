// // export const API_BASE_URL = "http://127.0.0.1:8000";
//export const API_BASE_URL = "http://52.34.26.10:8000";
// // export const API_BASE_URL = "http://192.168.0.249:8000";

// const getApiBaseUrl = () => {
//     const hostname = window.location.hostname;
//     console.log("hostname::", hostname)
//     switch (hostname) {
//         //case '127.0.0.1':
//         case 'localhost':
//             console.log("Matched localhost");
//             return 'http://127.0.0.1:8000';
//         case 'test-sbem-webapp.s3-website-us-east-1.amazonaws.com':
//             console.log("Matched test-sbem-webapp");
//             return 'http://52.34.26.10:8000';
//         case 'prod-sbem-webapp.s3-website-us-east-1.amazonaws.com':
//             console.log("Matched prod-sbem-webapp");
//             return 'http://34.210.253.28:8000';
//         case 'https://portal.sbemsolutions.com':
//             console.log("Matched portal.sbemsolutions.com");
//             return 'http://34.210.253.28:8000';
//         default:
//             console.log("Unknown hostname:", hostname);
//             throw new Error(`Unknown hostname: ${hostname}`);
//     }
// };

//export const API_BASE_URL = getApiBaseUrl();REACT_APP_API_BASE_URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const WEB_APP_URL = process.env.REACT_APP_WEB_URL;
export const SECRET_KEY = "ROCK-N-ROLL";
export const PAGE_SIZE = 25;
export const TYPE_SUCCESS = "success";
export const TYPE_INFO = "info";
export const TYPE_FAIL = "fail";
export const READER = "reader";
export const ADMIN = "admin";
export const SUPER_ADMIN = "super admin";
export const CONFIG_SERVICE = "config_service";

export const INTERVAL = 3000;
export const SAMPLE_FILE_URL = "https://test-sbem-webapp.s3.amazonaws.com/sample-csv/";

export const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
export const PIN_CODE_PATTERN = new RegExp(
    /^[1-9][0-9]{5}$/
);
export const PHONE_NUMBER_PATTERN = new RegExp(
    /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/
);
export const passwordMatchPattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
export const GOOGLE_MAP_API_KEY = "AIzaSyBjfzI2CKzGEPYy558uhoxzcN23uiTrTP4";