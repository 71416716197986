import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, SECRET_KEY, TYPE_SUCCESS, TYPE_FAIL, INTERVAL, SAMPLE_FILE_URL, ADMIN, SUPER_ADMIN } from 'assets/constants/Constants';
import { EDIT_ICON, TRASH_ICON, CHECK_ICON, CLOSE_ICON } from "assets/constants/Icons";
import Spinner from 'components/Spinner';
import { hasPermission } from "components/Permission";
import ShowToast from 'components/ShowToast';
import MaterialTable from "material-table";
import CSVFileReader from 'components/CSVFileReader';
import md5 from 'md5';
import CryptoJS from "crypto-js";
import FileDownloader from 'components/FileDownloader';

export class UserPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isUsernamePresent:false,
            input: {
                userFirstName: "",
                userLastName: "",
                userEmailId: "",
                userPassword: "",
                userRole: ""
            },
            buttonText: "Add User",
            userList: [],
            siteList: [],
            roleList: [],
            importList:[]
        }
    }

    async componentDidMount() {
        const userData = await this.getAllUsers();
        const siteData = await this.getAllSites();
        const roleData = await this.getRole();
        hasPermission("user", "read");
        this.setState({
            userList: userData.data.result,
            totalCount: userData.data.totalCount,
            siteList: siteData.data.result,
            roleList: roleData.data.result,
            isLoading: false
        })
    }

    getAllSites = async () => {
        return await axios(`${API_BASE_URL}/site`);
    }

    getAllUsers = async () => {
        return await axios(`${API_BASE_URL}/user/site/${sessionStorage.getItem("siteId")}/?limit=${this.state.pageSize}&page=${this.state.currentPage}`);
    }

    getRole = async () => {
        return await axios(`${API_BASE_URL}/role`);
    }

    handleInputTextChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        this.setState({
            input
        });
    }

    addEditUserHandler = (item) => {
        if (item) {
            const rowItem = {
                _id: item.userId,
                first_name: item.user.split(" ")[0],
                last_name: item.user.split(" ")[1],
                email_id: item.emailId,
                phone_number: item.phoneNumber,
                username: item.username,
                password: item.password,
                user_type: item.user_type,
                status: item.status,
                role: item.roleId,
                site_id:item.siteId
            }
            if((item.userRole === ADMIN) || (item.userRole === SUPER_ADMIN) ){
                hasPermission("user","admin_profile") ? (
                    this.props.history.push({
                        pathname: "/create-user-page",
                        state: { ...rowItem, isFromProfile: false }
                    })
                ):(
                    ShowToast('You dont have permission.', TYPE_FAIL, INTERVAL, this.props, "")
                ) 
            }
            else{
                this.props.history.push({
                    pathname: "/create-user-page",
                    state: { ...rowItem, isFromProfile: false }
                });
            }
        }
        else {
            this.props.history.push({
                pathname: "/create-user-page",
                state: null
            });
        }
    }

    handleDeleteUser = async (item) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            this.setState({ isLoading: true });
            let deleteResponse = await axios.delete(`${API_BASE_URL}/user/${item}`);
            if (deleteResponse.status === 200) {
                let fetchResponse = await this.getAllUsers();
                this.setState({
                    userList: fetchResponse.data.result,
                    isLoading: false
                });
                ShowToast('User deleted successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
            }
            else {
                ShowToast('Something went wrong. Please delete again after sometime!', TYPE_FAIL, INTERVAL, this.props, "");
            }
        }
    }

    handleDelete = async (item) => {
        if((item.userRole === ADMIN) || (item.userRole === SUPER_ADMIN) ){
            hasPermission("user","admin_profile") ? (
                this.handleDeleteUser(item.userId)
            ):(
                ShowToast('You dont have permission.', TYPE_FAIL, INTERVAL, this.props, "")
            )
        }
        else{
            this.handleDeleteUser(item.userId)
        }
    }

    renderSiteNames = (item) => {
        let siteNamesArray = [];
        this.state.siteList.forEach((siteItem) => {
            item.forEach((userSiteitem) => {
                if (siteItem._id === userSiteitem) {
                    siteNamesArray.push(siteItem.site_name);
                }
            });
        });
        return siteNamesArray.join(", ");
    }

    renderRole = (item) => {
        let roleNameArray = [];
        this.state.roleList.forEach((roleItem) => {
            if (roleItem._id === item) {
                roleNameArray.push(roleItem.user_role);
            }
        });
        return roleNameArray.join(", ");
    }

    renderUserRole = (item) => {
        let roleNameArray = [];
        this.state.roleList.forEach((roleItem) => {
            if (roleItem.user_role === item) {
                roleNameArray.push(roleItem._id);
            }
        });
        return roleNameArray.join(", ");
    }

    handleFile = async (fileData) => {
        if (fileData[0].errors.length === 0) {
            const importPromise = fileData.map( async (importItem, index) => {
                if(index !== 0) {
                    const innerPromiseResult = await new Promise(async (resolve) => {
                        const userData = await axios(`${API_BASE_URL}/user/findUserName/${importItem.data[4]}`)
                        if(fileData[index].data[0] && fileData[index].data[2] && fileData[index].data[3] && fileData[index].data[4] && fileData[index].data[5] && fileData[index].data[6] && fileData[index].data[7] && fileData[index].data[8]){
                            resolve({
                                first_name: importItem.data[0],
                                last_name: importItem.data[1],
                                email_id: importItem.data[2],
                                phone_number: importItem.data[3],
                                user_name: importItem.data[4],
                                password: importItem.data[5],
                                user_type: importItem.data[6],
                                user_status: importItem.data[7],
                                user_role: importItem.data[8],
                                site_id: sessionStorage.getItem("siteId"),
                                is_username_present: userData.data.result.length > 0 ? true : false 
                            });
                        }
                        else{
                            resolve(null);
                        }
                    });
                    return innerPromiseResult;
                }
            });

            let importResult = await Promise.all(importPromise);
            importResult =importResult.filter((mapItem) => mapItem !== null);
            importResult = importResult.filter((item) => item);
            if(importResult.length > 0){
                this.setState({
                    importList: [...importResult]
                }) 
            }
        }
        else {
            ShowToast('Something went wrong. Please try after sometime!', TYPE_FAIL, INTERVAL, this.props, ""); 
        }
    };

    isFormValid = () => {
        let isValid = true;

        this.state.importList.filter((item, index) => {
            if(item.is_username_present === true){
                isValid = false;
            }
            return isValid;
        });
       
        if(isValid === false){
            ShowToast('Username already present. Please provide different username and resubmit.', TYPE_FAIL, INTERVAL, this.props, ""); 
            this.setState({
                importList: this.state.importList,
                isLoading: false
            });
        }
        return isValid;
    }

    importUserHandler = async (event) => {
        let requestParameter= null;
        event.preventDefault();
        this.setState ({isLoading: true,});
        if(this.isFormValid()){
            const sitePromise = this.state.importList.map(async(item, index) => {
                    return new Promise( async (resolve) => {
                        requestParameter = {
                        siteId: item.site_id,
                        userFirstName: item.first_name,
                        userLastName: item.last_name,
                        userEmailId: item.email_id,
                        userPhoneNumber: item.phone_number,
                        userName: item.user_name,
                        userPassword: md5(item.password),
                        displayPass: CryptoJS.AES.encrypt(item.password, SECRET_KEY).toString(),
                        userType: item.user_type,
                        userStatus: item.user_status,
                        userRole: this.renderUserRole(item.user_role),
                        }
        
                        const userResponse = await axios.post(`${API_BASE_URL}/user`, requestParameter);
                    
                        resolve(userResponse);
                    })
            });
            
            const userResponse = await Promise.all(sitePromise);
            if(userResponse.length > 0 && userResponse.length === this.state.importList.length){
                ShowToast('User created successfully', TYPE_SUCCESS, INTERVAL, this.props, ""); 
                const userData = await this.getAllUsers();
                this.setState({
                    userList: userData.data.result,
                    importList: [],
                    isLoading: false
                });
            }
        }
    }

    cancelHandler = (event) => {
        window.location.reload();
    }

    renderUserName= (item) => {
        return ((item.is_username_present === false) ? <span className="iconGreenColor">{item.user_name + "   "}<i className={`${CHECK_ICON} iconGreenColor`} aria-hidden="true"></i></span> : <span className="iconRedColor">{item.user_name + "   "}<i className={`${CLOSE_ICON} iconRedColor`} aria-hidden="true"></i></span>);
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            if(this.state.importList.length === 0){
                return (
                    <div className="main-content">
                        <div className="list-wrapper">
                            <div className="back">
                                <ul className="breadcrumb1">
                                    <li>User Master</li>
                                </ul>
                            </div>
                            <div className="user-button-view">
                                {
                                    hasPermission("user", "create") && (
                                        <>
                                        <button className="brand-button" onClick={() => this.addEditUserHandler(null)}>{this.state.buttonText}</button>
                                        <CSVFileReader handleFile={this.handleFile} />
                                        <label className="csv-link-label" onClick={() =>{FileDownloader(SAMPLE_FILE_URL+ "user.csv" , "user.csv")}}>
                                            Sample Import CSV
                                        </label>
                                        </>
                                    )
                                }
                            </div>
                            <div className="mt-4 border list-table-div site-table" style={{marginBottom:50}}>
                                {
                                    hasPermission("user", "read") ? (
                                        this.state.userList.length > 0 ? (
                                            <MaterialTable
                                                columns={[
                                                    { title: "User", field: "user", 
                                                    cellStyle: {cellWidth: '18%', fontWeight:'bolder', color:'#000000', fontSize:14 }
                                                    },
                                                    { title: "Email Id", field: "emailId", 
                                                    cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14, textTransform:'none' },
                                                    sorting:false 
                                                    },
                                                    { title: "Phone Number", field: "phoneNumber", 
                                                    cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 },
                                                    sorting: false
                                                    },
                                                    { title: "Username", field: "username", 
                                                    cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14, textTransform:'none' }
                                                    },
                                                    { title: "Site", field: "site", 
                                                    cellStyle: { cellWidth: '12%', color:'#808080', fontSize:14 } 
                                                    },
                                                    { title: "Role", field: "userRole", 
                                                    cellStyle: {cellWidth: '14%', color:'#808080', fontSize:14 } 
                                                    },
                                                ]}
                                                data={
                                                    this.state.userList.map((item, index) => {
                                                        return (
                                                            {
                                                                user: `${item.first_name} ${item.last_name}`,
                                                                emailId: item.email_id,
                                                                phoneNumber: item.phone_number,
                                                                username: item.username,
                                                                site: this.renderSiteNames(item.site_id),
                                                                userRole: this.renderRole(item.role),
                                                                status: item.status,
                                                                user_type: item.user_type,
                                                                password: item.password,
                                                                confirmPassword: item.password,
                                                                userId: item._id,
                                                                siteId:item.site_id,
                                                                roleId:item.role
                                                            }
                                                        )
                                                    })
                                                }
        
                                                actions={[
                                                    {
                                                        icon: () => <i className={hasPermission("user", "update") ? `${EDIT_ICON}` : "not-active"} title="Edit User"></i>,
                                                        onClick: (event, rowData) =>
                                                            hasPermission("user", "update") ? this.addEditUserHandler(rowData) : ""
                                                    },
                                                    {
                                                        icon: () => <i className={hasPermission("user", "delete") ? `${TRASH_ICON}` : "not-active"} title="Delete User"></i>,
                                                        onClick: (event, rowData) =>
                                                            hasPermission("user", "delete") ? this.handleDelete(rowData) : ""
                                                    }
                                                ]}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    pageSize:25,
                                                    pageSizeOptions:[25,50,75,100],
                                                    headerStyle: {
                                                        color: '#999998',
                                                        fontWeight: 'bolder',
                                                        fontSize: 15
                                                    },
                                                    filtering: true,
                                                    sorting: true,
                                                    columnsButton: true,
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    exportFileName: "User Data"
                                                }}
                                            />
                                ) : (
                                <div className="text-center pt-2">
                                    <label>User records not found!</label>
                                </div>
                                )
                                ) : (
                                <div className="text-center pt-2">
                                    <label>You dont have permission to see list</label>
                                </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            else{
                return(
                    <>
                        <div className="user-button-view">
                            {
                                hasPermission("user", "create") && (
                                    <>
                                    <button className="brand-button" onClick={() => this.addEditUserHandler(null)}>{this.state.buttonText}</button>
                                    <CSVFileReader handleFile={this.handleFile} />
                                    </>
                                )
                            }
                        </div>
                        <div className="mt-4 border list-table-div site-table">
                        <MaterialTable
                            title = {<div className="csv-title">IMPORTED USER DATA</div>}
                            columns={[
                                { title: "User", field: "user", 
                                cellStyle: {cellWidth: '18%', fontWeight:'bolder', color:'#000000', fontSize:14 }
                                },
                                { title: "Email Id", field: "emailId", 
                                cellStyle: { cellWidth: '20%', color:'#808080', fontSize:14 },
                                sorting:false 
                                },
                                { title: "Phone Number", field: "phoneNumber", 
                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 },
                                sorting: false
                                },
                                { title: "Username", field: "username", 
                                cellStyle: {cellWidth: '12%', color:'#808080', fontSize:14 }
                                },
                                { title: "Role", field: "userRole", 
                                cellStyle: {cellWidth: '14%', color:'#808080', fontSize:14 } 
                                },
                            ]}
                            data={
                                this.state.importList.map((item, index) => {
                                    return (
                                        {
                                            user: `${item.first_name} ${item.last_name}`,
                                            emailId: item.email_id,
                                            phoneNumber: item.phone_number,
                                            username: this.renderUserName(item),
                                            userRole: item.user_role,
                                            roleId:item.user_role
                                        }
                                    )
                                })
                            }
                            options={{
                                headerStyle: {
                                    color: '#999998',
                                    fontWeight: 'bolder',
                                    fontSize: 15
                                },
                            }}
                        />
                        </div>
                        <div className="site-button-div" style={{marginTop: 30, marginBottom:50, marginLeft:17}}>
                            <button className="brand-button" onClick={ this.importUserHandler }>Submit</button>
                            <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                        </div>
                    </>
                )
            }
        }
    }
}

export default UserPage
