import React, { Component } from 'react';
import axios from "axios";
import { USER_ICON } from "assets/constants/Icons";
import { API_BASE_URL, SECRET_KEY,PHONE_NUMBER_PATTERN, emailPattern, passwordMatchPattern, TYPE_SUCCESS,TYPE_FAIL, TYPE_INFO, INTERVAL, SUPER_ADMIN, ADMIN } from "assets/constants/Constants";
import md5 from 'md5';
import Spinner from 'components/Spinner';
import CryptoJS from "crypto-js";
import ShowToast from 'components/ShowToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export class CreateUserPage extends Component {
    constructor(props) {
        super(props)
        
        const editData = this.props.history.location.state;
        this.state = {
            isLoading: true,
            isUsernamePresent:false,
            userId: editData === null ? null : editData._id,
            siteId: editData === null ? sessionStorage.getItem("siteId")  : editData.site_id,
            firstName: editData === null ? "" : editData.first_name,
            lastName: editData === null ? "" : editData.last_name,
            emailId: editData === null ? "" : editData.email_id,
            phoneNumber: editData === null ? "" : editData.phone_number,
            userName: editData === null ? "" : editData.username,
            password: editData === null ? "" : editData.password,
            confirmPassword: editData === null ? "" : editData.password,
            userType: editData === null ? "" : editData.user_type,
            userStatus: editData === null ? "" : editData.status,
            userRole: editData === null ? "" : editData.role,
            isFromProfile: editData === null ? "" : editData.isFromProfile,
            siteList: [],
            roleList: [],
            userList:[],
            error: {},
            isPasswordDisable: editData === null ? false : true,
            userStatusList: ["Active", "Inactive"],
            userTypeList: ["SBEM", "Contractor"],
            showPassword: false,
        }
    }
    
    async componentDidMount() {
        const roleData = await axios(`${API_BASE_URL}/role`);
        this.setState({
            roleList: roleData.data.result,
            isLoading: false,
        });
    }

    firstNameChangeHandler = (event) => {
        this.setState({
            firstName: event.target.value
        });
    }

    lastNameChangeHandler = (event) => {
        this.setState({
            lastName: event.target.value
        });
    }

    emailIdChangeHandler = (event) => {
        this.setState({
            emailId: event.target.value
        });
    }

    phoneNumberChangeHandler = (event) => {
        this.setState({
            phoneNumber: event.target.value
        });
    }

    userNameChangeHandler = (event) => {
        this.setState({
            userName: event.target.value
        });
    }

    usernameUniqueHandler = async (event) => {
        if (event.target.value) {
            const userData = await axios(`${API_BASE_URL}/user/findUserName/${event.target.value.trim().toLowerCase()}`);
            if (this.state.userId === null) {
                this.setState({
                    isUsernamePresent: userData.data.result.length > 0 ? true : false
                });

                if (userData.data.result.length > 0) {
                    ShowToast('Username already present, please try with different username!', TYPE_FAIL, INTERVAL, this.props, "");
                }
            }
        }
    }

    passwordChangehandler = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    confirmPasswordChangehandler = (event) => {
        this.setState({
            confirmPassword: event.target.value
        });
    }

    userTypeChaneHandler = (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState({
            userType: event.nativeEvent.target[index].text
        })
    }

    userStatusChaneHandler = (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState({
            userStatus: event.nativeEvent.target[index].text
        })
    }

    userRoleChaneHandler = (event) => {
        this.setState({
            userRole: event.target.value
        })
    }

    renderRoleOptions = () => {
        if(this.state.roleList.length > 0){
            return this.state.roleList.map((roleItem, index) => {
                let isSelected = false;
                if(roleItem._id === this.state.userRole){
                    isSelected = true;
                }
                return(
                    <option value={ roleItem._id } selected = { isSelected } key= { roleItem._id } className = "option-text-color"> { roleItem.user_role } </option>
                )
            });
        }
        else{
            return "<option> No Data Found</option>"
        }
    }

    renderStatusOptions = () => {
        if(this.state.userStatusList.length > 0){
            return this.state.userStatusList.map((statusItem) => {
                let isSelected = false;
                if(statusItem === this.state.userStatus){
                    isSelected = true;
                }
                return(
                    <option selected= { isSelected }>{ statusItem }</option>
                );
            });
        }
    }

    renderUserTypeOptions = () => {
        if(this.state.userTypeList.length > 0){
            return this.state.userTypeList.map((userTypeItem) => {
                let isSelected = false;
                if(userTypeItem === this.state.userType){
                    isSelected = true;
                }
                return(
                    <option selected= { isSelected }>{ userTypeItem }</option>
                );
            });
        }
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        
        if(!this.state.firstName){
            isValid = false;
            error.firstName = "Please enter first name";
        }

        if(!this.state.lastName){
            isValid = false;
            error.lastName = "Please Enter last name";
        }

        if(!this.state.emailId){
            isValid = false;
            error.emailId = "Please enter email id";
        }

        if(this.state.emailId){
            if (!emailPattern.test(this.state.emailId)) {
                isValid = false;
                error.emailId = "Please enter valid email address.";
            }
        }

        if(!this.state.phoneNumber){
            isValid = false;
            error.phoneNumber = "Please enter phone number";
        }

        if(this.state.phoneNumber !== "undefined"){
            if (!PHONE_NUMBER_PATTERN.test(this.state.phoneNumber)) {
                isValid = false;
                error.phoneNumber = "Please enter valid phone number.";
            }
        }

        if(!this.state.userName){
            isValid = false;
            error.userName = "Please enter username";
        }

        if(!this.state.password){
            isValid = false;
            error.password = "Pleae enter password";
        }

        if(this.state.password){
            if(this.state.userId === null){
                if (!passwordMatchPattern.test(this.state.password)) {
                    isValid = false;
                    error.password = "Please enter valid password";
                    ShowToast('Password must be minimum eight characters including one uppercase letter,\n one special character and alphanumeric characters', TYPE_INFO, INTERVAL, this.props, ""); 
                }
            }
        }

        if(!this.state.confirmPassword){
            isValid = false;
            error.confirmPassword = "Please enter confirm password";
        }

        if(this.state.password !== this.state.confirmPassword){
            isValid = false;
            error.confirmPassword = "Entered password and confirm password does not match";
        }

        if(!this.state.userType){
            isValid = false;
            error.userType = "Please select user type";
        }

        if(!this.state.userStatus){
            isValid = false;
            error.userStatus = "Please select user status";
        }

        if(!this.state.userRole){
            isValid = false;
            error.userRole = "Please select user role";
        }

        if(this.state.userRole){
            let roleValue = null;
            const userRole = JSON.parse(sessionStorage.getItem("userRole"));
            this.state.roleList.forEach((item)=>{
                if(this.state.userRole === item._id){
                    roleValue = item.user_role;
                }
            })
            if(userRole.user_role === ADMIN){
                if((roleValue === ADMIN) || roleValue === SUPER_ADMIN) {
                    error.userRole = "Please select different user role";
                    ShowToast('You dont have permission to create Admin/Super Admin!', TYPE_FAIL, INTERVAL, this.props, "");
                }
            }
        }

        if(this.state.isUsernamePresent){
            if(this.state.userId === null){
                isValid = false;
                error.userName = "Please enter different username";
            }
        }
        this.setState({
            error
        });
        return isValid;
    }
    
    createUserHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({isLoading: true,})
            const requestData = {
                siteId: this.state.siteId,
                userFirstName: this.state.firstName,
                userLastName: this.state.lastName,
                userEmailId: this.state.emailId,
                userPhoneNumber: this.state.phoneNumber,
                userName: this.state.userName.trim().toLowerCase(),
                userPassword: this.state.userId === null ? md5(this.state.password) : (this.state.isFromProfile ? md5(this.state.password) : this.state.password),
                displayPass: CryptoJS.AES.encrypt(this.state.password, SECRET_KEY).toString(),
                userType: this.state.userType,
                userStatus: this.state.userStatus,
                userRole: this.state.userRole,
            }
            let userResponse = null;
            if(this.state.userId === null){
                userResponse = await axios.post(`${API_BASE_URL}/user`, requestData);
            }
            else{
                userResponse = await axios.put(`${API_BASE_URL}/user/${this.state.userId}`, requestData);
            }

            if(userResponse.status === 200){
                this.setState({
                    firstName: "",
                    lastName: "",
                    emailId: "",
                    phoneNumber: "",
                    userName: "",
                    password: "",
                    confirmPassword: "",
                    userType: "",
                    userStatus: "",
                    siteList: [],
                    error: {},
                    isLoading: false,
                }, () => {
                    if(this.state.userId === null){
                        ShowToast('User created Successfully', TYPE_SUCCESS, INTERVAL, this.props, "/user-page");
                    }
                    else{
                        ShowToast('User updated Successfully', TYPE_SUCCESS, INTERVAL, this.props, "/user-page"); 
                    }
                    this.props.history.push("/user-page");
                });
            }
        }
    }

    cancelHandler = () => {
        this.props.history.push("/user-page");
    }

    cancelResetHandler = async (event) => {
            event.preventDefault();
            this.setState({isLoading: true,isPasswordDisable: false,})
            var x = document.getElementById("mytext");
            if (x.innerHTML === "Change Password") {
                this.setState({
                    password: md5(this.state.password),
                    confirmPassword: md5(this.state.confirmPassword)
                });
                x.innerHTML = "Reset Password";
            }
                this.setState({
                    isLoading: false,
                    isPasswordDisable: true,
                });
    }

    resetPasswordHandler = async (event) => {
        event.preventDefault();
        var buf = new Uint8Array(6);
        window.crypto.getRandomValues(buf);
        let password = window.btoa(String.fromCharCode.apply(null, buf)); 
    
        const requestData = {
            userFirstName: this.state.firstName,
            userLastName: this.state.lastName,
            userEmailId: this.state.emailId,
            userName: this.state.userName,
            userPassword: md5(password), 
            displayPass: CryptoJS.AES.encrypt(password, SECRET_KEY).toString(),
        }  
        let userResponse = null;
        ShowToast('Please wait while Reset Password', TYPE_SUCCESS, INTERVAL, this.props, "");
        userResponse = await axios.post(`${API_BASE_URL}/user/resetPassword/${this.state.userId}/${this.state.userPassword}`, requestData);
        if(userResponse.status === 200){
            ShowToast('Password reset successfully!', TYPE_SUCCESS, INTERVAL, this.props, "");
            this.setState({
                isPasswordDisable: true,
            })
        } 
    }
    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };
    render() {
        const { showPassword } = this.state;
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li><a href="/user-page">User Master</a></li>
                            {
                                this.state.userId === null ? (
                                    <li>Add New User</li>
                                ) : (
                                    <li>Edit User</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="add-user-wrapper">
                        <div className="mx-auto text-left border create-user-div">
                            <form onSubmit={this.handleSubmit} className="pt-1">
                                <div className="user-icon-wrapper">
                                    <h4>{ this.state.userId === null ? "Add New User" : "Edit User" }</h4>
                                    <i className={ `${USER_ICON} list-table-icon` }></i>
                                </div>
                                
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">First Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.firstName } onChange={ this.firstNameChangeHandler } placeholder="Enter First Name" disabled = { this.state.isFromProfile }/>
                                        <div className="text-danger error-msg">
                                            {this.state.error.firstName}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.lastName } onChange={ this.lastNameChangeHandler } placeholder="Enter Last Name" disabled = { this.state.isFromProfile }/>
                                        <div className="text-danger error-msg">
                                            {this.state.error.lastName}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Email Id <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.emailId } onChange={ this.emailIdChangeHandler } placeholder="Enter Email Id" disabled = { this.state.isFromProfile } />
                                        <div className="text-danger error-msg">
                                            {this.state.error.emailId}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.phoneNumber } onChange={ this.phoneNumberChangeHandler } placeholder="Enter Phone Number"  maxLength="10" disabled = { this.state.isFromProfile }/>
                                        <div className="text-danger error-msg">
                                            {this.state.error.phoneNumber}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-3">
                                        <label className="form-label">User Name <span className="text-danger">*</span></label>
                                        <input className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.userName } onChange={ this.userNameChangeHandler } onBlur={ this.usernameUniqueHandler } placeholder="Enter User Name" disabled = { this.state.isFromProfile }/>
                                        <div className="text-danger error-msg">
                                            {this.state.error.userName}
                                        </div>
                                    </div>
                                    {
                                        this.state.isFromProfile === true && (
                                            <div className="input-label-group col-md-3">
                                            <label className="form-label">Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.password } onChange={ this.passwordChangehandler } placeholder="Enter Password"/>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.password}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.isFromProfile === false && (
                                            <div className="input-label-group col-md-3">
                                            <label className="form-label">Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.password } onChange={ this.passwordChangehandler } placeholder="Enter Password"  disabled = { this.state.isPasswordDisable }/>
                                            <div className="text-danger error-msg">
                                                {this.state.error.password}
                                            </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.userId === null && (
                                            <div className="input-label-group col-md-3">
                                                <label className="form-label">Password <span className="text-danger">*</span></label>
                                                <div className="password-input-container">
                                                    <input type={showPassword ? 'text' : 'password'} className="form-control brand-font p-3 placeholderColor add-user-input" value={this.state.password} onChange={this.passwordChangehandler} placeholder="Enter Password" />
                                                    <span className="password-icon" onClick={this.togglePasswordVisibility}>
                                                        <FontAwesomeIcon
                                                            icon={showPassword ? faEye : faEyeSlash} />
                                                    </span>
                                                </div>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.password}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.isFromProfile === true && (
                                            <div className="input-label-group col-md-3">
                                                <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.confirmPassword } onChange={ this.confirmPasswordChangehandler } placeholder="Enter Confirm Password"/>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.confirmPassword}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.isFromProfile === false && (
                                            <div className="input-label-group col-md-3">
                                                <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.confirmPassword } onChange={ this.confirmPasswordChangehandler } placeholder="Enter Confirm Password" disabled = { this.state.isPasswordDisable }/>
                                                <div className="text-danger error-msg">
                                                    {this.state.error.confirmPassword}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.userId === null && (
                                            <div className="input-label-group col-md-3">
                                                <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                                <input type="password" className="form-control brand-font p-3 placeholderColor add-user-input" value={ this.state.confirmPassword } onChange={ this.confirmPasswordChangehandler } placeholder="Enter Confirm Password" />
                                                <div className="text-danger error-msg">
                                                    {this.state.error.confirmPassword}
                                                </div>
                                            </div>
                                        )
                                    }
                                    { this.state.userId !== null && this.state.isFromProfile === false &&(
                                    <div className="input-label-group col-md-3">
                                    <button className="reset-button" id="mytext" onClick={this.resetPasswordHandler} >Reset Password</button>
                                    </div>)}
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">User Type <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-user-input" onChange={this.userTypeChaneHandler} placeholder="Select User Type" disabled = { this.state.isFromProfile } >
                                            <option>Select User Type</option>
                                            {
                                                this.renderUserTypeOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.userType}
                                        </div>
                                    </div>
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">User Status <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-user-input" onChange={this.userStatusChaneHandler} placeholder="Select User Status" disabled = { this.state.isFromProfile } >
                                            <option>Select User Status</option>
                                            {
                                                this.renderStatusOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.userStatus}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-label-group col-md-6">
                                        <label className="form-label">User Role <span className="text-danger">*</span></label>
                                        <select className="form-control brand-font p-3 placeholderColor add-user-input" onChange={this.userRoleChaneHandler} placeholder="Select User Status" disabled = { this.state.isFromProfile } >
                                            <option>Select User Role</option>
                                            {
                                                this.renderRoleOptions()
                                            }
                                        </select>
                                        <div className="text-danger error-msg">
                                            {this.state.error.userRole}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-6">
                                        <button className="brand-button" onClick={this.createUserHandler}>Submit</button>
                                        <button className="cancel_button ml-5" onClick={this.cancelHandler}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row dummy_row"></div>
                </div>
            )
        }
    }
}

export default CreateUserPage
