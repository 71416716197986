import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import ShowToast from 'components/ShowToast';

export class SearchTransactionIdPage extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            isLoading: true,
            isClick:false,
            error: {},
            routeList:[],
            siteList:[],
            readerList:[],
            managerList:[],
            readingList:[],
            meter_count:null
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: false,
        });

    }

    //1650261533248
    //1650262265630
    getTransactionData = async () => {
        return await axios(`${API_BASE_URL}/route_assignement/transaction_id/${this.state.transactionId}`);
    }

    transactionIDHandler = (event) => {
        this.setState({
           transactionId : event.target.value,
           isClick:false
        });
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};
        const number_pattern = new RegExp(/^[0-9]*$/);

        if (!this.state.transactionId) {
            isValid = false;
            error.transactionId = "Please enter transaction ID";
        }

        if(this.state.transactionId){
            if (!number_pattern.test(this.state.transactionId)) {
                isValid = false;
                error.transactionId = "Please enter valid transaction ID.";
            }
        }

        this.setState({
            error
        });
        
        return isValid;
    }

    searchTransactionIDHandler = async (event) => {
        event.preventDefault();
        if(this.isFormValid()){
            ShowToast('Please wait...Transaction ID is searching....!', TYPE_SUCCESS, INTERVAL, this.props, ""); 
            const transaction = await this.getTransactionData();
            if(transaction.data.result.length !== 0){
                this.setState({
                    routeList: transaction.data.result[0],
                    siteList: transaction.data.result[1],
                    readerList: transaction.data.result[2],
                    managerList: transaction.data.result[3],
                    readingList: transaction.data.result[4],
                    meter_count:transaction.data.result[5],
                    isLoading: false,
                    isClick:true
                });
            }
            else{
             ShowToast('Transaction ID is not found. Please try with different Transaction ID!', TYPE_FAIL, INTERVAL, this.props, ""); 
            }
        }
    }

    render() {
        if(this.state.isLoading){
            return (
                <Spinner />
            )
        }
        else{
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li>Search Transaction ID</li>
                        </ul>
                    </div>
                    <div className="list-wrapper">
                    <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                                <div className="row">
                                    <div className="addUser">
                                    <label className="form-label">Enter Transaction ID <span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholder-class" value = {this.state.transactionId} onChange = {this.transactionIDHandler} placeholder="Enter transaction ID." maxLength="13"/>
                                    <div className="text-danger error-msg">
                                        {this.state.error.transactionId}
                                    </div>
                                </div>  
                                </div>
                        <div className="addButton">
                            <button id="button" className="brand-button"  title= "Submit" onClick={this.searchTransactionIDHandler}>Submit</button>
                        </div>
                    </div>
                        {
                            this.state.isClick === true && (
                                <>
                                <div  className="pt-4 mt-4 mb-5 pb-4 border list-table-div site-table">
                                {
                                    this.state.routeList.length > 0 && (
                                        <>
                                        <table className="table list-table">
                                            <thead>
                                                <tr>
                                                    <th className="pl1">Route Name</th>
                                                    <th>Route Street</th>
                                                    <th>Route City</th>
                                                    <th>Route Pincode</th>
                                                    <th>Meter Count</th>
                                                </tr>
                                            </thead>
                                            <tbody className="site-list-table">
                                            {
                                                this.state.routeList.map((item, index) => {
                                                    return(
                                                        <>
                                                        <tr key={ item._id }>
                                                            <td className="site-name-td pl1" > { item.route_name } </td>
                                                            <td className="site-street-td" > { item.street } </td>
                                                            <td className="site-city-td" > { item.city } </td>
                                                            <td className="site-pincode-td" > { item.pin_code } </td>
                                                            <td className="site-pincode-td" > { this.state.meter_count } </td>
                                                        </tr>
                                                    </>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </>
                                    )
                                }
                                </div>

                                <div  className="pt-4 mt-4 mb-5 pb-4 border list-table-div site-table">
                                {
                                    this.state.siteList.length > 0 && (
                                        <>
                                        <table className="table list-table">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Site Name</th>
                                                <th>Site Street</th>
                                                <th>Site City</th>
                                                <th>Site Pincode</th>
                                            </tr>
                                        </thead>
                                        <tbody className="site-list-table">
                                        {
                                            this.state.siteList.map((item, index) => {
                                                return(
                                                    <tr key={ item._id }>
                                                        <td className="site-name-td pl1" > { item.site_name } </td>
                                                        <td className="site-street-td" > { item.street } </td>
                                                        <td className="site-city-td" > { item.city } </td>
                                                        <td className="site-pincode-td" > { item.pincode } </td>
                                                    </tr> 
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    </>
                                    )
                                }
                                </div>

                                <div  className="pt-4 mt-4 mb-5 pb-4 border list-table-div site-table">
                                {
                                    this.state.readerList.length > 0 && (
                                        <>
                                        <table className="table list-table">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Reader Name</th>
                                                <th>Email ID</th>
                                                <th>Phone Number</th>
                                                <th>Username</th>
                                            </tr>
                                        </thead>
                                        <tbody className="user-list-table">
                                        {
                                            this.state.readerList.map((item, index) => {
                                                return(
                                                    <tr key={ item._id }>
                                                        <td className="name-td pl1" > { item.first_name + " " + item.last_name } </td>
                                                        <td className="email-td" > { item.email_id } </td>
                                                        <td className="phone-td" > { item.phone_number } </td>
                                                        <td className="username-td" > { item.username } </td>
                                                    </tr> 
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    </>
                                    )
                                }
                                </div>

                                <div  className="pt-4 mt-4 mb-5 pb-4 border list-table-div site-table">
                                {
                                    this.state.managerList.length > 0 && (
                                        <>
                                        <table className="table list-table">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Route Manager Name</th>
                                                <th>Email ID</th>
                                                <th>Phone Number</th>
                                                <th>Username</th>
                                            </tr>
                                        </thead>
                                        <tbody className="user-list-table">
                                        {
                                            this.state.managerList.map((item, index) => {
                                                return(
                                                    <tr key={ item._id }>
                                                         <td className="name-td pl1" > { item.first_name + " " + item.last_name } </td>
                                                        <td className="email-td" > { item.email_id } </td>
                                                        <td className="phone-td" > { item.phone_number } </td>
                                                        <td className="username-td" > { item.username } </td>
                                                    </tr> 
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    </>
                                    )
                                }
                                </div>

                                <div  className="pt-4 mt-4 mb-5 pb-4 border list-table-div site-table">
                                {
                                    this.state.readingList.length > 0 && (
                                        <>
                                        <table className="table list-table">
                                        <thead>
                                            <tr>
                                                <th className="pl1">Serial Number</th>
                                                <th>Aqualink Device</th>
                                                <th>Unit</th>
                                                <th>Reading Status</th>
                                                <th>Alarm Type</th>
                                                <th>Date</th>
                                                <th>Reading</th>
                                            </tr>
                                        </thead>
                                        <tbody className="reading-list-table ">
                                        {
                                            this.state.readingList.map((item, index) => {
                                                return(
                                                    <tr key={ item._id }>
                                                        <td className="serial-no-td pl1" > { item.meter_serial_no } </td>
                                                        <td className="sird-td" > { item.sird_device_id } </td>
                                                        <td className="unit-td" > { item.unit } </td>
                                                        <td className="meter-status-td" > { item.meter_status } </td>
                                                        <td className="alarm-td" > { item.alarm_type !== '' ? item.alarm_type : "None" } </td>
                                                        <td className="date-td" > { item.receive_date_time } </td>
                                                        <td className="reading-td" > { item.meter_reading } </td>
                                                    </tr> 
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    </>
                                    )
                                }
                                </div>
                                </>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}

export default SearchTransactionIdPage
