import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE_URL, TYPE_SUCCESS, TYPE_FAIL, INTERVAL } from 'assets/constants/Constants';
import Spinner from 'components/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ShowToast from 'components/ShowToast';


export class ClearMeterHistory extends Component {
    constructor(props) {
        super(props)
        let siteId = sessionStorage.getItem("siteId");
        this.state = {
            isLoading: true,
            buttonFlag: false,
            startDate: "",
            endDate: moment().endOf('day').toDate(),
            resultList: [],
            meterList: [],
            error: {},
            siteId: siteId,
            meterSerialNo: "",
            maxEndDate: new Date()
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeData = this.handleChangeData.bind(this);
    }

    async componentDidMount() {
        this.setState({
            isLoading: false,
        });

    }

    handleChange(date) {
        this.setState({
            startDate: date
        })
    }

    handleChangeData(date) {
        this.setState({
            endDate: date
        })
    }

    isFormValid = () => {
        let isValid = true;
        let error = {};

        if (this.state.meterSerialNo === "") {
            isValid = false;
            error.MeterSerialNoError = "Please enter meter serial no";
        }
        if (this.state.startDate === "") {
            isValid = false;
            error.startDateError = "Please select date";
        }
        this.setState({
            error
        });

        return isValid;
    }

    clearMeterHistoryData = async (event) => {
        event.preventDefault();
        if (this.isFormValid()) {
            const conformClearDta = window.confirm("Are you sure you want to clear this " + this.state.meterSerialNo + " meter history data ? ");
            if (!conformClearDta) {
                return;
            }
            this.setState({ isLoading: true, buttonFlag: true });
            try {
                if (!isNaN(this.state.meterSerialNo)) {
                    const routeResponse = await axios.delete(`${API_BASE_URL}/meter/clear/reading/${this.state.meterSerialNo}/site/${this.state.siteId}/dateLimits/${this.state.startDate}/${this.state.endDate}`);
                    const resultList = routeResponse.data.result;
                    console.log("resultList:", resultList);

                    if (resultList !== undefined && resultList !== null) {
                        this.setState({
                            resultList: resultList,
                            isLoading: false,
                            meterSerialNo: "",
                            startDate: ""
                        }, () => {
                            ShowToast('Clear meter history successfully', TYPE_SUCCESS, INTERVAL, this.props, "");
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            meterSerialNo: "",
                            startDate: ""
                        }, () => {
                            ShowToast('Meter readings are not found in this site', TYPE_FAIL, INTERVAL, this.props, "");
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false,
                        meterSerialNo: "",
                        startDate: ""
                    }, () => {
                        ShowToast('Enter correct meter serial number ', TYPE_FAIL, INTERVAL, this.props, "");
                    });
                }
            } catch (error) {
                console.error(error);
                this.setState({
                    isLoading: false,
                    meterSerialNo: "",
                    startDate: ""
                }, () => {
                    ShowToast('Not clear meter history', TYPE_FAIL, INTERVAL, this.props, "");
                });
            }
        }
    }


    render() {
        if (this.state.isLoading) {
            return (
                <Spinner />
            )
        }
        else {
            return (
                <div className="main-content">
                    <div className="back">
                        <ul className="breadcrumb1">
                            <li>Clear Meter History</li>
                        </ul>
                    </div>
                    <div className="list-wrapper">
                        <div className="pt-4 mt-4 mb-5 pb-4 border list-table-div">
                            <div className="row">
                                <div className="addUser">
                                    <label className="form-label">Meter Serial No <span className="text-danger">*</span></label>
                                    <input className="form-control brand-font p-3 placeholderColor add-meter-input" type="text" value={this.state.meterSerialNo} onChange={(e) => this.setState({ meterSerialNo: e.target.value })}
                                        placeholder="Enter meter serial no" >
                                    </input>
                                    <div className="text-danger error-msg">
                                        {this.state.error.MeterSerialNoError}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="addUser">
                                    <label className="form-label">From Date <span className="text-danger">*</span></label>
                                    <div className="calender-div">
                                        <span className="icon fa-calendar" />
                                        <DatePicker
                                            className="form-control brand-font p-3"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Please select date"
                                            maxDate={this.state.maxEndDate}
                                        />
                                    </div>
                                    <div className="text-danger error-msg">
                                        {this.state.error.startDateError}
                                    </div>
                                </div>
                                <div className="addUser">
                                    <label className="form-label">To Date <span className="text-danger">*</span></label>
                                    <div className="calender-div">
                                        <span className="icon fa-calendar" />
                                        <DatePicker className="form-control brand-font p-3"
                                            selected={this.state.endDate}
                                            onChange={this.handleChangeData}
                                            timeIntervals={1}
                                            timeCaption="time"
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={this.state.maxEndDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="addButton">
                                <button id="button" className="brand-button report-button-big" onClick={this.clearMeterHistoryData}>Clear Meter Data</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default ClearMeterHistory
