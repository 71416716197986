import React, { Component } from 'react'
import { defaultFormatDate } from "lib/utils"

export class RouteListTable extends Component {

    render() {
        return (
            <React.Fragment>
                <table className="table list-table" id="table-to-xls" >
                    <thead>
                        <tr>
                            <th className="pl1">Route Name</th>
                            <th>Route Manager</th>
                            <th>Street</th>
                            <th>City</th>
                            <th>Pin Code</th>
                            <th>Meter Counts</th>
                            <th>Last Updated Date</th>
                            <th>Last Assigned Reader</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody className="route-list-table">
                        {
                            this.props.routeList.map((readingItem) => {
                                return (
                                    <tr key={readingItem._id}>
                                        <td className="route-name-td pl1" > {readingItem.route_name} </td>
                                        <td className="route-manager-td" > {readingItem.route_manager} </td>
                                        <td className="street-td" > {readingItem.street} </td>
                                        <td className="city-td" > {readingItem.city} </td>
                                        <td className="pin-code-td" > {readingItem.pin_code} </td>
                                        <td className="meter-count-td" > {readingItem.meter_count} </td>
                                        <td className="date-td" > {defaultFormatDate(readingItem.updated_date_time)} </td>
                                        <td className="reader-td"> {readingItem.last_assigned_reader !== null ? readingItem.last_assigned_reader.join(", ") : "none"} </td>
                                        <td className="status-td"> {readingItem.route_status !== false ? "Active" : "Inactive"} </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default RouteListTable
