import React, { useState } from 'react'
import InvoicePDF from 'components/InvoicePDF';
import PDFGenerator from 'components/PDFGenerator';
import { Link } from 'react-router-dom';

function BillingInvoice(props) {
    const [pageLink] = useState(props.history.location.state.pageLink);
    const [pageName] = useState(props.history.location.state.pageName);

    const redirectToPreviousPage = () => {
        props.history.push({
            pathname: pageLink,
            state: props.history.location.state.pageData
        })
    }

    return (
        <div className="main-content">
            <section className='mb-5 pb-5'>
                <div className="back">
                    <ul className="breadcrumb1">
                        {
                            <>
                                <li><Link onClick={redirectToPreviousPage}>{pageName}</Link></li>
                                <li>Customer Invoice</li>
                            </>
                        }

                    </ul>
                </div>
                <div className='container'>
                    <div className='invoice-preview-div'>
                        <InvoicePDF data={props.history.location.state}/>
                    </div>
                    <PDFGenerator>
                        <InvoicePDF data={props.history.location.state}/>
                    </PDFGenerator>
                </div>
            </section>
        </div>
    )
}

export default BillingInvoice