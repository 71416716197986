import React from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from 'react-google-maps';
import { GOOGLE_MAP_API_KEY } from 'assets/constants/Constants';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import ShowToast from 'components/ShowToast';
import { TYPE_SUCCESS, INTERVAL } from 'assets/constants/Constants';
import { CLICK_ICON } from 'assets/constants/Icons';

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100vh' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withStateHandlers(
    () => ({
      startingPoint: null,
      destinationPoint: null,
      hoveredMarker: null,
    }),
    {
      onSelectMarker: () => (marker) => ({
        startingPoint: marker,
        destinationPoint: null,
      }),
      onSelectDestination: () => (marker) => ({
        destinationPoint: marker,
      }),
      onClearSelection: () => () => ({
        startingPoint: null,
        destinationPoint: null,
        hoveredMarker: null,
      }),
      onHoverMarker: () => (marker) => ({
        hoveredMarker: marker,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { startingPoint, destinationPoint } = props;

  const handleMarkerClick = (item) => {
    if (!startingPoint) {
      props.onSelectMarker(item);
    } else if (!destinationPoint && startingPoint !== item) {
      props.onSelectDestination(item);
    } else if (startingPoint && destinationPoint) {
      props.onClearSelection();
      props.onSelectMarker(item);
    }
  };

  const handleCalculateDistance = () => {
    const distance = calculateDistance(props.startingPoint, props.destinationPoint);

    ShowToast(`Starting Point: ${startingPoint.serial_number}, ${startingPoint.status}\n` +
      `Destination Point: ${destinationPoint.serial_number}, ${destinationPoint.status}\n` +
      `Distance between Starting and destination Point: ${distance.toFixed(2)} km`, TYPE_SUCCESS, INTERVAL, "");
  };

  const calculateDistance = (point1, point2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const lat1 = point1.gps_latitude * (Math.PI / 180);
    const lon1 = point1.gps_longitude * (Math.PI / 180);
    const lat2 = point2.gps_latitude * (Math.PI / 180);
    const lon2 = point2.gps_longitude * (Math.PI / 180);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  return (
    <GoogleMap defaultZoom={18} defaultCenter={props.centerLocationCoordinates} onClick={() => { props.onClearSelection() }}>
      {props.meterList.map((item) => (
        <Marker
          key={item._id}
          position={{
            lat: parseFloat(item.gps_latitude),
            lng: parseFloat(item.gps_longitude),
          }}
          onClick={() => handleMarkerClick(item)}
          draggable={true}
          onMouseOver={() => {
            props.onHoverMarker(item);
          }}
          onMouseOut={() => {
            props.onHoverMarker(null);
          }}
        >
          {props.startingPoint === item && (
            <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
              <div className="infobox-wrapper">
                <div className="inner-info">
                  <center>
                    <label>{`${item.serial_number}, ${item.status}`}</label>
                    <label onClick={() => props.infoBoxCliked(item)}>export</label>
                    <label>{`Starting Point (selected)`}</label>
                  </center>
                </div>
              </div>
            </InfoBox>
          )}
          {props.destinationPoint === item && (
            <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
              <div className="infobox-wrapper" >
                <div className="inner-info">
                  <center>
                    <label>{`${item.serial_number}, ${item.status}`}</label>
                    <label onClick={() => props.infoBoxCliked(item)}>export</label>
                    <label>{`destination Point (selected)`}</label><br />
                    <button onClick={() => handleCalculateDistance(props.startingPoint, item)}>
                      <i style={{ color: '#0A043B', marginRight: '5px' }} className={`${CLICK_ICON}`}>
                        <span style={{ marginLeft: '5px', marginRight: '5px' }}> Calculate Distance </span> </i>
                    </button>
                  </center>
                </div>
              </div>
            </InfoBox>
          )}
          {props.hoveredMarker === item && (
            <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
              <div className="infobox-wrapper">
                <div className="inner-info">
                  <label>{`${item.serial_number}, ${item.status}`}</label>
                </div>
              </div>
            </InfoBox>
          )}
        </Marker>
      ))}

      {props.startingPoint && (
        <Marker
          position={{
            lat: parseFloat(props.startingPoint.gps_latitude),
            lng: parseFloat(props.startingPoint.gps_longitude),
          }}
        />
      )}
      {props.destinationPoint && (
        <Marker
          position={{
            lat: parseFloat(props.destinationPoint.gps_latitude),
            lng: parseFloat(props.destinationPoint.gps_longitude),
          }}
        />
      )}
      {props.startingPoint && props.destinationPoint && (
        <Polyline
          path={[
            {
              lat: parseFloat(props.startingPoint.gps_latitude),
              lng: parseFloat(props.startingPoint.gps_longitude),
            },
            {
              lat: parseFloat(props.destinationPoint.gps_latitude),
              lng: parseFloat(props.destinationPoint.gps_longitude),
            },
          ]}
          options={{
            strokeColor: '#4285F4',
            strokeWeight: 4,
            strokeOpacity: 0.8,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 8,
                  fillColor: '#4285F4',
                  strokeColor: '#4285F4',
                },
                offset: '0%',
              },
              {
                icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 8,
                  fillColor: 'red',
                  strokeColor: 'red',
                },
                offset: '100%',
              },
            ],
          }}
        />
      )}
    </GoogleMap>
  );
});

export default MapComponent
