import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
// import "assets/css/pdfstyle.min.css"

function InvoicePDF({ data }) {
    console.log("Props in invoice PDF: ", data);
    const [customerName, setCustomerName] = useState("");
    const [address, setAddress] = useState("");
    const [consumerNumber, setConsumerNumber] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [unit, setUnit] = useState("");
    const [billAmount, setBillAmount] = useState(0);
    const [netAmount, setNetAmount] = useState(0);
    const [cgst, setcgst] = useState(0);
    const [sgst, setsgst] = useState(0);
    const [igst, setigst] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState(0);
    const [bill_start_date, setBillStartDate] = useState(moment(Date.now()).format("Do MMM, YYYY"));
    const [bill_end_date, setBillEndDate] = useState(moment(Date.now()).format("Do MMM, YYYY"));
    const [invoiceDate, setInvoiceDate] = useState(moment(Date.now()).format("Do MMM, YYYY"));

    const setValues = useCallback(() => {
        setCustomerName(data.customer_name);
        setConsumerNumber(data.consumer_no);
        setSerialNumber(data.serial_number);
        setUnit(data.unit_consume);
        setBillAmount(data.bill_amount);
        setNetAmount(data.net_bill_amount);
        setcgst(data.cgst_amount);
        setsgst(data.sgst_amount);
        setigst(data.igst_amount);
        setInvoiceNumber(data.invoice_no);
        setBillStartDate(moment(data.start_date).format("Do MMM, YYYY"));
        setBillEndDate(moment(data.end_date).format("Do MMM, YYYY"));
        setInvoiceDate(moment(data.bill_print_date).format("Do MMM, YYYY"));
        let fullAddress = [];
        if (data.customer_address1) {
            fullAddress.push(data.customer_address1);
        }
        if (data.customer_address2) {
            fullAddress.push(data.customer_address2);
        }
        if (data.street) {
            fullAddress.push(data.street);
        }
        if (data.city) {
            fullAddress.push(data.city);
        }
        if (data.pin_code) {
            fullAddress.push(data.pin_code);
        }
        setAddress(fullAddress.join(", "));
    }, [data.customer_name,data.consumer_no,data.serial_number,data.unit_consume,data.bill_amount,data.net_bill_amount,data.cgst_amount,data.sgst_amount,data.igst_amount,data.invoice_no,data.start_date,data.end_date,data.bill_print_date,data.customer_address1,data.customer_address2,data.street,data.city,data.pin_code,
    ]);

    useEffect(() => {
        setValues();
    }, [setValues]);

    
    const numbersToWords = () => {
        var convertRupeesIntoWords = require("convert-rupees-into-words");

        if (billAmount > 0) {
            return convertRupeesIntoWords(netAmount);
        } else {
            return "Zero Rupees";
        }
    };

    return (
        <table class="table table-bordered billing-outer-div" style={{ width: "750px", margin: "8px", marginTop: "30px" }}>
            <tbody>
                <tr>
                    <td colspan="4" rowspan="2">
                        <div className='company-header-div'>
                            <h4 className='logo'>SBEM</h4>
                            <div>Khed Shivapur, Pune, Maharashtra, 411042</div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div>
                            Mobile No: 99999999
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div>
                            GSTIN: 354342345SDFRDW
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" rowspan="2">
                        <div>
                            To:
                        </div>
                        <div>
                            {customerName},
                        </div>
                        <div>
                            {address}
                        </div>
                    </td>
                    <td colspan="3">
                        <div>
                            Invoice No: {invoiceNumber}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div>
                            Billing Cycle: {`${bill_start_date} - ${bill_end_date}`}
                        </div>
                        <div>
                            Bill Date: {invoiceDate}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><div>SR. No</div></td>
                    <td><div>Consumer Name</div></td>
                    <td><div>Consumer Number</div></td>
                    <td><div>serial Number</div></td>
                    <td><div>Units</div></td>
                    <td><div>Amount</div></td>
                </tr>
                <tr>
                    <td><div>1</div></td>
                    <td><div>{customerName}</div></td>
                    <td><div>{consumerNumber}</div></td>
                    <td><div>{serialNumber}</div></td>
                    <td><div>{unit}</div></td>
                    <td><div>{billAmount}</div></td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div className='float-left'>
                            Total Amount Before Tax: {billAmount}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" rowspan="4">
                        <div className='float-center'>
                            {numbersToWords()}
                        </div>
                    </td>
                    <td colspan="1">
                        <div>
                            Add CGST {data.cgst}%
                        </div>
                    </td>
                    <td>
                        <div>
                            {cgst.toFixed(2)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div>
                            Add SGST {data.sgst}%
                        </div>
                    </td>
                    <td>
                        <div>
                            {sgst.toFixed(2)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div>
                            Add IGST {data.igst}%
                        </div>
                    </td>
                    <td>
                        <div>
                            {igst.toFixed(2)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div>
                            Total Amount After Tax
                        </div>
                    </td>
                    <td>
                        <div>
                            {netAmount.toFixed(2)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div className='float-left'>
                            For SBEM PVT LTD
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default InvoicePDF