import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from 'assets/constants/Constants';
import md5 from 'md5';
import { getPermissionMatrix } from "components/Permission";
import ShowToast from 'components/ShowToast';
import config from "../config.json";
import { styles } from '../assets/js-css/styles';

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const classes = styles();
    const [userName, setUserName] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isUserAuthenticated, setIsAuthenticated] = useState(false);

    
    const configFile = { ...config };

    const applicationState = {
        userName,
        userData,
        isUserAuthenticated,
        ...configFile,
        styles: classes
    }

    const signIn = async (username, password, selectedSiteId) => {
        return new Promise( async (resolve, reject) => {
            try{
                const requestData = {
                    username,
                    password: md5(password),
                    selectedSiteId: selectedSiteId,
                    device: 'web'
                };
                const encodedCredentials = Buffer.from(JSON.stringify(requestData)).toString("base64");  
                const responseData = await axios.post(`${API_BASE_URL}/login`, {credentials: encodedCredentials});
                const roleResponseData = await axios.get(`${API_BASE_URL}/role/${responseData.data.result.result.role}`);
                const siteData = await getAllSites();

                await getPermissionMatrix();
                let isAuthenticated = false;
                if(responseData.status === 200){
                    if(responseData.data.result.result){
                        isAuthenticated = true;
                    }
                }
                sessionStorage.setItem("isAuthenticated", isAuthenticated);
                sessionStorage.setItem("userData", JSON.stringify(responseData.data.result.result));
                sessionStorage.setItem("userRole", JSON.stringify(roleResponseData.data.result));
                sessionStorage.setItem("siteData", JSON.stringify(siteData.data.result));
                setIsAuthenticated(isAuthenticated);
                setUserData(responseData.data.result.result);
                setUserName(username);
                resolve(responseData);
            }
            catch(error){
                console.log(error);
                reject(null);
            }
        });
    }

    const signOut = () => {
        const loginURL = localStorage.getItem("loginURL");
        setIsAuthenticated(false);
        setUserData(null);
        setUserName(null);
        sessionStorage.setItem("isAuthenticated", false);
        sessionStorage.setItem("userData", null);
        sessionStorage.clear();
        ShowToast('You are logged out!','success'); 
        if(loginURL){
            window.location.href = loginURL;
        }
        else{
            window.location.href = "/login-page";
        }
    }

    const getAllSites = async () => {
        return await axios(`${API_BASE_URL}/site`);
    }
    
    const getUserDetails = async () => {
        return new Promise((resolve) => {
            if(sessionStorage.getItem("userData")){
                resolve(JSON.parse(sessionStorage.getItem("userData")));
            }
            else{
                resolve(null);
            }
        });
    }

    const isAuthenticated = () => {
        if(sessionStorage.getItem("isAuthenticated") === "false"){
            return false;
        }
        else if(sessionStorage.getItem("isAuthenticated") === "true"){
            return true;
        }
        else{
            return false;
        }
    }

    return (
        <AuthContext.Provider value={{...applicationState, isAuthenticated, signIn, signOut, getUserDetails}}>
            { props.children }
        </AuthContext.Provider>
    )
}

export default AuthContext
